import React from "react";
import Slider from "@material-ui/core/Slider";

function FieldGroup({
  validationState,
  id,
  label,
  help,
  type,
  options,
  disabled,
  ...props
}) {
  return (
    <div className=" ">
      {/* <label
        className="block text-black text-sm font-medium mb-1"
        htmlFor={label}
      >
        {label} <span className="text-red-600">*</span>
      </label> */}

      {type === "textarea" ? (
        <textarea
          id={id}
          {...props}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner "
        ></textarea>
      ) : type === "select" ? (
        <select
          id={id}
          {...props}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
        >
          {options()}
        </select>
      ) : (
        <input
          id={id}
          type={type}
          disabled={disabled}
          className={`form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner ${
            disabled === true ? "bg-gray-300" : "bg-white"
          }`}
          {...props}
        />
      )}

      {type === "textarea" ? (
        <p hidden={validationState} className=" text-sm mt-2 flex justify-end">
          {help}
        </p>
      ) : (
        <p hidden={validationState} className="text-red-500 text-sm mt-2">
          {help}
        </p>
      )}
    </div>
  );
}

class Room_Ranges extends React.Component {
  constructor() {
    super();
    this.sliderRoom = React.createRef();
  }

  /*checkslide=()=>{
        //console.log(this.sliderRoom,this.sliderRoom.current.children[11].getAttribute("tabindex"))
    }*/

  render() {
    const {
      total_numb_rooms,
      onSliderValueChange,
      temp_range_high,
      temp_range_low,
      room_type_list,
      room_range_list,
      adding_room_range,
      onAddingRoom_range,
      onRange_Selection_Change,
      temp_range_sel_rm,
      room_selection_empty,
      range_overlap,
      all_ranges_covered,
      close_adding_range,
      range_is_verified,
    } = this.props;
    return (
      <>
        <div className="space-y-2">
          <h1 className="text-lg">{`Rooms & Rates`}</h1>
          <p className="shadow-div">
            In the following form you will be asked to provide ranges that will
            help us set up your exact hotel inventory. (eg. In the first floor
            you have 10 total rooms 5 of the are of type A and 5 of type B or
            1-5 are type A and 6-10 are type B inclusively) Please Add a range
            and fill in the starting rooms number and ending room number,
            remember that the room must be in continuous numeric order and
            preferably starting from the bottom floor to the highest floor,
            there cannot be letter such as 102-A and 102-B
          </p>
          <div className="flex flex-col md:flex-row text-center md:justify-around pt-4">
            <p hidden={!all_ranges_covered} className="text-red-500 ">
              {"All Ranges have been covered you can move to the next step"}
            </p>
            <p className="text-red-500">
              {"Please Add at least 1 range to all room ranges"}
            </p>
            <button
              onClick={
                all_ranges_covered === true
                  ? close_adding_range
                  : onAddingRoom_range
              }
              className="
                        
              lg:order-1
              order-2
              px-6
              py-2.5
              bg-blue-600
              text-white
              font-medium
              text-xs
              leading-tight
              uppercase
              rounded
              shadow-md
              hover:bg-blue-700 hover:shadow-lg
              focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
              active:bg-blue-800 active:shadow-lg
              transition
              duration-150
              ease-in-out
              ml-1"
            >
              {adding_room_range === false
                ? "Add Range"
                : all_ranges_covered === true
                ? "Close"
                : "Save Range"}
            </button>
          </div>
          <div className="grid grid-flow-col grid-cols-3 gap-4">
            <p>Room Type Name</p>
            <p>Starting Number</p>
            <p>Ending Number</p>
          </div>
          <div
            className="grid grid-flow-col grid-cols-3 gap-4"
            hidden={adding_room_range === false ? true : false}
          >
            <FieldGroup
              id="room_type_name"
              type="select"
              onChange={onRange_Selection_Change}
              value={temp_range_sel_rm}
              options={() => {
                return (
                  <>
                    <option
                      value={`${0}-Choose`}
                      selected={temp_range_sel_rm === `${0}-Choose`}
                    >
                      Choose...
                    </option>
                    {room_type_list.map((current, index) => {
                      return (
                        <option
                          value={`${index}-${current.room_type_name}`}
                          selected={
                            temp_range_sel_rm ===
                            `${index}-${current.room_type_name}`
                          }
                        >
                          {current.room_type_name}
                        </option>
                      );
                    })}
                  </>
                );
              }}
            />
            <Slider
              ref={this.sliderRoom}
              orientation="horizontal"
              defaultValue={[1, total_numb_rooms - 1]}
              aria-labelledby="vertical-slider"
              marks={[
                {
                  value: 1,
                  label: "1",
                },
                {
                  value: total_numb_rooms,
                  label: `${total_numb_rooms}`,
                },
              ]}
              value={[temp_range_low, temp_range_high]}
              min={1}
              max={total_numb_rooms}
              step={1}
              valueLabelDisplay="auto"
              onChange={onSliderValueChange}
            />
          </div>
          <div>
            {room_range_list.map((current, index) => {
              return (
                <>
                  <div className="grid grid-flow-col grid-cols-3 gap-4">
                    <p>{current.room_type}</p>
                    <p>{current.room_start}</p>
                    <p>{current.room_end}</p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default Room_Ranges;
