import React from "react";

function FieldGroup({
  validationState,
  id,
  mdsize,
  label,
  help,
  hiddenlbl,
  ...props
}) {
  ////console.log({...props})
  return (
    <div className="flex space-x-2 items-center">
      <label>{label}</label>
      <input
        {...props}
        className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
      />
    </div>
    // <Form.Group as={Row} controlId="formRegNum">
    //     <Form.Label column sm={6}>
    //         Reg#:
    // </Form.Label>
    //     <Col sm={6}>
    //         <Form.Control type="text" placeholder="Enter Reg#" />
    //     </Col>
    // </Form.Group>
  );
}

const RegNumInput = ({ formSubmit, onRegNumChange, regNumEntered }) => {
  return (
    <div>
      <FieldGroup
        id="RegNum"
        label={"Reg#:"}
        type="text"
        placeholder="Enter Reg #"
        value={regNumEntered}
        onChange={onRegNumChange}
        onKeyPress={formSubmit}
      />
    </div>
  );
};
export default RegNumInput;
