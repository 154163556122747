import React from "react";
import moment from "moment";

const Payment_grid = ({ payment_array, pagedisplay }) => {
  ////console.log(pagedisplay)
  return (
    <>
      {payment_array.map((payment, i) => {
        ////console.log(payment.type_payment,"type",pagedisplay==="make")
        return (
          <div className="flex" key={i}>
            <span className="w-1/3">
              {pagedisplay === "maker"
                ? payment.type_payment
                : payment.payment_type}
            </span>
            <span className="w-1/3">{`$ ${parseFloat(payment.amount).toFixed(
              2
            )}`}</span>
            <span className="w-1/3">
              {pagedisplay === "maker"
                ? moment().format("MM/DD/YYYY")
                : moment(payment.date_received).format("MM/DD/YYYY")}
            </span>
          </div>
        );
      })}
    </>
  );
};
export default Payment_grid;
