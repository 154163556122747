import React from "react";

class occupancy_sel extends React.Component {
  constructor(props) {
    super(props);
    // from here you can access any of the props!
    ////console.log('The value is ' + props.value);
    // we can even call grid API functions, if that was useful
    //props.api.selectAll();
    this.setvalue = this.setvalue.bind(this);
    this.state = {
      value: "",
    };
  }
  isPopup() {
    return true;
  }
  getValue() {
    return this.state.value;
  }

  setvalue = (e) => {
    ////console.log("changedvalue",e.target.value)
    let temp = e.target.value;
    let regex = /^[0-9]+$/;
    let test = regex.test(temp);

    if (test === false) {
    } else {
      this.setState((prevState) => ({ value: temp }));
    }

    e.stopPropagation();
  };

  render() {
    //console.log("pssed by grid prec",this.props.agGridReact.props.cellEditorParams)
    return (
      <>
        <input
          className="form-input w-12 text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
          type="text"
          onChange={this.setvalue}
          placeholder="Occupancy"
          value={this.state.value}
        ></input>
      </>
    );
  }
}
export default occupancy_sel;
