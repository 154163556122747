import React from "react";
import moment from "moment";

const Notes_grid = ({ notesadded, pagedisplay }) => {
  //console.log(notesadded)
  return (
    <>
      {notesadded.map((note, i) => {
        return (
          <div className="flex space-x-4 " key={i}>
            <div className="w-20" xs={3}>
              {pagedisplay === "maker"
                ? moment().format("MM/DD/YYYY")
                : moment(note.date_made).format("MM/DD/YYYY")}
            </div>
            <div className="" xs={9}>
              {pagedisplay === "maker" ? note : note.reservation_note}
            </div>
          </div>
        );
      })}
    </>
  );
};
export default Notes_grid;
