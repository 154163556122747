import React, { Fragment } from "react";
//import { connect } from "react-redux";

import Grid_main from "./Grid_main";
import Client_Form from "./Client_Form";
import "./Main_Make_res.css";
import moment from "moment";
import Grid_preview from "./Grid_preview";
import Client_preview_grid from "./Client_preview_grid";
import Price_box from "./price_box";
import Button_bar from "./Button_bar";
import Modal_reservation from "./Modal_reservation";
import Modal_reservation_finished from "./Modal_reservation_finished";
import Modal_notes from "./Modal_notes";
import Button_array from "./Button_array";
import Modal_AddPayment from "./Modal_Add_Payment";
import Grid_Reserv4me from "./Grid_reservation_Reserv4me";

import SideBar from "../Header Menu/SideBar";
import Header from "../Header Menu/Header";

/*import { setCIdatepicker } from "../../actions/Make_reservation_actions";
import { GridOptionsWrapper } from "ag-grid-community";*/

import Modal_Add_Discount from "./Modal_AddDiscount";

import axios from "axios";
import { withRouter } from "react-router";
import Flatpickr from "react-flatpickr";
import printJS from "print-js";

/*const mapStateToProps = (state) => {
  return {
    CIdate: state.CIdate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    date_CI_change: (event) => {
      dispatch(setCIdatepicker(event));
    },
  };
};*/

//const { ipcRenderer } = window.require("electron");

const today = moment();

const InitialState = {
  client_id: 0,
  client_fname: "",
  client_lname: "",
  client_phone_num: "",
  client_email: "",
  client_address: "",
  client_city: "",
  client_state: "",
  client_zip: "",
  channel: "",
  channel_types: [],

  room_inv_detail: [],
  columnDefs: [
    {
      headerName: "Room Info",
      children: [
        {
          headerName: "Rm #",
          field: "roomnumb",
          width: 55,
          lockPosition: true,
        },
        {
          headerName: "Type",
          field: "unittype",
          width: 80,
          lockPosition: true,
        },
        {
          headerName: "Avail",
          field: "isAvail",
          width: 20,
          lockPosition: true,
          hide: true,
        },
      ],
    },
  ],
  rowData: [],
  maingrid_hasSelection: false,

  rowClassRules: {
    /*rowClassRule: function (params) {
      //console.log(params);
      return true;
    },*/
  },
  rowSelection: "multiple", //controls how the main grid in selectable
  isRowSelectable: function (rowNode) {
    //controls whether main grid row is available for selecting
    ////console.log(rowNode);
    return rowNode.data ? rowNode.data.isAvail === true : false;
  },

  CIdate: moment(),
  COdate: moment().add(7, "days"),

  columnDefs_preview: [
    { headerName: "Rm #", field: "room_num", width: 55, lockPosition: true },
    { headerName: "Type", field: "unittype", width: 55, lockPosition: true },
    {
      headerName: "Type_id",
      field: "unittypeID",
      width: 55,
      lockPosition: true,
      hide: true,
    },
    { headerName: "CI", field: "date_CI", width: 85, lockPosition: true },
    { headerName: "CO", field: "date_CO", width: 85, lockPosition: true },
    {
      headerName: "Num Adults",
      field: "num_Adg",
      width: 80,
      lockPosition: true,
      cellEditor: "selectcellEditor",
      editable: true,
    },
    {
      headerName: "Num Children",
      field: "num_CHg",
      width: 80,
      lockPosition: true,
      cellEditor: "selectcellEditor",
      editable: true,
    },
    //{ headerName: "Rate Type", field: "ratetype", width: 55, lockPosition: true},
    {
      headerName: "Total unit price",
      field: "total_price",
      width: 85,
      lockPosition: true,
    },
    { headerName: "Tax", field: "tax_room", width: 85, lockPosition: true },
    //{ headerName: "Room #", field: "room_num",width: 55, lockPosition: true}
  ],
  rowData_preview: [],
  rowClassRules_preview: [],

  selectedRows: [],
  grand_total: {
    total_wo_tax: 0,
    tax_total: 0,
    total_w_tax: 0,
  },

  //error handling
  showModal: false,
  fnameval: false,
  lnameval: false,
  phone_numval: false,
  email_addrval: false,

  gridinfo: "",

  showsuccessModal: false,

  columDefs_client: [
    {
      headerName: "Name",
      field: "client_fname",
      width: 60,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Name",
      field: "client_lname",
      width: 60,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Phone #",
      field: "client_phone_number",
      width: 100,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Email",
      field: "client_email",
      width: 160,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Address",
      field: "client_street",
      width: 80,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "State",
      field: "client_state",
      width: 60,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "ZIP",
      field: "client_zip",
      width: 55,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Client_ID",
      field: "client_id",
      width: 55,
      lockPosition: true,
    },
  ],
  rowData_client: [],

  client_grid_visible: true,
  preview_grid_visible: false,
  button_clientgrid_nofound_visible: true,

  showModal_notes: false,
  Notes_res: "",
  notesadded: [],
  canMove: true,
  isNotesInvalidated: false,

  showModal_AddPayment: false,
  addedPayment: [],
  AmountValue: "",
  SelectedPaymenttype: "CC",
  payment_types_array: [],
  ispaymentInvalidated: false,

  resulting_reg_Numb: "",

  reserv4me_reservations: [],
  reserv4me_active: false,
  reserve4me_grid_hidden: true,
  columDef_reserv4me: [
    {
      headerName: "Name",
      field: "clientfname",
      width: 60,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Name",
      field: "clientlname",
      width: 60,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Phone #",
      field: "clientphone",
      width: 100,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Email",
      field: "clientemail",
      width: 160,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "CI",
      field: "checkin",
      width: 80,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "CO",
      field: "checkout",
      width: 60,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Room Total",
      field: "totalcostbtax",
      width: 55,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Ext. R#",
      field: "resid",
      width: 55,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Date Made",
      field: "datemade",
      width: 55,
      lockPosition: true,
      resizable: true,
    },
  ],
  rowSelection_reserv4me: "single",
  webres_id: "",
  reserv4meindex: "", //thisis the index of the row selected with the double click in the reserv4me method
  reserv4me_selected_Data: "",
  localvexternalroomID: [],
  taxrate: "",

  showDiscount_Modal: false,
  discount_Array: [],
  discount_array_applied: [],
  discount_selected: "",
  new_discount_array_applied: [],
  discounts_to_be_deleted: [],
  sidebarOpen: false,
  loading: false,
};

class Make_res extends React.Component {
  constructor(props) {
    super(props);
    //this.date_selected=this.date_selected.bind(this)
    this.clientinfor_change = this.clientinfor_change.bind(this);
    this.state = InitialState;
  }

  date_CI_change = (value) => {
    // //console.log("CIval",value)

    const CIvalue = value;
    if (
      value.diff(today, "days") >= 0 &&
      this.state.COdate.diff(value, "days") > 0
    ) {
      this.setState(
        (prevState) => ({
          CIdate: value,
          // COdate: moment(value).add(7, "days"),
          rowData: [],
          columnDefs: InitialState.columnDefs,
          rowClassRules: InitialState.rowClassRules,
        }),
        () => {
          this.sendinfo();
          this.onmainSelectionchanged();
        }
      );
    }
    if (this.state.COdate.diff(value, "days") <= 0) {
      ////console.log("option B CI");
      this.setState(
        (prevState) => ({
          CIdate: CIvalue,
          COdate: moment(CIvalue).add(1, "days"),
          rowData: [],
          columnDefs: InitialState.columnDefs,
          rowClassRules: InitialState.rowClassRules,
        }),
        () => {
          this.sendinfo();
          this.onmainSelectionchanged();
        }
      );
    }

    /* this.setState(prevState => {
            if (prevState.CIdate !== value) {
                return (
                    {
                        CIdate: value,
                        COdate:moment(value).add(7,"days"),
                        rowData: [],
                        columnDefs: InitialState.columnDefs,
                        rowClassRules: InitialState.rowClassRules
                    })
            }
        }, () => { 
            this.sendinfo();
            this.onmainSelectionchanged();
            
        })*/
  };
  date_CO_change = (value) => {
    ////console.log("COval",value)
    const COvalue = value;
    if (value > this.state.COdate && value > this.state.CIdate) {
      //dateCOenter.diff(this.state.dateCO,"days")>=0&&dateCOenter.diff(this.state.dateCI,"days")>0)
      ////console.log("op[tion a ", value);
      this.setState(
        (prevState) => ({
          COdate: moment(value),
          rowData: [],
          columnDefs: InitialState.columnDefs,
          rowClassRules: InitialState.rowClassRules,
        }),
        () => {
          this.sendinfo();
          this.onmainSelectionchanged();
        }
      );
    } else {
      ////console.log("op[tion b ");

      if (COvalue < this.state.COdate && COvalue > today) {
        if (COvalue <= this.state.CIdate) {
          this.setState(
            (prevState) => ({
              CIdate: prevState.CIdate.subtract(
                prevState.CIdate.diff(COvalue, "days") + 1,
                "days"
              ),
              COdate: COvalue,
              rowData: [],
              columnDefs: InitialState.columnDefs,
              rowClassRules: InitialState.rowClassRules,
            }),
            () => {
              this.sendinfo();
              this.onmainSelectionchanged();
            }
          );
        } else if (value > this.state.CIdate) {
          this.setState(
            (prevState) => ({
              COdate: value,
              rowData: [],
              columnDefs: InitialState.columnDefs,
              rowClassRules: InitialState.rowClassRules,
            }),
            () => {
              this.sendinfo();
              this.onmainSelectionchanged();
            }
          );
        }
      } else if (value < this.state.COdate && value <= today) {
        this.setState(
          (prevState) => ({
            dateCI: value.diff(prevState.CIdate, "days") > 0 ? today : today,
            dateCO: InitialState.COdate,
            rowData: [],
            columnDefs: InitialState.columnDefs,
            rowClassRules: InitialState.rowClassRules,
          }),
          () => {
            this.sendinfo();
            this.onmainSelectionchanged();
          }
        );
      }
    }

    /*this.setState(prevState => {
            if(prevState.COdate!==value){
            return({
                COdate: value,
                rowData: [],
                columnDefs: InitialState.columnDefs,
                rowClassRules: InitialState.rowClassRules
            })}}, () => {
                this.sendinfo();
                this.onmainSelectionchanged();
            })*/
  };

  sendinfo = () => {
    //gets the information from the database to draw the databook

    /*let req = JSON.stringify({
			fromdate: this.state.CIdate,
			untildate: this.state.COdate
		});
		let resp = ipcRenderer.sendSync('book-getter', req);

		//console.log("resp ofbook",resp)
			this.setState((prevState) => ({
			columnDefs: prevState.columnDefs.concat(resp.daterange.columns),
			rowData: prevState.rowData.concat(resp.daterange.rowData),
			room_inv_detail: prevState.room_inv_detail.concat(resp.daterange.room_inv_detail)
		}))*/
    ////console.log(document.cookie);

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/book-getter"
          : "https://apiv1.reserv4me.com/book-getter",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        fromdate: this.state.CIdate,
        untildate: this.state.COdate,
        analytics_mode_disabled: true,
        date_analytics: "",
      },
      withCredentials: true,
    })
      .then((resp) => {
        ////console.log("resp ofbook", resp.data);
        for (let i = 0; i < resp.data.daterange.columns.length; i++) {
          Object.assign(
            resp.data.daterange.columns[i].children[0].cellClassRules,

            {
              cellCI_Selected: function (params) {
                let matchingcase =
                  params.data[
                    `${resp.data.daterange.columns[i].children[0].field}`
                  ];
                ////console.log("Modify_res -> sendinfo -> params2", matchingcase);

                if (matchingcase.split("-").length === 2) {
                  return (
                    parseInt(matchingcase.split("-")[0]) ===
                      parseInt(resp.data.daterange.regNum) &&
                    matchingcase.split("-")[1] === "CI"
                  );
                }
              },
              cellCI: function (params) {
                let matchingcase =
                  params.data[
                    `${resp.data.daterange.columns[i].children[0].field}`
                  ];
                ////console.log("Modify_res -> sendinfo -> params2", matchingcase);

                if (matchingcase.split("-").length === 2) {
                  return matchingcase.split("-")[1] === "CI";
                }
                //return matchingcase === true;
              },
              cellreservation: function (params) {
                let matchingcase =
                  params.data[
                    `${resp.data.daterange.columns[i].children[0].field}`
                  ];
                if (matchingcase.split("-").length === 1) {
                  return (
                    parseInt(matchingcase.split("-")[0]) ===
                    parseInt(resp.data.daterange.regNum)
                  );
                } else if (matchingcase.split("-").length === 2) {
                  return false;
                }
                /*//console.log(
                "resp",
                params.data,
                params.data[
                  `${resp.data.daterange.columns[i].children[0].field}`
                ].split("-").length === 1,
                resp.data.daterange.columns[i].children[0].field
              ); //this outputs the field name which is necessary to search the params
              return matchingcase === parseInt(resp.data.daterange.regNum);*/
              },
            }
          );
          Object.assign(
            resp.data.daterange.columns[i].children[1].cellClassRules,

            {
              cellreservation: function (params) {
                ////console.log("Modify_res -> sendinfo -> params", params);
                let matchingcase =
                  params.data[
                    `${resp.data.daterange.columns[i].children[0].field}`
                  ];
                if (matchingcase.split("-").length === 1) {
                  return (
                    parseInt(matchingcase.split("-")[0]) ===
                    parseInt(resp.data.daterange.regNum)
                  );
                } else if (matchingcase.split("-").length === 2) {
                  return false;
                }
                ////console.log(matchingcase,tempvar.format('MM/DD/YYYY'),resp.data.daterange.columns[i].children[0].field)
                //return matchingcase === parseInt(resp.data.daterange.regNum);
              },
            }
          );
        }
        this.setState((prevState) => ({
          columnDefs: prevState.columnDefs.concat(resp.data.daterange.columns),
          rowData: prevState.rowData.concat(resp.data.daterange.rowData),
          room_inv_detail: prevState.room_inv_detail.concat(
            resp.data.daterange.room_inv_detail
          ),
        }));
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });
  };

  componentDidMount() {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/get-paymenttype"
          : "https://apiv1.reserv4me.com/get-paymenttype",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {},
      withCredentials: true,
    })
      .then((resp) => {
        //console.log(resp.data);
        this.setState((prevStat) => ({
          payment_types_array: resp.data.paymenttypes,
          channel_types: resp.data.receivedBy,
          channel: resp.data.receivedBy[0].received_type,
          discount_Array: resp.data.discounts,
          discount_selected: resp.data.discounts[0].discount_name,
        }));
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });
  }

  handleRenderer = (e, args) => {
    ////console.log("here",args);
  };
  onGridReady = (params) => {
    ////console.log(params);
    this.gridApi = params.api;
    //this.setState({gridinfo:params.api})
    this.sendinfo();
  };
  onPreviewReady = (params) => {
    this.previewgrid = params.api;
  };
  onClientGridREady = (params) => {
    this.clientgrid = params.api;
  };
  onReserv4meGridReady = (params) => {
    this.reserv4megrid = params.api;
  };

  onmainSelectionchanged = (e, value) => {
    //when the main grid is selected the even is created to make the preview grid display the selection

    var selectedRows = this.gridApi.getSelectedRows();
    var selectedindex = this.gridApi.getSelectedNodes();
    ////console.log(selectedRows.length === 0); //selectedRows[0].unittypeID===1)

    if (this.state.reserv4me_active === false) {
      let temprowData = [];
      for (let i = 0; i < selectedRows.length; i++) {
        temprowData[i] = {
          room_num: selectedRows[i].roomnumb,
          unittype: selectedRows[i].unittype,
          unittypeID:
            this.state.room_inv_detail[selectedindex[i].rowIndex].type_id,
          date_CI: this.state.CIdate.format("MM/DD/YYYY"),
          date_CO: this.state.COdate.format("MM/DD/YYYY"), //moment(this.state.COdate.format("MM/DD/YYYY")).add(1,"days").format("MM/DD/YYYY"),
          num_Adg: value
            ? value[i].num_Adg !== ""
              ? `${value[i].num_Adg}`
              : ""
            : "",
          num_CHg: value
            ? value[i].num_CHg !== ""
              ? `${value[i].num_CHg}`
              : ""
            : "",
          total_price: value
            ? value[i].total_cost_wo_tax !== ""
              ? `$${value[i].total_cost_wo_tax}`
              : ""
            : "",
          tax_room: value
            ? value[i].tax_total !== ""
              ? `$${value[i].tax_total}`
              : ""
            : "",
        };
      }

      this.setState((prevState) => {
        return {
          rowData_preview: temprowData,
          maingrid_hasSelection: !prevState.maingrid_hasSelection,
          client_grid_visible: false,
          preview_grid_visible: true,
        };
      });
    } else {
      let temprowData = [];
      if (selectedRows.length !== 0) {
        /*//console.log(
          selectedRows.length === 0,
          this.state.reserv4me_selected_Data
        ); //selectedRows[0].unittypeID===1)*/
        if (
          selectedRows[0].unittypeID !==
          this.state.reserv4me_selected_Data.internalroomID
        ) {
          this.gridApi.deselectAll();
          this.previewgrid.setRowData(temprowData);
          this.setState((prevState) => {
            return {
              rowData_preview: temprowData,
              maingrid_hasSelection: !prevState.maingrid_hasSelection,
              client_grid_visible: false,
              preview_grid_visible: true,
            };
          });
        }
      }
      ////console.log("hello");
      if (selectedRows.length !== 0) {
        if (
          selectedRows[0].unittypeID ===
          this.state.reserv4me_selected_Data.internalroomID
        ) {
          /*//console.log(
            "yeap",
            this.state.reserv4me_selected_Data.data.totalcostbtax.split("$")[1]
          );*/
          let temptotal = parseFloat(
            this.state.reserv4me_selected_Data.data.totalcostbtax.split("$")[1]
          );
          //let temp
          for (let i = 0; i < selectedRows.length; i++) {
            temprowData[i] = {
              room_num: selectedRows[i].roomnumb,
              unittype: selectedRows[i].unittype,
              unittypeID:
                this.state.room_inv_detail[selectedindex[i].rowIndex].type_id,
              date_CI: this.state.CIdate.format("MM/DD/YYYY"),
              date_CO: this.state.COdate.format("MM/DD/YYYY"), //moment(this.state.COdate.format("MM/DD/YYYY")).add(1,"days").format("MM/DD/YYYY"),
              num_Adg:
                this.state.reserv4me_selected_Data.data.occupancy.split("-")[0],
              num_CHg:
                this.state.reserv4me_selected_Data.data.occupancy.split("-")[1],
              total_price:
                this.state.reserv4me_selected_Data.data.totalcostbtax,
              tax_room: `$${(
                temptotal * parseFloat(this.state.taxrate)
              ).toFixed(2)}`,
            };
          }

          this.previewgrid.setRowData(temprowData);
          this.setState((prevState) => {
            return {
              rowData_preview: temprowData,
              maingrid_hasSelection: !prevState.maingrid_hasSelection,
              client_grid_visible: false,
              preview_grid_visible: true,
            };
          });
        }
      }
    }
  };

  getpricesdb = (e) => {
    //when the number of adults or children is changed then this even goes to the database to read the data and set the prices

    ////console.log("prices");
    /*let req = JSON.stringify({
			rowData_preview: this.state.rowData_preview,
			discountsArray: this.state.discount_array_applied
			//we should pass the discount object here
		});*/
    //let resp = ipcRenderer.sendSync('price-getter', req);
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/price-getter"
          : "https://apiv1.reserv4me.com/price-getter",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        rowData_preview: this.state.rowData_preview,
        discountsArray: this.state.discount_array_applied,
      },
      withCredentials: true,
    })
      .then((resp) => {
        //console.log(resp.data);
        this.setState(
          (prevState) => {
            return {
              rowData_preview: InitialState.rowData_preview,
              grand_total: InitialState.grand_total,
            };
          },
          () => {
            this.onmainSelectionchanged(e, resp.data[0]);
            this.set_updated_prices(resp.data[1]);
          }
        );
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });
    ////console.log(resp)
  };

  set_updated_prices(value) {
    //updates grandtotal

    this.setState((prevState) => ({ grand_total: value }));
  }

  clientinfor_change(e) {
    //checks which form field has been changed and makes an update to the object

    switch (e.target.id) {
      case "fname": {
        this.setState({ client_fname: e.target.value }, () => {
          this.datagrid_client_search();
        });
        break;
      }
      case "lname": {
        this.setState({ client_lname: e.target.value }, () => {
          this.datagrid_client_search();
        });
        break;
      }
      case "phonenumb": {
        this.setState({ client_phone_num: e.target.value }, () => {
          this.datagrid_client_search();
        });
        break;
      }
      case "Email": {
        this.setState({ client_email: e.target.value }, () => {
          this.datagrid_client_search();
        });
        break;
      }
      case "Street": {
        this.setState({ client_address: e.target.value }, () => {
          this.datagrid_client_search();
        });
        break;
      }
      case "Cityname": {
        this.setState({ client_city: e.target.value }, () => {
          this.datagrid_client_search();
        });
        break;
      }
      case "clstate": {
        this.setState({ client_state: e.target.value }, () => {
          this.datagrid_client_search();
        });
        break;
      }
      case "zip": {
        this.setState({ client_zip: e.target.value }, () => {
          this.datagrid_client_search();
        });
        break;
      }
      case "channel": {
        this.setState({ channel: e.target.value });
        break;
      }

      default: {
        //console.log("we did not read the data correctly please restart");
        break;
      }
    }
  }

  datagrid_client_search = () => {
    if (
      this.state.client_fname === "" &&
      this.state.client_lname === "" &&
      this.state.client_phone_num === "" &&
      this.state.client_email === ""
    ) {
      this.setState((prevState) => ({
        client_grid_visible: false,
        preview_grid_visible: true,
      }));
    } else {
      /*let req = JSON.stringify({
				client_info: {
					client_fname: this.state.client_fname,
					client_lname: this.state.client_lname,
					client_phone_num: this.state.client_phone_num,
					client_email: this.state.client_email,
					client_address: this.state.client_address,
					client_city: this.state.client_city,
					client_state: this.state.client_state,
					client_zip: this.state.client_zip
				}
			});
			let resp = ipcRenderer.sendSync('client-search', req);*/

      axios({
        method: "post",
        url:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/client-search"
            : "https://apiv1.reserv4me.com/client-search",
        headers: {
          "Access-Control-Allow-Origin":
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://apiv1.reserv4me.com",
          "Content-Type": "application/json",
          Authorization: "internalvar.apikey",
        },
        data: {
          client_info: {
            client_fname: this.state.client_fname,
            client_lname: this.state.client_lname,
            client_phone_num: this.state.client_phone_num,
            client_email: this.state.client_email,
            client_address: this.state.client_address,
            client_city: this.state.client_city,
            client_state: this.state.client_state,
            client_zip: this.state.client_zip,
          },
        },
        withCredentials: true,
      })
        .then((resp) => {
          //console.log(resp.data, resp.data.length === 0);
          this.clientgrid.setRowData(resp.data);
          if (resp.data.length !== 0) {
            this.setState((prevState) => ({
              client_grid_visible: true,
              preview_grid_visible: false,
            }));
          } else {
            this.setState((prevState) => ({
              client_grid_visible: true,
              preview_grid_visible: false,
              button_clientgrid_nofound_visible: false,
            }));
          }
          ////console.log(resp.data)
        })
        .catch((error) => {
          this.props.history.push({
            pathname: `/`,
          });
          //console.log("massive error", error);
        });
    }
  };
  onClientrowDoubleClick = (e) => {
    this.setState((prevState) => ({
      client_id: e.data.client_id === null ? "" : e.data.client_id,
      client_fname: e.data.client_fname === null ? "" : e.data.client_fname,
      client_lname: e.data.client_lname === null ? "" : e.data.client_lname,
      client_phone_num:
        e.data.client_phone_number === null ? "" : e.data.client_phone_number,
      client_email: e.data.client_email === null ? "" : e.data.client_email,
      client_address: e.data.client_street === null ? "" : e.data.client_street,
      client_city: e.data.client_city === null ? "" : e.data.client_city,
      client_state: e.data.client_state === null ? "" : e.data.client_state,
      client_zip: e.data.client_zip === null ? "" : e.data.client_zip,
      client_grid_visible: false,
      preview_grid_visible: true,
    }));
  };

  handle_validation = () => {
    let preview_check = true;
    // console.log(
    //   "🚀 ~ file: Main_Make_res.js ~ line 980 ~ Make_res ~ handle_validation",
    //   this.state.channel_types.find(
    //     (element) => element.received_type === this.state.channel
    //   ).immediate_email,
    //   this.state.channel_types,
    //   this.state.client_fname !== "" &&
    //     this.state.client_lname !== "" &&
    //     this.state.client_phone_num !== "" &&
    //     this.gridApi.getSelectedRows().length !== 0 &&
    //     preview_check === true &&
    //     ((this.state.channel_types.find(
    //       (element) => element.received_type === this.state.channel
    //     ).immediate_email === true && this.state.client_email === "") === true
    //       ? false
    //       : true)
    // );
    //var selectedRows=this.gridApi.getSelectedRows()

    this.previewgrid.forEachNode((node, index) => {
      //checks that all preview rows have occupancy selected
      preview_check =
        preview_check === false
          ? false
          : node.data.num_Adg === "" || node.data.num_CHg === ""
          ? false
          : true;
    });
    if (
      this.state.client_fname !== "" &&
      this.state.client_lname !== "" &&
      this.state.client_phone_num !== "" &&
      this.gridApi.getSelectedRows().length !== 0 &&
      preview_check === true &&
      ((this.state.channel_types.find(
        (element) => element.received_type === this.state.channel
      ).immediate_email === true && this.state.client_email === "") === true
        ? false
        : true)
    ) {
      return true;
    } else {
      let errrorfind = [
        this.state.client_fname === "" ? true : false,
        this.state.client_lname === "" ? true : false,
        this.state.client_phone_num === "" ? true : false,
        this.state.client_email === "" ? true : false,
      ];
      this.setState((prevState) => ({
        showModal: !prevState.showModal,
        fnameval: errrorfind[0],
        lnameval: errrorfind[1],
        phone_numval: errrorfind[2],
        email_addrval: errrorfind[3],
      }));
      ////console.log(this.previewgrid.getSelectedRows())
      return false; //something is missing
    }
    ////console.log(this.gridApi)
  };
  flash_occupancy = () => {
    //console.log("flash occup", this.previewgrid.getDisplayedRowAtIndex(0));
    this.previewgrid.forEachNode((node, index) => {
      this.previewgrid.flashCells({
        rowNodes: [node],
        columns: [
          node.data.num_Adg === "" ? "num_Adg" : "",
          node.data.num_CHg === "" ? "num_CHg" : "",
        ],
      });
    });
  };

  onPrintRegistration = (reservation_id) => {
    ////console.log("connected")

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/hotel-test-print"
          : "https://apiv1.reserv4me.com/hotel-test-print",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        //Authorization: "internalvar.apikey"
      },
      data: {
        client_info: {
          client_id: this.state.client_id,
          client_fname: this.state.client_fname,
          client_lname: this.state.client_lname,
          client_phone_num: this.state.client_phone_num,
          client_email: this.state.client_email,
          client_address: this.state.client_address,
          client_city: this.state.client_city,
          client_state: this.state.client_state,
          client_zip: this.state.client_zip,
        },
        CIdate: this.state.CIdate,
        COdate: this.state.COdate,
        reservation_info: this.state.rowData_preview,
        grand_total: this.state.grand_total,
        regNum: reservation_id,
        payments: this.state.addedPayment,
        channel: this.state.channel,
      },
      withCredentials: true,
    })
      .then((response) => {
        //console.log(response.data);
        printJS({
          printable: response.data.split(",")[1],
          type: "pdf",
          base64: true,
        });
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Main_Make_res.js ~ line 1132 ~ Make_res ~ error",
          error
        );
        // this.props.history.push({
        //   pathname: `/`,
        // });
      });

    /*.then((result) => {
      console.log(result.data);
      this.setState((prevState) => {
        return { printingview: result.data };
      });
    });*/
  };

  make_reservation = () => {
    //method is called when the one wants to make a reservation
    this.setState(
      () => {
        return { loading: true };
      },
      () => {
        let resolution = this.handle_validation();

        if (this.state.reserv4me_active === false) {
          if (resolution === false) {
            //this.setState(prevState=>({showModal:!prevState.showModal,fnameval:!prevState.fnameval,lnameval:!prevState.lnameval,phone_numval:!prevState.phone_numval}))
          } else {
            /*let req = JSON.stringify({
					client_info: {
						client_id: this.state.client_id,
						client_fname: this.state.client_fname,
						client_lname: this.state.client_lname,
						client_phone_num: this.state.client_phone_num,
						client_email: this.state.client_email,
						client_address: this.state.client_address,
						client_city: this.state.client_city,
						client_state: this.state.client_state,
						client_zip: this.state.client_zip,
						channel: this.state.channel
					},
					CIdate: this.state.CIdate,
					COdate: this.state.COdate,
					reservation_info: this.state.rowData_preview,
					grand_total: this.state.grand_total,
					canMove: this.state.canMove,
					Notes_res: this.state.Notes_res,
					notesadded: this.state.notesadded,
					addedPayment: this.state.addedPayment,
					channel_id: this.state.channel_types.find((element) => element.received_type === this.state.channel)
						.receivedBy_id,
					discountsArray: this.state.discount_array_applied
				});
				let resp = ipcRenderer.sendSync('make-reservation', req);*/

            axios({
              method: "post",
              url:
                process.env.NODE_ENV === "development"
                  ? "http://localhost:3000/make-reservation"
                  : "https://apiv1.reserv4me.com/make-reservation",
              headers: {
                "Access-Control-Allow-Origin":
                  process.env.NODE_ENV === "development"
                    ? "http://localhost:3000"
                    : "https://apiv1.reserv4me.com",
                "Content-Type": "application/json",
                Authorization: "internalvar.apikey",
              },
              data: {
                client_info: {
                  client_id: this.state.client_id,
                  client_fname: this.state.client_fname,
                  client_lname: this.state.client_lname,
                  client_phone_num: this.state.client_phone_num,
                  client_email: this.state.client_email,
                  client_address: this.state.client_address,
                  client_city: this.state.client_city,
                  client_state: this.state.client_state,
                  client_zip: this.state.client_zip,
                  channel: this.state.channel,
                },
                CIdate: this.state.CIdate,
                COdate: this.state.COdate,
                reservation_info: this.state.rowData_preview,
                grand_total: this.state.grand_total,
                canMove: this.state.canMove,
                Notes_res: this.state.Notes_res,
                notesadded: this.state.notesadded,
                addedPayment: this.state.addedPayment,
                channel_id: this.state.channel_types.find(
                  (element) => element.received_type === this.state.channel
                ).receivedBy_id,
                channel_importance: this.state.channel_types.find(
                  (element) => element.received_type === this.state.channel
                ).immediate_email,
                discountsArray: this.state.discount_array_applied,
              },
              withCredentials: true,
            })
              .then((resp) => {
                this.onPrintRegistration(resp.data.reservation_id);
                if (resp.data.status === "ok") {
                  this.grid_cleaner();
                  this.setState((prevState) => ({
                    showsuccessModal: !prevState.showsuccessModal,
                    resulting_reg_Numb: resp.data.reservation_id,
                  }));
                } else {
                  //this.grid_cleaner();
                  //this.setState(prevState=>({showsuccessModal:!prevState.showsuccessModal}))
                  //console.log("seems like sometjing broke");
                }
              })
              // .then((resp) => {
              //   //let result=JSON.parse(resp);
              //   ////console.log("bonjour",result)
              //   if (resp.data.status === "ok") {
              //     this.grid_cleaner();
              //     this.setState((prevState) => ({
              //       showsuccessModal: !prevState.showsuccessModal,
              //       resulting_reg_Numb: resp.data.reservation_id,
              //     }));
              //   } else {
              //     //this.grid_cleaner();
              //     //this.setState(prevState=>({showsuccessModal:!prevState.showsuccessModal}))
              //     //console.log("seems like sometjing broke");
              //   }
              // })
              .catch((error) => {
                console.log(
                  "🚀 ~ file: Main_Make_res.js ~ line 1259 ~ Make_res ~ error",
                  error
                );

                // this.props.history.push({
                //   pathname: `/`,
                // });
              });
          }
        } else {
          //console.log("payment", this.state.addedPayment);

          if (resolution === false) {
            //this.setState(prevState=>({showModal:!prevState.showModal,fnameval:!prevState.fnameval,lnameval:!prevState.lnameval,phone_numval:!prevState.phone_numval}))
          } else {
            /*let req = JSON.stringify({
					client_info: {
						client_id: this.state.client_id,
						client_fname: this.state.client_fname,
						client_lname: this.state.client_lname,
						client_phone_num: this.state.client_phone_num,
						client_email: this.state.client_email,
						client_address: this.state.client_address,
						client_city: this.state.client_city,
						client_state: this.state.client_state,
						client_zip: this.state.client_zip,
						channel: this.state.channel
					},
					CIdate: this.state.CIdate,
					COdate: this.state.COdate,
					reservation_info: this.state.rowData_preview,
					grand_total: this.state.grand_total,
					canMove: this.state.canMove,
					Notes_res: this.state.Notes_res,
					notesadded: this.state.notesadded,
					addedPayment: this.state.channel === 'Reserv4me-Setup' ? [] : this.state.addedPayment,
					reserv4me_active: this.state.reserv4me_active,
					reserv4me_selected_Data: this.state.reserv4me_selected_Data
				});
				let resp = ipcRenderer.sendSync('make-reservation-reserv4me', req);*/

            axios({
              method: "post",
              url:
                process.env.NODE_ENV === "development"
                  ? "http://localhost:3000/make-reservation-reserv4me"
                  : "https://apiv1.reserv4me.com/make-reservation-reserv4me",
              headers: {
                "Access-Control-Allow-Origin":
                  process.env.NODE_ENV === "development"
                    ? "http://localhost:3000"
                    : "https://apiv1.reserv4me.com",
                "Content-Type": "application/json",
                Authorization: "internalvar.apikey",
              },
              data: {
                client_info: {
                  client_id: this.state.client_id,
                  client_fname: this.state.client_fname,
                  client_lname: this.state.client_lname,
                  client_phone_num: this.state.client_phone_num,
                  client_email: this.state.client_email,
                  client_address: this.state.client_address,
                  client_city: this.state.client_city,
                  client_state: this.state.client_state,
                  client_zip: this.state.client_zip,
                  channel: this.state.channel,
                },
                CIdate: this.state.CIdate,
                COdate: this.state.COdate,
                reservation_info: this.state.rowData_preview,
                grand_total: this.state.grand_total,
                canMove: this.state.canMove,
                Notes_res: this.state.Notes_res,
                notesadded: this.state.notesadded,
                addedPayment:
                  this.state.channel === "Reserv4me-Setup"
                    ? []
                    : this.state.addedPayment,
                reserv4me_active: this.state.reserv4me_active,
                reserv4me_selected_Data: this.state.reserv4me_selected_Data,
              },
              withCredentials: true,
            })
              .then((resp) => {
                //let result=JSON.parse(resp);
                //console.log("bonjour", resp.data);
                if (resp.data.status === "ok") {
                  this.grid_cleaner();
                  this.setState((prevState) => ({
                    showsuccessModal: !prevState.showsuccessModal,
                    resulting_reg_Numb: resp.data.reservation_id,
                  }));
                } else {
                  //this.grid_cleaner();
                  //this.setState(prevState=>({showsuccessModal:!prevState.showsuccessModal}))
                  //console.log("seems like sometjing broke");
                }
              })
              .catch((error) => {
                this.props.history.push({
                  pathname: `/`,
                });
              });
          }
        }
      }
    );
  };

  grid_cleaner = () => {
    //resets the state to initial state
    this.setState(
      (prevState) => {
        if (true) {
          return InitialState;
        }
      },
      () => {
        this.sendinfo();
        this.clientgrid.setRowData();
        //let req = JSON.stringify({});
        //---let resp = ipcRenderer.sendSync('get-paymenttype', req);
        axios({
          method: "post",
          url:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/get-paymenttype"
              : "https://apiv1.reserv4me.com/get-paymenttype",
          headers: {
            "Access-Control-Allow-Origin":
              process.env.NODE_ENV === "development"
                ? "http://localhost:3000"
                : "https://apiv1.reserv4me.com",
            "Content-Type": "application/json",
            Authorization: "internalvar.apikey",
          },
          data: {},
          withCredentials: true,
        })
          .then((resp) => {
            this.setState((prevStat) => ({
              payment_types_array: resp.data.paymenttypes,
              channel_types: resp.data.receivedBy,
              channel: resp.data.receivedBy[0].received_type,
              discount_Array: resp.data.discounts,
              discount_selected: resp.data.discounts[0].discount_name,
            }));
          })
          .catch((error) => {
            this.props.history.push({
              pathname: `/`,
            });
          });

        //this.onmainSelectionchanged();
      }
    );
  };
  handleModalClose = (e) => {
    ////console.log("hello",e)
    this.setState((prevState) => ({ showModal: !prevState.showModal }));
    this.flash_occupancy();
  };
  handleSuccessModalClose = (e) => {
    this.setState((prevState) => ({
      showsuccessModal: !prevState.showsuccessModal,
      resulting_reg_Numb: InitialState.resulting_reg_Numb,
    }));
  };
  handleClientGridclose = () => {
    this.setState((prevState) => ({
      client_grid_visible: false,
      preview_grid_visible: true,
      button_clientgrid_nofound_visible: true,
    }));
  };
  handleModal_Notes_Close = () => {
    this.setState((prevState) => ({
      showModal_notes: !prevState.showModal_notes,
    }));
  };
  handleModal_Notes_Show = () => {
    this.setState((prevState) => ({
      showModal_notes: !prevState.showModal_notes,
    }));
  };
  handleModal_AddPayment_Show = () => {
    this.setState((prevState) => ({
      showModal_AddPayment: !prevState.showModal_AddPayment,
    }));
  };
  onNotesValueChanged = (e) => {
    // //console.log(e.target.value)
    let tempNotes = e.target.value;
    this.setState((prevState) => ({ Notes_res: tempNotes }));
  };
  oncanMoveChanged = (e) => {
    // //console.log(e,"changed")
    this.setState((prevState) => ({ canMove: !prevState.canMove }));
  };

  onClickAddPayment = (e) => {
    this.handleModal_AddPayment_Show();
  };

  onAddPaymentButton = (e) => {
    if (this.state.AmountValue !== "") {
      let addedpayment = {
        id: this.state.payment_types_array.find(
          (element) => element.paytype === this.state.SelectedPaymenttype
        ).payment_type_id,
        amount: this.state.AmountValue,
        type_payment: this.state.SelectedPaymenttype,
      };
      this.setState((prevState) => ({
        addedPayment: prevState.addedPayment.concat(addedpayment),
        AmountValue: InitialState.AmountValue,
        SelectedPaymenttype: InitialState.SelectedPaymenttype,
      }));
    } else {
      this.setState((prevState) => ({ ispaymentInvalidated: true }));
    }
    ////console.log(e,"here added")
  };
  onAmountChange = (e) => {
    ////console.log(e,"yes")

    let tempval = e.target.value;
    //tempval=parseFloat(tempval.replace("$",""))
    let regex =
      /^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/;
    ////console.log(regex.test(tempval))
    if (regex.test(tempval)) {
      return this.setState((prevState) => ({
        AmountValue: tempval,
        ispaymentInvalidated: false,
      }));
    } else {
      return null;
    }
  };
  onPaymentTypeSelectedChange = (e) => {
    ////console.log(e.target.value)
    let selectedtype = e.target.value;
    this.setState((prevState) => ({ SelectedPaymenttype: selectedtype }));
  };
  onAddNoteButton = (e) => {
    //console.log(e, "notes");
    if (this.state.Notes_res !== "") {
      this.setState((prevState) => ({
        notesadded: prevState.notesadded.concat(this.state.Notes_res),
        Notes_res: InitialState.Notes_res,
      }));
    } else {
      this.setState((prevState) => ({ isNotesInvalidated: true }));
    }
  };

  Reserv4me_Extraction = () => {
    /*let req = JSON.stringify({});

		let resp = ipcRenderer.sendSync('read-from_External', req);*/

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/read-from_External"
          : "https://apiv1.reserv4me.com/read-from_External",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {},
      withCredentials: true,
    })
      .then((resp) => {
        //console.log("after clicking", resp.data);

        this.setState((prevState) => ({
          reserv4me_reservations: resp.data[0],
          reserve4me_grid_hidden: false,
          client_grid_visible: true,
          preview_grid_visible: true,
          reserv4me_active: true,
          localvexternalroomID: resp.data[1],
          taxrate: parseFloat(resp.data[2].tax_rate) / 100,
          channel: "Reserv4me",
        }));
        this.reserv4megrid.setRowData(resp.data[0]);
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });

    //ipcRenderer.send('test-print')
  };
  Reserv4me_selection_DoubleClick = (e) => {
    let eventobj = e;
    let data = e.data;
    let externalroomID = data.roomtypeid;
    let internalroomID = this.state.localvexternalroomID.find(
      (element) => element.onlineroomtypeid === externalroomID
    );

    //console.log("we are being selected ", eventobj);
    this.reserv4megrid.setRowData([]);
    this.setState(
      (prevState) => ({
        channel:
          data.payment_method === "Stripe-Later"
            ? "Reserv4me-Setup"
            : "Reserv4me",
        client_fname: data.clientfname === null ? "" : data.clientfname,
        client_lname: data.clientlname === null ? "" : data.clientlname,
        client_phone_num: data.clientphone === null ? "" : data.clientphone,
        client_email: data.clientemail === null ? "" : e.data.clientemail,
        client_address:
          data.client_street === null || data.client_street === undefined
            ? ""
            : data.client_street,
        client_city:
          data.client_city === null || data.client_city === undefined
            ? ""
            : data.client_city,
        client_state:
          data.client_state === null || data.client_state === undefined
            ? ""
            : data.client_state,
        client_zip:
          data.client_zip === null || data.client_zip === undefined
            ? ""
            : data.client_zip,
        client_grid_visible: false,
        notesadded: prevState.notesadded.concat(data.special_request),
        preview_grid_visible: true,
        reserve4me_grid_hidden: true,
        webres_id: data.resid,
        CIdate: moment(data.checkin),
        COdate: moment(data.checkout),
        rowData: [],
        columnDefs: InitialState.columnDefs,
        rowClassRules: InitialState.rowClassRules,
        reserv4meindex: eventobj.rowIndex,
        rowSelection: "single",
        reserv4me_selected_Data: {
          data,
          internalroomID: internalroomID.localroomtypeid,
        },
        columnDefs_preview: [
          {
            headerName: "Rm #",
            field: "room_num",
            width: 55,
            lockPosition: true,
          },
          {
            headerName: "Type",
            field: "unittype",
            width: 55,
            lockPosition: true,
          },
          {
            headerName: "Type_id",
            field: "unittypeID",
            width: 55,
            lockPosition: true,
            hide: true,
          },
          { headerName: "CI", field: "date_CI", width: 85, lockPosition: true },
          { headerName: "CO", field: "date_CO", width: 85, lockPosition: true },
          {
            headerName: "Num Adults",
            field: "num_Adg",
            width: 80,
            lockPosition: true,
            cellEditor: "selectcellEditor",
            editable: false,
          },
          {
            headerName: "Num Children",
            field: "num_CHg",
            width: 80,
            lockPosition: true,
            cellEditor: "selectcellEditor",
            editable: false,
          },
          //{ headerName: "Rate Type", field: "ratetype", width: 55, lockPosition: true},
          {
            headerName: "Total unit price",
            field: "total_price",
            width: 85,
            lockPosition: true,
          },
          {
            headerName: "Tax",
            field: "tax_room",
            width: 85,
            lockPosition: true,
          },
          //{ headerName: "Room #", field: "room_num",width: 55, lockPosition: true}
        ],
      }),
      () => {
        this.sendinfo();
        this.getSpecificOnlineInfo();

        /*this.gridApi.isRowSelectable=function (rowNode) {//controls whether main grid row is available for selecting
                //console.log(rowNode)
                return rowNode.data ? rowNode.data.isAvail === true && rowNode.data.unittypeID===1: false;
            }*/
        ////console.log(this.gridApi)
      }
    );
  };

  getSpecificOnlineInfo = () => {
    /*let req = JSON.stringify({
			data_selected: this.state.webres_id,
			num_Adg: this.state.reserv4me_selected_Data.data.occupancy.split('-')[0],
			num_CHg: this.state.reserv4me_selected_Data.data.occupancy.split('-')[1]
		});

		let resp = ipcRenderer.sendSync('getOnlineReservation-Info', req);*/
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/getOnlineReservation-Info"
          : "https://apiv1.reserv4me.com/getOnlineReservation-Info",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        data_selected: this.state.webres_id,
        num_Adg:
          this.state.reserv4me_selected_Data.data.occupancy.split("-")[0],
        num_CHg:
          this.state.reserv4me_selected_Data.data.occupancy.split("-")[1],
      },
      withCredentials: true,
    })
      .then((resp) => {
        //console.log(resp.data);
        this.set_updated_prices(resp.data);
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  handle_AddDiscountModal = () => {
    //console.log("modalhere");
    this.setState((prevState) => ({
      showDiscount_Modal: !prevState.showDiscount_Modal,
    }));
  };

  onDiscountSelectedChange = (e) => {
    let tempval = e.target.value;
    ////console.log(e.value)
    this.setState((prevState) => ({ discount_selected: tempval }));
  };
  onAddDiscountButton = (e) => {
    let discountTemp = {
      id: this.state.discount_Array.find(
        (element) => element.discount_name === this.state.discount_selected
      ).discount_id,
      discount_name: this.state.discount_selected,
      discount_rate: this.state.discount_Array.find(
        (element) => element.discount_name === this.state.discount_selected
      ).discount_rate,
    };

    this.setState(
      (prevState) => ({
        new_discount_array_applied:
          prevState.new_discount_array_applied.concat(discountTemp),
        discount_array_applied:
          prevState.discount_array_applied.concat(discountTemp),
        discount_selected: this.state.discount_Array[0].discount_name,
      }),
      () => {
        this.getpricesdb();
      }
    );
  };

  onDeleteDiscount = (e) => {
    //console.log(this.state.new_discount_array_applied);
    let tempvar = e.currentTarget.id;
    tempvar = tempvar.split("-");
    //console.log(tempvar);

    /*if(tempvar[1]==='N'){

            this.setState(prevState=>{

                const array_new=prevState.new_discount_array_applied.filter((item,j)=>j!==(parseInt(tempvar[0])-prevState.discount_array_applied.length))
                const array=prevState.discount_array_applied.filter((item,j)=>j!==parseInt(tempvar[0]));
                return(
                   {
                       discount_array_applied:array,
                       new_discount_array_applied:array_new
                    }
                )
                
    
            },()=>{

                this.getpricesdb()
            })

        }

        else{
*/

    this.setState(
      (prevState) => {
        const array = prevState.discount_array_applied.filter(
          (item, j) => j !== parseInt(tempvar[0])
        );

        return {
          discount_array_applied: array,
          discounts_to_be_deleted: prevState.discounts_to_be_deleted.concat(
            tempvar[1]
          ),
        };
      },
      () => {
        this.getpricesdb();
      }
    );
    //}
  };

  resertForm = () => {
    //console.log("we are heree");
    this.grid_cleaner();
  };

  /*onOpenNewWindow = () => {
    //ipcRenderer.send("create-alternate-window");
  };*/
  setSidebarOpen = () => {
    this.setState((prevState) => ({ sidebarOpen: !prevState.sidebarOpen }));
  };

  onDateSelectionChange = (CIdate, COdate) => {
    this.setState(
      (prevState) => ({
        CIdate: CIdate,
        COdate: COdate,
        rowData: [],
        columnDefs: InitialState.columnDefs,
        rowClassRules: InitialState.rowClassRules,
      }),
      () => {
        this.sendinfo();
        this.onmainSelectionchanged();
      }
    );
  };

  render() {
    //console.log("in render", process.env);
    return (
      <>
        <div className="flex flex-col min-h-screen overflow-hidden ">
          <SideBar
            sidebarOpen={this.state.sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
          />
          <div className="absolute flex flex-col flex-1 overflow-y-auto overflow-x-hidden w-full">
            <Header
              sidebarOpen={this.state.sidebarOpen}
              setSidebarOpen={this.setSidebarOpen}
              userName={window.sessionStorage.getItem("uname")}
            />
            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full z-0">
                <div className="grid grid-flow-row   lg:grid-cols-12  gap-4 ">
                  <div
                    className="col-span-12 lg:col-span-4  space-y-4 border
                 border-gray-200 shadow-lg p-2 rounded-sm order-2 lg:order-1"
                  >
                    <div className="">
                      <Client_Form
                        client_info={{
                          fname: this.state.client_fname,
                          lname: this.state.client_lname,
                          phone_num: this.state.client_phone_num,
                          email: this.state.client_email,
                          address: this.state.client_address,
                          city: this.state.client_city,
                          state: this.state.client_state,
                          zipcode: this.state.client_zip,
                          channel: this.state.channel,
                        }}
                        onChange={this.clientinfor_change}
                        channel_types={this.state.channel_types}
                        fnameval={this.state.fnameval}
                        lnameval={this.state.lnameval}
                        phone_numval={this.state.phone_numval}
                        email_addrval={this.state.email_addrval}
                      />
                    </div>
                    <div className="">
                      <div
                        className="h-60"
                        hidden={this.state.client_grid_visible}
                      >
                        <Grid_preview
                          Columns={this.state.columnDefs_preview}
                          Rows={this.state.rowData_preview}
                          rowClassRules={this.state.rowClassRules_preview}
                          onCellValueChanged={this.getpricesdb}
                          onGridReady={this.onPreviewReady}
                        />
                      </div>
                      <div
                        className="h-60"
                        hidden={this.state.preview_grid_visible}
                      >
                        <span
                          hidden={this.state.button_clientgrid_nofound_visible}
                        >
                          <button
                            className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12  "
                            onClick={this.handleClientGridclose}
                          >
                            <svg
                              className="shrink-0 h-6 w-6"
                              viewBox="0 0 500 500"
                            >
                              <path
                                fill="currentColor"
                                d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-83.6 290.5c4.8 4.8 4.8 12.6 0 17.4l-40.5 40.5c-4.8 4.8-12.6 4.8-17.4 0L256 313.3l-66.5 67.1c-4.8 4.8-12.6 4.8-17.4 0l-40.5-40.5c-4.8-4.8-4.8-12.6 0-17.4l67.1-66.5-67.1-66.5c-4.8-4.8-4.8-12.6 0-17.4l40.5-40.5c4.8-4.8 12.6-4.8 17.4 0l66.5 67.1 66.5-67.1c4.8-4.8 12.6-4.8 17.4 0l40.5 40.5c4.8 4.8 4.8 12.6 0 17.4L313.3 256l67.1 66.5z"
                              ></path>
                            </svg>
                          </button>
                        </span>
                        <Client_preview_grid
                          Columns={this.state.columDefs_client}
                          Rows={this.state.rowData_client}
                          rowClassRules={this.state.rowClassRules_preview}
                          onCellValueChanged={this.getpricesdb}
                          onGridReady={this.onClientGridREady}
                          onRowDoubleClicked={this.onClientrowDoubleClick}
                        />
                      </div>
                      <div
                        className="shadow-div h-60 "
                        hidden={this.state.reserve4me_grid_hidden}
                      >
                        <Grid_Reserv4me
                          Columns={this.state.columDef_reserv4me}
                          rowSelection={this.state.rowSelection_reserv4me}
                          Rows={this.state.rowData_client}
                          rowClassRules={this.state.rowClassRules_preview}
                          onCellValueChanged={this.getpricesdb}
                          onGridReady={this.onReserv4meGridReady}
                          onRowDoubleClicked={
                            this.Reserv4me_selection_DoubleClick
                          }
                        />
                      </div>
                    </div>
                    <div className="grid md:grid-cols-12 grid-flow-row gap-4 ">
                      <div className=" col-span-12 lg:col-span-4 order-2 lg:order-1">
                        <Button_array
                          onClick={this.onClickAddPayment}
                          onDiscountClick={this.handle_AddDiscountModal}
                        />
                      </div>
                      <div className="col-span-12 lg:col-span-8 order-1 lg:order-2">
                        <Price_box grand_total={this.state.grand_total} />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-8 order-1 lg:order-2 ">
                    <div className="space-y-2 border border-gray-200 shadow-lg p-2 rounded-sm shadow-div">
                      <div className="">
                        <div className="flex items-center space-x-2 space-y-2 ">
                          <span>Dates:</span>
                          <div className="relative  ">
                            <Flatpickr
                              className="form-input pl-9 w-64 text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
                              value={[
                                this.state.CIdate.toDate(),
                                this.state.COdate.toDate(),
                              ]}
                              options={{
                                mode: "range",
                                static: true,
                                monthSelectorType: "static",
                                dateFormat: "M j, Y",
                                minDate: "today",
                                defaultDate: [
                                  this.state.CIdate.toDate(),
                                  this.state.COdate.toDate(),
                                ],
                                prevArrow:
                                  '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
                                nextArrow:
                                  '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
                              }}
                              onReady={(selectedDates, dateStr, instance) => {
                                instance.element.value = dateStr.replace(
                                  "to",
                                  "-"
                                );
                                instance.calendarContainer.classList.add(
                                  `flatpickr-right`
                                );
                              }}
                              onChange={(selectedDates, dateStr, instance) => {
                                if (selectedDates.length === 1) {
                                  //this.date_CI_change(moment(selectedDates[0]));
                                } else {
                                  //this.date_CI_change(moment(selectedDates[0]));
                                  //this.date_CO_change(moment(selectedDates[1]));
                                  this.onDateSelectionChange(
                                    moment(selectedDates[0]),
                                    moment(selectedDates[1])
                                  );
                                }

                                // console.log(
                                //   "🚀 ~ file: Main_Modify_res.js ~ line 2423 ~ Modify_res ~ render ~ selectedDates, dateStr, instance",
                                //   selectedDates,
                                //   dateStr,
                                //   instance
                                // );
                              }}
                              onClose={(selectedDates, dateStr, instance) => {
                                if (selectedDates.length == 1) {
                                  // console.log(
                                  //   "🚀 ~ file: Main_Modify_res.js ~ line 2449 ~ Modify_res ~ render ~ selectedDates",
                                  //   selectedDates[0]
                                  // );

                                  // this.date_CO_change(
                                  //   moment(selectedDates[0]).add(1, "d")
                                  // );
                                  this.onDateSelectionChange(
                                    moment(selectedDates[0]),
                                    moment(selectedDates[0]).add(1, "days")
                                  );

                                  instance.setDate([
                                    selectedDates[0],
                                    moment(selectedDates[0])
                                      .add(1, "d")
                                      .toDate(),
                                    true,
                                  ]);
                                  instance.element.value = dateStr.replace(
                                    "to",
                                    "-"
                                  );
                                }
                              }}
                            />
                            <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
                              <svg
                                className="w-4 h-4 fill-current text-gray-500 ml-3"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <Datepicker
                            CIdate={this.state.CIdate}
                            COdate={this.state.COdate}
                            onChangeCI={this.date_CI_change}
                            onChangeCO={this.date_CO_change}
                            options={this.state.options}
                          ></Datepicker> */}
                        </div>
                      </div>
                      <div className="h-96 lg:h-132">
                        <Grid_main
                          Columns={this.state.columnDefs}
                          Rows={this.state.rowData}
                          rowClassRules={this.state.rowClassRules}
                          onSelectionChanged={this.onmainSelectionchanged}
                          rowSelection={this.state.rowSelection}
                          onGridReady={this.onGridReady}
                          isRowSelectable={this.state.isRowSelectable}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" col-span-12 lg:pl-4 lg:col-start-5 lg:col-end-13 order-3 ">
                    <Button_bar
                      makeres={this.make_reservation}
                      handleshownotes={this.handleModal_Notes_Show}
                      Reserv4me_Extraction={this.Reserv4me_Extraction}
                      resertForm={this.resertForm}
                      //onOpenNewWindow={this.onOpenNewWindow}
                    />
                  </div>
                </div>
              </div>
            </main>
            <Modal_reservation
              className="modal-backdrop fade show"
              handleClose={this.handleModalClose}
              show={this.state.showModal}
            />
            <Modal_reservation_finished
              handleClose={this.handleSuccessModalClose}
              show={this.state.showsuccessModal}
              resulting_reg_Numb={this.state.resulting_reg_Numb}
            />
            <Modal_notes
              canMovechanged={this.oncanMoveChanged}
              canMove={this.state.canMove}
              handleClose={this.handleModal_Notes_Close}
              show={this.state.showModal_notes}
              value={this.state.Notes_res}
              onValueChanged={this.onNotesValueChanged}
              onAddNoteButton={this.onAddNoteButton}
              notesadded={this.state.notesadded}
              isNotesInvalidated={this.state.isNotesInvalidated}
              pagedisplay="maker"
            />
            <Modal_AddPayment
              show={this.state.showModal_AddPayment}
              handleClose={this.handleModal_AddPayment_Show}
              onAddPaymentButton={this.onAddPaymentButton}
              AmountValue={this.state.AmountValue}
              onAmountChange={this.onAmountChange}
              addedPayment={this.state.addedPayment}
              SelectedPaymenttype={this.state.SelectedPaymenttype}
              onPaymentTypeSelectedChange={this.onPaymentTypeSelectedChange}
              ispaymentInvalidated={this.state.ispaymentInvalidated}
              payment_types_array={this.state.payment_types_array}
              pagedisplay="maker"
            />
            <Modal_Add_Discount
              show={this.state.showDiscount_Modal}
              handleClose={this.handle_AddDiscountModal}
              discounttypeArray={this.state.discount_Array}
              discountAdded={this.state.discount_array_applied}
              discountValue={this.state.discount_selected}
              pagedisplay="maker"
              onDiscountChange={this.onDiscountSelectedChange}
              onAddDiscountButton={this.onAddDiscountButton}
              onDeleteDiscount={this.onDeleteDiscount}
            />
            <div
              hidden={this.state.loading === false ? true : false}
              className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  "
            >
              <div className="loading"></div>
              <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
                <p className="text-2xl text-white -translate-y-16">
                  Loading...
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Make_res);
