import React, { Component } from "react";
import "./Registration_Card.css";

class Registration_Card extends React.Component {
  render() {
    return (
      <div
        className="print-source"
        dangerouslySetInnerHTML={{ __html: this.props.html_obj }}
      />
    );
  }
}

export default Registration_Card;
