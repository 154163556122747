import React from "react";

function FieldGroup({
  validationState,
  id,
  mdsize,
  label,
  help,
  type,
  isInvalid,
  ...props
}) {
  return (
    <div className="px-3">
      <label>{label}</label>

      <input
        id={id}
        type={type}
        {...props}
        className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
      />
      <p
        className="text-red-500 text-sm mt-2"
        hidden={isInvalid === false ? true : false}
      >
        {help}
      </p>
    </div>
  );
}

class Basic_Info extends React.Component {
  render() {
    const {
      basic_info,
      onBasicInfo_Change,
      basic_info_validation,
      hotel_logo_verification,
      basic_is_verified,
    } = this.props;
    return (
      <>
        <div className="">
          <h1 className="text-lg">Basic Information</h1>

          <div className="space-y-2">
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="hotel_name"
                type="text"
                label="Hotel Name"
                placeholder="Enter Hotel Name"
                value={basic_info.hotel_name}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_name === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_name
                }
                help={"Add a valid name without special characters"}
              />
              <FieldGroup
                id="hotel_rep"
                label="Hotel Owner/Representative's Name"
                type="text"
                placeholder="Enter Hotel Owner/Representative's Name"
                value={basic_info.hotel_rep}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_rep === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_rep
                }
                help="Add a valid name without special characters"
              />
            </div>
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2 ">
              <FieldGroup
                id="hotel_phone"
                label="Hotel Phone"
                type="tel"
                placeholder="Enter Hotel Phone"
                value={basic_info.hotel_phone}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_phone === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_phone
                }
                help={"Add a valid phone number"}
              />
              <FieldGroup
                id="hotel_email"
                label="Hotel Primary Email"
                type="email"
                placeholder="Enter Hotel Email"
                value={basic_info.hotel_email}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_email === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_email
                }
                help="Add a valid email address"
              />
            </div>
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="hotel_fax"
                label="Hotel Fax"
                type="tel"
                placeholder="Enter Hotel Fax"
                value={basic_info.hotel_fax}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_fax === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_fax
                }
                help={"Add a valid fax number"}
              />
              <FieldGroup
                id="hotel_baddress"
                label="Hotel Address"
                type="text"
                placeholder="Enter Hotel Address"
                value={basic_info.hotel_baddress}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_baddress === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_baddress
                }
                help="Add a valid address"
              />
            </div>
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="hotel_town"
                label="Hotel Town"
                type="text"
                placeholder="Enter Hotel Town"
                value={basic_info.hotel_town}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_town === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_town
                }
                help={"Add a valid town"}
              />
              <FieldGroup
                id="hotel_State"
                label="Hotel State"
                type="text"
                placeholder="Enter Hotel State"
                value={basic_info.hotel_State}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_State === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_State
                }
                help="Add a valid State"
              />
            </div>
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="hotel_zip"
                label="Hotel ZipCode"
                type="text"
                placeholder="Enter Hotel Zip Code"
                alue={basic_info.hotel_zip}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_zip === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_zip
                }
                help={"Add a valid ZipCode"}
              />
              <FieldGroup
                id="hotel_fblink"
                label="Hotel Facebook Page Link"
                type="text"
                placeholder="Enter Hotel Facebook Page Link"
                value={basic_info.hotel_fblink}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_fblink === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_fblink
                }
                help="Add a valid address in the form https://www.domain.com"
              />
            </div>
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="hotel_twlink"
                label="Hotel Twitter Link"
                type="text"
                placeholder="Enter Hotel Twitter Link"
                value={basic_info.hotel_twlink}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_twlink === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_twlink
                }
                help={"Add a valid address in the form https://www.domain.com"}
              />
              <FieldGroup
                id="hotel_inlink"
                label="Hotel Instagram Page Link"
                type="text"
                placeholder="Enter Hotel Instagram Page Link"
                value={basic_info.hotel_inlink}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_inlink === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_inlink
                }
                help="Add a valid address in the form https://www.domain.com"
              />
            </div>
            <div className="grid grid-flow-col pl-3">
              <span className="w-full">Upload Logo Image of your property</span>
              <input
                type="file"
                onChange={this.props.onLogoImgSelected}
                ref={(fileInput) => (this.fileInput = fileInput)}
                style={{ display: "none" }}
                multiple={false}
                accept="image/*"
              ></input>
              <div className="w-full">
                <button
                  className="font-normal  bg-blue-500 text-white p-2 rounded inline "
                  variant="primary"
                  onClick={() => this.fileInput.click()}
                >
                  Pick File
                </button>
                {(() => {
                  if (this.props.file_too_Large === true) {
                    return (
                      <p className="Error_Paragraph">
                        Choose a file that is less than 100kB
                      </p>
                    );
                  } else if (this.props.selectedFiles === "") {
                    if (this.props.hotel_logo_verification === false) {
                      return (
                        <p className="Error_Paragraph">
                          {" "}
                          Please add a logo that represents your company
                        </p>
                      );
                    } else {
                      return <p></p>;
                    }
                  } else {
                    return (
                      <>
                        <p>{`${this.props.selectedFiles[0].name} | ${(
                          this.props.selectedFiles[0].size / 1e6
                        ).toFixed(2)} MB`}</p>
                      </>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Basic_Info;
