import React from "react";
import { withRouter } from "react-router-dom";
import "./modify_reservation.css";
import ReactToPrint from "react-to-print";
//thisis just to test

class Button_bar extends React.Component {
  Main_btn_click = (e, path) => {
    switch (path) {
      case "gotomain": {
        this.props.history.push({
          pathname: `/`,
        });
        break;
      }
      case "mainmenu": {
        this.props.history.push({
          pathname: `/mainmenu`,
        });
        break;
      }
      case "makeres": {
        this.props.history.push({
          pathname: `/makeres`,
        });
        break;
      }
      case "modifyres": {
        this.props.history.push({
          pathname: `/modifyres`,
        });
        break;
      }
      case "dailyrate": {
        this.props.history.push({
          pathname: `/dailyrate`,
        });
        break;
      }
      case "work_book": {
        this.props.history.push({
          pathname: `/workbook`,
        });
        break;
      }
      case "addnotes": {
        //this.props.shownotes
        //console.log("add notes");
        break;
      }

      default: {
        this.props.history.push({
          pathname: `/`,
        });
        break;
      }
    }
  };
  render() {
    return (
      <>
        <div className=" flex flex-wrap justify-center md:justify-start space-x-2 gap-2 ">
          <button
            className={`font-semibold ${
              this.props.disableModify === true &&
              this.props.reservation_is_cancelled === false
                ? "bg-blue-400"
                : "bg-blue-500"
            }  text-white  p-2 rounded w-8`}
            onClick={this.props.resetForm}
            disabled={
              this.props.disableModify === true &&
              this.props.reservation_is_cancelled === false
                ? true
                : false
            }>
            <svg viewBox="0 0 512 512">
              <path
                fill="currentcolor"
                d="M492 8h-10c-6.627 0-12 5.373-12 12v110.625C426.804 57.047 346.761 7.715 255.207 8.001 118.82 8.428 7.787 120.009 8 256.396 8.214 393.181 119.166 504 256 504c63.926 0 122.202-24.187 166.178-63.908 5.113-4.618 5.354-12.561.482-17.433l-7.069-7.069c-4.503-4.503-11.749-4.714-16.482-.454C361.218 449.238 311.065 470 256 470c-117.744 0-214-95.331-214-214 0-117.744 95.331-214 214-214 82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12z"></path>
            </svg>
          </button>
          <button
            onClick={this.props.modify_Reservation}
            disabled={this.props.disableModify}
            className={`font-normal ${
              this.props.disableModify === true ? "bg-blue-400" : "bg-blue-500"
            }  text-white  p-2 rounded `}>
            Modify Reservation
          </button>
          <button
            className={`font-normal ${
              this.props.disableModify === true ? "bg-blue-400" : "bg-blue-500"
            }  text-white  p-2 rounded w-8`}
            onClick={(e) => {
              this.Main_btn_click(e, "mainmenu");
            }}
            id="mainmenu">
            <svg viewBox="0 0 512 512">
              <path
                fill="currentColor"
                d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                class=""></path>
            </svg>
          </button>
          {/*<button
                        onClick={this.Main_btn_click}
                        id="gotomain"
                        className="btnbar">Go Back to Main</button>*/}
          <button
            className={`font-normal ${
              this.props.disableModify === true ? "bg-blue-400" : "bg-blue-500"
            }  text-white  p-2 rounded `}
            onClick={this.props.handleshownotes}
            id="addnotes"
            disabled={this.props.disableModals}>
            View Notes
          </button>
          <button
            className={`font-normal ${
              this.props.disableModify === true ? "bg-blue-400" : "bg-blue-500"
            }  text-white  p-2 rounded `}
            onClick={this.props.cancelReservation}
            disabled={this.props.disableModify}>
            Cancel{" "}
          </button>
          <button
            className={`font-normal ${
              this.props.disableModify === true ? "bg-blue-400" : "bg-blue-500"
            }  text-white  p-2 rounded `}
            onClick={this.props.resendEmailConfirmation}
            disabled={this.props.disableModify}>
            Confirmation
          </button>

          <button
            className={`font-normal ${
              this.props.disableModify === true ? "bg-blue-400" : "bg-blue-500"
            }  text-white  p-2 rounded `}
            onClick={this.props.onPrintRegistration}
            disabled={this.props.disableModify}>
            Print Registration{" "}
          </button>

          <button
            className={`font-normal ${
              this.props.disableModify === true ? "bg-blue-400" : "bg-blue-500"
            }  text-white  p-2 rounded `}
            onClick={this.props.handlePay_Req}
            disabled={this.props.disableModify}>
            Request Payment{" "}
          </button>

          {/* <ReactToPrint
            onBeforeGetContent={this.props.onPrintRegistration}
            trigger={() => (
              <button
                className={`font-normal ${
                  this.props.disableModify === true
                    ? "bg-blue-400"
                    : "bg-blue-500"
                }  text-white  p-2 rounded `}
                onClick={this.props.onPrintRegistration}
                disabled={this.props.disableModify}
              >
                Print Registration{" "}
              </button>
            )}
            content={() => this.props.content}
          /> */}
          {/*<button 
                        onClick={this.props.onPrintRegistration} 
                        disabled={this.props.disableModify} 
                    className="btnbar">Print Registration </button>*/}
          <button
            className="font-normal  bg-blue-500 text-white p-2 rounded  text-center "
            onClick={this.props.testchanges}
            id="DownloadCSV">
            <svg viewBox="0 0 512 512" className="w-6">
              <path
                fill="currentColor"
                d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"
                class=""></path>
            </svg>
          </button>
        </div>
      </>
    );
  }
}
export default withRouter(Button_bar);
