import React, { Fragment } from "react";
import moment from "moment";
import Client_Form from "../Make_reservation/Client_Form";
import Grid_main from "../Make_reservation/Grid_main";
import Client_preview_grid from "../Make_reservation/Client_preview_grid";
import Grid_preview from "../Make_reservation/Grid_preview";
import Price_box from "../Make_reservation/price_box";
import Button_bar from "./Button_bar_modify";
import Modal_res_not_found from "./Modal_res_not_found";
import Button_array from "../Make_reservation/Button_array";
import Modal_AddPayment from "../Make_reservation/Modal_Add_Payment";
import Modal_notes from "../Make_reservation/Modal_notes";
import Modal_res_was_cancelled from "./Modal_reservation_was_canceled";
import DialogModify from "./Dialog_Modify";
import DialogShell from "./DialogShell";
import Modal_cancel_res from "./Modal_cancel";
import axios from "axios";
import Modal_Handle_Pay_req from "../Make_reservation/Modal_Handle_Pay_req";

import RegNumBox from "./regNumberBox";
//import { truncate } from "fs";

import Modal_Add_Discount from "../Make_reservation/Modal_AddDiscount";

import ReactToPrint from "react-to-print";
import Registration_Card from "../Registration_Card/Registration_Card";

import SideBar from "../Header Menu/SideBar";
import Header from "../Header Menu/Header";
import Flatpickr from "react-flatpickr";
import printJS from "print-js";

const today = moment();

//const { ipcRenderer } = window.require("electron");

let immutableStore = [];

let index_of_changes = [];

const InitialState = {
  client_id: 0,
  client_fname: "",
  client_lname: "",
  client_phone_num: "",
  client_email: "",
  client_address: "",
  client_city: "",
  client_state: "",
  client_zip: "",
  channel: "",
  channel_types: [],

  room_inv_detail: [],
  columnDefs: [
    {
      headerName: "Room Info",
      children: [
        {
          headerName: "Rm #",
          field: "roomnumb",
          width: 80,
          lockPosition: true,
          rowDrag: true,
        },
        {
          headerName: "Type",
          field: "unittype",
          width: 80,
          lockPosition: true,
        },
        {
          headerName: "TypeID",
          field: "unittypeID",
          width: 80,
          lockPosition: true,
          hide: true,
        },
        {
          headerName: "Avail",
          field: "isAvail",
          width: 50,
          lockPosition: true,
          hide: true,
        },
        {
          headerName: "Canbeshift",
          field: "isShift",
          width: 55,
          lockPosition: true,
          hide: true,
        },
      ],
    },
  ],
  rowData: [],
  maingrid_hasSelection: false,

  rowClassRules: {
    /*"cellreservation":function(params){
            let reservation=params.data["regnum_11/14/2019"]
            return reservation===38008
        }*/
  },

  //rowSelection: "multiple",//controls how the main grid in selectable
  isRowSelectable: function (rowNode) {
    //controls whether main grid row is available for selecting
    return rowNode.data ? rowNode.data.isAvail === true : false;
  },

  CIdate: moment(),
  COdate: moment().add(7, "days"),

  columnDefs_preview: [
    { headerName: "Rm #", field: "room_num", width: 55, lockPosition: true },
    { headerName: "Type", field: "unittype", width: 55, lockPosition: true },
    {
      headerName: "Type_id",
      field: "unittypeID",
      width: 55,
      lockPosition: true,
      hide: true,
    },
    { headerName: "CI", field: "date_CI", width: 85, lockPosition: true },
    { headerName: "CO", field: "date_CO", width: 85, lockPosition: true },
    {
      headerName: "Num Adults",
      field: "num_Adg",
      width: 80,
      lockPosition: true,
      cellEditor: "selectcellEditor",
      editable: true,
    },
    {
      headerName: "Num Children",
      field: "num_CHg",
      width: 80,
      lockPosition: true,
      cellEditor: "selectcellEditor",
      editable: true,
    },
    //{ headerName: "Rate Type", field: "ratetype", width: 55, lockPosition: true},
    {
      headerName: "Total unit price",
      field: "total_price",
      width: 85,
      lockPosition: true,
    },
    { headerName: "Tax", field: "tax_room", width: 85, lockPosition: true },
    //{ headerName: "Room #", field: "room_num",width: 55, lockPosition: true}
  ],
  rowData_preview: [],
  rowClassRules_preview: [],

  selectedRows: [],
  grand_total: {
    total_wo_tax: 0,
    tax_total: 0,
    total_w_tax: 0,
  },

  //error handling
  showModal: false,
  fnameval: false,
  lnameval: false,
  phone_numval: false,
  email_addrval: false,

  gridinfo: "",

  showsuccessModal: false,

  columDefs_client: [
    {
      headerName: "First",
      field: "client_fname",
      width: 60,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Last",
      field: "client_lname",
      width: 60,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Phone #",
      field: "client_phone_number",
      width: 100,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Email",
      field: "client_email",
      width: 160,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "CI",
      field: "ci_date",
      width: 80,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "CO",
      field: "co_date",
      width: 60,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "ZIP",
      field: "total",
      width: 55,
      lockPosition: true,
      resizable: true,
    },
    {
      headerName: "Client_ID",
      field: "client_id",
      width: 55,
      lockPosition: true,
    },
    {
      headerName: "Reservation_ID",
      field: "reservation_id",
      width: 90,
      lockPosition: true,
    }, //hide:true },
    { headerName: "Items", field: "items", width: 90, lockPosition: true }, //hide:true },
  ],
  rowData_client: [],

  client_grid_visible: false,
  preview_grid_visible: true,
  button_clientgrid_nofound_visible: true,

  showModal_notes: false,
  Notes_res: "",

  regNumEntered: "",
  items_reservation: "", //how many rooms are in the reseravations
  reservation_specification: [],
  changed_to_reservation_shift: [],
  changed_from_reservation_shift: [],
  //modificationshappened

  client_info_changed: false,
  room_swap_changed: false,
  reservation_details_changed: false,

  showModal_res_not_found: false,

  showModal_notes: false,
  Notes_res: "",
  notesadded: [],
  canMove: true,
  isNotesInvalidated: false,

  showModal_AddPayment: false,
  addedPayment: [],
  AmountValue: "",
  SelectedPaymenttype: "CC",
  payment_types_array: [],
  ispaymentInvalidated: false,

  disableModals: true,
  disableModify: true,

  Modal_res_cancelled: false,

  showModal_pay_req: false,
  Amount_req: "",
  ispayment_req_Invalidated: false,
  requestFull: false,

  DialogModify: false,
  Dialog_Cancel: false,

  DialogShellShown: false,
  DialogShelltxt: "",
  DialongShellHeader: "",

  reservationwasSelected: false,
  client_info_updated: false,

  showDiscount_Modal: false,
  discount_Array: [],
  discount_array_applied: [],
  discount_selected: "",
  new_discount_array_applied: [],
  discounts_to_be_deleted: [],
  schedule_payment_time: 0,
  webRegID: {}, //constains both trip id and webregId
  scheduled_amount: 0,
  scheduled_payment_failed: false,
  schedule_payment_occured: false,
  scheduled_payment_was_collected: false,

  printingview: "",
  sidebarOpen: false,

  analytics_mode_disabled: true,
  date_analytics: moment(),
  reservation_is_cancelled: false,
};

let changed_from_reservation_shift = [];

class Modify_res extends React.Component {
  constructor(props) {
    super(props);
    //this.onRegNumChange=this.onRegNumChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this);
    this.state = InitialState;
  }

  date_CI_change = (value) => {
    //console.log("CIval", value, this.state.COdate.diff(value, "days"));

    const CIvalue = value;
    if (
      /*value.diff(today, "days") >= 0 && */
      this.state.COdate.diff(value, "days") > 0
    ) {
      this.setState(
        (prevState) => ({
          CIdate: value,
          // COdate: moment(value).add(7, "days"),
          rowData: [],
          columnDefs: InitialState.columnDefs,
          rowClassRules: InitialState.rowClassRules,
        }),
        () => {
          this.sendinfo();
          this.onmainSelectionchanged();
        }
      );
    }
    if (this.state.COdate.diff(value, "days") <= 0) {
      //console.log("option B CI");
      this.setState(
        (prevState) => ({
          CIdate: CIvalue,
          COdate: moment(CIvalue).add(1, "days"),
          rowData: [],
          columnDefs: InitialState.columnDefs,
          rowClassRules: InitialState.rowClassRules,
        }),
        () => {
          this.sendinfo();
          this.onmainSelectionchanged();
        }
      );
    }
  };
  date_CO_change = (value) => {
    const COvalue = value;
    if (COvalue > this.state.COdate && COvalue > this.state.CIdate) {
      //dateCOenter.diff(this.state.dateCO,"days")>=0&&dateCOenter.diff(this.state.dateCI,"days")>0)
      ////console.log("op[tion a ", value)
      this.setState(
        (prevState) => ({
          COdate: moment(value),
          rowData: [],
          columnDefs: InitialState.columnDefs,
          rowClassRules: InitialState.rowClassRules,
        }),
        () => {
          this.sendinfo();
          this.onmainSelectionchanged();
        }
      );
    } else {
      //console.log("option B");
      if (COvalue < this.state.COdate && COvalue > today) {
        //console.log("Modify_res -> date_CO_change -> COvalueoptionB", COvalue);
        if (COvalue <= this.state.CIdate) {
          this.setState(
            (prevState) => ({
              CIdate: prevState.CIdate.subtract(
                prevState.CIdate.diff(COvalue, "days") + 1,
                "days"
              ),
              COdate: COvalue,
              rowData: [],
              columnDefs: InitialState.columnDefs,
              rowClassRules: InitialState.rowClassRules,
            }),
            () => {
              this.sendinfo();
              this.onmainSelectionchanged();
            }
          );
        } else if (COvalue > this.state.CIdate) {
          this.setState(
            (prevState) => ({
              COdate: COvalue,
              rowData: [],
              columnDefs: InitialState.columnDefs,
              rowClassRules: InitialState.rowClassRules,
            }),
            () => {
              this.sendinfo();
              this.onmainSelectionchanged();
            }
          );
        }
      } else if (COvalue < this.state.COdate && COvalue <= today) {
        //console.log("Modify_res -> date_CO_change -> testCO less than day",COvalue);

        this.setState(
          (prevState) => ({
            //dateCI: COvalue.diff(prevState.CIdate, "days") > 0 ? today : today,
            COdate: COvalue,
            rowData: [],
            columnDefs: InitialState.columnDefs,
            rowClassRules: InitialState.rowClassRules,
          }),
          () => {
            this.sendinfo();
            this.onmainSelectionchanged();
          }
        );
      }
    }

    /*this.setState(prevState => {
            if (prevState.COdate !== value) {
                return ({
                    COdate: value,
                    rowData: [],
                    columnDefs: InitialState.columnDefs,
                    rowClassRules: InitialState.rowClassRules
                })
            }
        }, () => {
            this.sendinfo();
            this.onmainSelectionchanged();
        })*/
  };
  componentDidMount() {
    /*let req = JSON.stringify({

        })
        let resp = ipcRenderer.sendSync('get-paymenttype', req);*/
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/get-paymenttype"
          : "https://apiv1.reserv4me.com/get-paymenttype",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {},
      withCredentials: true,
    })
      .then((resp) => {
        this.setState((prevStat) => ({
          payment_types_array: resp.data.paymenttypes,
          channel_types: resp.data.receivedBy,
          channel: resp.data.receivedBy[0].received_type,
          discount_Array: resp.data.discounts,
          discount_selected: resp.data.discounts[0].discount_name,
        }));
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  }

  sendinfo = () => {
    //gets the information from the database to draw the databook

    /*let req = JSON.stringify({
            fromdate: this.state.CIdate,
            untildate: this.state.COdate,
            regNum: this.state.regNumEntered,
        })
        //---let resp = ipcRenderer.sendSync('book-getter', req);*/
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/book-getter"
          : "https://apiv1.reserv4me.com/book-getter",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        fromdate: this.state.CIdate,
        untildate: this.state.COdate,
        regNum: this.state.regNumEntered,
        analytics_mode_disabled: this.state.analytics_mode_disabled,
        date_analytics:
          this.state.analytics_mode_disabled === true
            ? ""
            : this.state.date_analytics,
      },
      withCredentials: true,
    })
      .then((resp) => {
        //console.log("data", resp.data, this.state.regNumEntered === "");
        if (this.state.regNumEntered !== "") {
          resp.data.daterange.columns.splice(0, 0, {
            headerName: "Room Info",
            children: [
              {
                headerName: "Rm #",
                field: "roomnumb",
                width: 80,
                lockPosition: true,
                rowDrag: true,
                cellClassRules: {
                  cellcanShift: function (params) {
                    let matchingcase = params.data["isShift"];
                    ////console.log(matchingcase,tempvar.format('MM/DD/YYYY'),resp.data.daterange.columns[i].children[0].field)
                    return matchingcase === true;
                  },
                },
              },
              {
                headerName: "Type",
                field: "unittype",
                width: 80,
                lockPosition: true,
              },
              {
                headerName: "TypeID",
                field: "unittypeID",
                width: 80,
                lockPosition: true,
                hide: true,
              },
              {
                headerName: "Avail",
                field: "isAvail",
                width: 50,
                lockPosition: true,
                hide: true,
              },
              {
                headerName: "Canbeshift",
                field: "isShift",
                width: 55,
                lockPosition: true,
                hide: true,
              },
            ],
          });
        }

        for (
          let i = this.state.regNumEntered !== "" ? 1 : 0;
          i < resp.data.daterange.columns.length;
          i++
        ) {
          Object.assign(
            resp.data.daterange.columns[i].children[0].cellClassRules,

            {
              cellCI_Selected: function (params) {
                let matchingcase =
                  params.data[
                    `${resp.data.daterange.columns[i].children[0].field}`
                  ];
                //console.log("Modify_res -> sendinfo -> params2", matchingcase);

                if (matchingcase === undefined) {
                  return null;
                } else {
                  if (matchingcase.split("-").length === 2) {
                    return (
                      parseInt(matchingcase.split("-")[0]) ===
                        parseInt(resp.data.daterange.regNum) &&
                      matchingcase.split("-")[1] === "CI"
                    );
                  }
                }
              },
              cellCI: function (params) {
                let matchingcase =
                  params.data[
                    `${resp.data.daterange.columns[i].children[0].field}`
                  ];
                //console.log("Modify_res -> sendinfo -> params2", matchingcase);

                if (matchingcase.split("-").length === 2) {
                  return matchingcase.split("-")[1] === "CI";
                }
                //return matchingcase === true;
              },
              cellreservation: function (params) {
                let matchingcase =
                  params.data[
                    `${resp.data.daterange.columns[i].children[0].field}`
                  ];
                if (matchingcase.split("-").length === 1) {
                  return (
                    parseInt(matchingcase.split("-")[0]) ===
                    parseInt(resp.data.daterange.regNum)
                  );
                } else if (matchingcase.split("-").length === 2) {
                  return false;
                }
                /*//console.log(
                "resp",
                params.data,
                params.data[
                  `${resp.data.daterange.columns[i].children[0].field}`
                ].split("-").length === 1,
                resp.data.daterange.columns[i].children[0].field
              ); //this outputs the field name which is necessary to search the params
              return matchingcase === parseInt(resp.data.daterange.regNum);*/
              },
            }
          );
          Object.assign(
            resp.data.daterange.columns[i].children[1].cellClassRules,

            {
              cellreservation: function (params) {
                ////console.log("Modify_res -> sendinfo -> params", params);
                let matchingcase =
                  params.data[
                    `${resp.data.daterange.columns[i].children[0].field}`
                  ];
                if (matchingcase.split("-").length === 1) {
                  return (
                    parseInt(matchingcase.split("-")[0]) ===
                    parseInt(resp.data.daterange.regNum)
                  );
                } else if (matchingcase.split("-").length === 2) {
                  return (
                    parseInt(matchingcase.split("-")[0]) ===
                    parseInt(resp.data.daterange.regNum)
                  );
                }
                ////console.log(matchingcase,tempvar.format('MM/DD/YYYY'),resp.data.daterange.columns[i].children[0].field)
                //return matchingcase === parseInt(resp.data.daterange.regNum);
              },
            }
          );
        }
        immutableStore = resp.data.daterange.rowData;

        //console.log("after changes", resp.data.daterange);
        this.gridApi.setRowData(resp.data.daterange.rowData);
        this.setState((prevState) => ({
          columnDefs:
            this.state.regNumEntered != 0
              ? resp.data.daterange.columns
              : prevState.columnDefs.concat(resp.data.daterange.columns),
          rowData: prevState.rowData.concat(resp.data.daterange.rowData),
          room_inv_detail: prevState.room_inv_detail.concat(
            resp.data.daterange.room_inv_detail
          ),
        }));
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
    //Flatpickr.changeMonth(1);

    //  this.gridApi.forEachNode((node, index) => {
    //      //console.log("new method", node.id === "5")
    //      if (node.id === "4") {
    //          node.setSelected(true, true)
    //          //console.log(node)
    //      }
    //  })
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    //this.setState({gridinfo:params.api})
    this.gridApi.setSuppressRowDrag(true);

    this.sendinfo();
  };

  onmainSelectionchanged = (e, value) => {
    //when the main grid is selected the even is created to make the preview grid display the selection
    // var selectedRows = this.gridApi.getSelectedRows()
    // var selectedindex = this.gridApi.getSelectedNodes()
    // let temprowData = []
    // for (let i = 0; i < selectedRows.length; i++) {
    //     temprowData[i] = {
    //         room_num: selectedRows[i].roomnumb,
    //         unittype: selectedRows[i].unittype,
    //         unittypeID: this.state.room_inv_detail[selectedindex[i].rowIndex].type_id,
    //         date_CI: this.state.CIdate.format("MM/DD/YYYY"),
    //         date_CO: this.state.COdate.format("MM/DD/YYYY"),//moment(this.state.COdate.format("MM/DD/YYYY")).add(1,"days").format("MM/DD/YYYY"),
    //         num_Adg: value ? (value[i].num_Adg !== "" ? `${value[i].num_Adg}` : "") : "",
    //         num_CHg: value ? (value[i].num_CHg !== "" ? `${value[i].num_CHg}` : "") : "",
    //         total_price: value ? (value[i].total_cost_wo_tax !== "" ? `$${value[i].total_cost_wo_tax}` : "") : "",
    //         tax_room: value ? (value[i].tax_total !== "" ? `$${value[i].tax_total}` : "") : ""
    //     }
    // }
    // this.setState(prevState => {
    //     return (
    //         { rowData_preview: temprowData, maingrid_hasSelection: !prevState.maingrid_hasSelection, client_grid_visible: true, preview_grid_visible: false }
    //     )
    // })
  };
  formSubmit = (e) => {
    if (e.key === "Enter") {
      //console.log("hello ");
      /*let req = JSON.stringify({
                regNum: this.state.regNumEntered === "" ? 0 : this.state.regNumEntered,
            })
            let resp = ipcRenderer.sendSync('reservation-search', req)*/

      axios({
        method: "post",
        url:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/reservation-search"
            : "https://apiv1.reserv4me.com/reservation-search",
        headers: {
          "Access-Control-Allow-Origin":
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://apiv1.reserv4me.com",
          "Content-Type": "application/json",
          Authorization: "internalvar.apikey",
        },
        data: {
          regNum:
            this.state.regNumEntered === "" ? 0 : this.state.regNumEntered,
        },
        withCredentials: true,
      })
        .then((resp) => {
          console.log("after long seach", resp.data);
          if (resp.data.isFound === true) {
            this.setState(
              (prevState) => ({
                CIdate: moment(resp.data.ci_date),
                COdate: moment(resp.data.co_date),
                rowData: [],
                columnDefs: InitialState.columnDefs,
                rowClassRules: InitialState.rowClassRules,
                client_id: resp.data.client_id,
                client_fname: resp.data.client_fname,
                client_lname: resp.data.client_lname,
                client_phone_num: resp.data.client_phone_number,
                client_email: resp.data.client_email,
                client_address: resp.data.client_street,
                client_city: resp.data.client_city,
                client_state: resp.data.client_state,
                client_zip: resp.data.client_zip,
                items_reservation: resp.data.items,
                reservationwasSelected: true,
                new_discount_array_applied: [],
                discounts_to_be_deleted: [],
                discount_array_applied: [],
                schedule_payment_time:
                  resp.data.days_before_due === null
                    ? ""
                    : resp.data.days_before_due,
                webRegID:
                  resp.data.web_res_id === null ||
                  resp.data.tripidonline === null
                    ? ""
                    : {
                        webReg: resp.data.web_res_id,
                        tripid: resp.data.tripidonline,
                      },
                scheduled_amount:
                  resp.data.scheduled_amount === null
                    ? ""
                    : resp.data.scheduled_amount,
                scheduled_payment_failed: false,
                schedule_payment_occured: false,
                scheduled_payment_was_collected: resp.data.scheduled_collected,
                reservation_is_cancelled:
                  resp.data.status === "Cancelled" ? true : false,
              }),
              () => {
                this.sendinfo();
                this.onmainSelectionchanged();
                this.getReservationInfo();
                index_of_changes = [];
              }
            );
          } else {
            this.setState(
              (prevState) => {
                if (true) {
                  return InitialState;
                }
              },
              () => {
                this.sendinfo();
                this.onmainSelectionchanged();
                this.componentDidMount();
                index_of_changes = [];
              }
            );

            this.handleModal_res_not_found_Close();
          }
        })
        .catch((error) => {
          this.props.history.push({
            pathname: `/`,
          });
        });
      ////console.log(resp.data!==undefined)
    }
  };
  onRegNumChange = (e) => {
    //test what
    //console.log(e.target.value);
    let tempval = e.target.value;
    let regex = /^-?\d*$/;
    ////console.log(regex.test(tempval))
    if (regex.test(tempval)) {
      return this.setState((prevState) => ({ regNumEntered: tempval }));
    } else {
      return null;
    }
  };
  onClientGridREady = (params) => {
    this.clientgrid = params.api;
  };
  onPreviewReady = (params) => {
    this.previewgrid = params.api;
  };
  clientinfor_change = (e) => {
    //checks which form field has been changed and makes an update to the object

    // if(this.state.reservationwasSelected===false){
    switch (e.target.id) {
      case "fname": {
        this.setState({ client_fname: e.target.value }, () => {
          if (this.state.reservationwasSelected === false) {
            this.onClientFormSearch();
          } else {
            //console.log("change", e);
            this.setState((prevState) => ({ client_info_updated: true }));
          }
        });
        break;
      }
      case "lname": {
        this.setState({ client_lname: e.target.value }, () => {
          if (this.state.reservationwasSelected === false) {
            this.onClientFormSearch();
          } else {
            //console.log("change", e);
            this.setState((prevState) => ({ client_info_updated: true }));
          }
        });
        break;
      }
      case "phonenumb": {
        this.setState({ client_phone_num: e.target.value }, () => {
          if (this.state.reservationwasSelected === false) {
            this.onClientFormSearch();
          } else {
            //console.log("change", e);
            this.setState((prevState) => ({ client_info_updated: true }));
          }
        });
        break;
      }
      case "Email": {
        this.setState({ client_email: e.target.value }, () => {
          if (this.state.reservationwasSelected === false) {
            this.onClientFormSearch();
          } else {
            //console.log("change", e);
            this.setState((prevState) => ({ client_info_updated: true }));
          }
        });
        break;
      }
      case "Street": {
        this.setState({ client_address: e.target.value }, () => {
          if (this.state.reservationwasSelected === false) {
            this.onClientFormSearch();
          } else {
            //console.log("change", e);
            this.setState((prevState) => ({ client_info_updated: true }));
          }
        });
        break;
      }
      case "Cityname": {
        this.setState({ client_city: e.target.value }, () => {
          if (this.state.reservationwasSelected === false) {
            this.onClientFormSearch();
          } else {
            //console.log("change", e);
            this.setState((prevState) => ({ client_info_updated: true }));
          }
        });
        break;
      }
      case "clstate": {
        this.setState({ client_state: e.target.value }, () => {
          if (this.state.reservationwasSelected === false) {
            this.onClientFormSearch();
          } else {
            //console.log("change", e);
            this.setState((prevState) => ({ client_info_updated: true }));
          }
        });
        break;
      }
      case "zip": {
        this.setState({ client_zip: e.target.value }, () => {
          if (this.state.reservationwasSelected === false) {
            this.onClientFormSearch();
          } else {
            //console.log("change", e);
            this.setState((prevState) => ({ client_info_updated: true }));
          }
        });
        break;
      }
      case "channel": {
        this.setState({ channel: e.target.value }, () => {
          if (this.state.reservationwasSelected === false) {
            this.onClientFormSearch();
          } else {
            //console.log("change", e);
            this.setState((prevState) => ({ client_info_updated: true }));
          }
        });
        break;
      }
    }

    // else {
    //     //console.log("change", e)
    //     this.setState(prevState => ({ client_info_updated: true }))
    // }
  };
  onClientFormSearch = () => {
    /*let req = JSON.stringify({
            client_info: {
                client_fname: this.state.client_fname,
                client_lname: this.state.client_lname,
                client_phone_num: this.state.client_phone_num,
                client_email: this.state.client_email,
            }
        })
        let resp = ipcRenderer.sendSync('reservation-search-cl', req);*/

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/reservation-search-cl"
          : "https://apiv1.reserv4me.com/reservation-search-cl",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        client_info: {
          client_fname: this.state.client_fname,
          client_lname: this.state.client_lname,
          client_phone_num: this.state.client_phone_num,
          client_email: this.state.client_email,
        },
      },
      withCredentials: true,
    })
      .then((resp) => {
        this.clientgrid.setRowData(resp.data);
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
    ////console.log(resp)
  };
  onClientrowDoubleClick = (e) => {
    ////console.log(e)
    let dataselected = e.data;
    //console.log("doubnleclick", dataselected);
    this.setState(
      {
        CIdate: moment(dataselected.ci_date),
        COdate: moment(dataselected.co_date),
        rowData: [],
        columnDefs: InitialState.columnDefs,
        rowClassRules: InitialState.rowClassRules,
        client_id:
          dataselected.client_id === null ? "" : dataselected.client_id,
        client_fname:
          dataselected.client_fname === null ? "" : dataselected.client_fname,
        client_lname:
          dataselected.client_lname === null ? "" : dataselected.client_lname,
        client_phone_num:
          dataselected.client_phone_number === null
            ? ""
            : dataselected.client_phone_number,
        client_email:
          dataselected.client_email === null ? "" : dataselected.client_email,
        client_address:
          dataselected.client_street === null ? "" : dataselected.client_street,
        client_city:
          dataselected.client_city === null ? "" : dataselected.client_city,
        client_state:
          dataselected.client_state === null ? "" : dataselected.client_state,
        client_zip:
          dataselected.client_zip === null ? "" : dataselected.client_zip,
        regNumEntered:
          dataselected.reservation_id === null
            ? ""
            : dataselected.reservation_id,
        items_reservation: dataselected.items,
        schedule_payment_time:
          dataselected.days_before_due === null
            ? ""
            : dataselected.days_before_due,
        webRegID:
          dataselected.web_res_id === null || dataselected.tripidonline === null
            ? ""
            : {
                webReg: dataselected.web_res_id,
                tripid: dataselected.tripidonline,
              },
        scheduled_amount:
          dataselected.scheduled_amount === null
            ? ""
            : dataselected.scheduled_amount,
        scheduled_payment_failed: false,
        schedule_payment_occured: false,
        scheduled_payment_was_collected: dataselected.scheduled_collected,
      },
      () => {
        this.sendinfo();
        this.onmainSelectionchanged();
        this.getReservationInfo();
      }
    );
  };

  getReservationInfo() {
    //console.log(this.state.items_reservation);
    /*let req = JSON.stringify({
            client_info: {
                client_fname: this.state.client_fname,
                client_lname: this.state.client_lname,
                client_phone_num: this.state.client_phone_num,
                client_email: this.state.client_email,
            },
            regNum: this.state.regNumEntered,
            CIdate: this.state.CIdate,
            COdate: this.state.COdate,
            items_reservation: this.state.items_reservation
        })
        let resp = ipcRenderer.sendSync('reservation-particular-info', req);*/

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/reservation-particular-info"
          : "https://apiv1.reserv4me.com/reservation-particular-info",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        client_info: {
          client_fname: this.state.client_fname,
          client_lname: this.state.client_lname,
          client_phone_num: this.state.client_phone_num,
          client_email: this.state.client_email,
        },
        regNum: this.state.regNumEntered,
        CIdate: this.state.CIdate,
        COdate: this.state.COdate,
        items_reservation: this.state.items_reservation,
      },
      withCredentials: true,
    })
      .then((resp) => {
        console.log("getres", resp.data);
        this.gridApi.setSuppressRowDrag(false);
        this.previewgrid.setRowData(resp.data[0]);
        /*req = JSON.stringify({

            })*/
        //let resp_type = ipcRenderer.sendSync('get-paymenttype', req);
        this.clientgrid.setRowData([]);
        //this.setState(prevStat=>({payment_types_array:resp.data}))
        //this.gridApi.setSuppressRowDrag(false)
        this.setState(
          (prevState) => ({
            rowData_preview: resp.data[0],
            addedPayment: resp.data[2],
            notesadded: resp.data[3],
            client_grid_visible: true,
            preview_grid_visible: false,
            reservation_specification: resp.data[0],
            disableModals: false,
            /*payment_types_array:resp.data_type.paymenttypes,
                channel_types:resp.data_type.receivedBy,*/
            channel: resp.data[6],
            canMove: resp.data[4],
            disableModify: resp.data[5],
            Modal_res_cancelled: resp.data[5],
            reservationwasSelected: true,
            discount_array_applied: resp.data[7],
          }),
          () => {
            this.getpricesdb();
            this.set_updated_prices(resp.data[1]);
            //console.log("what we have", this.state.webRegID);
          }
        );
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  }
  set_updated_prices(value) {
    //updates grandtotal
    this.setState((prevState) => ({ grand_total: value }));
  }

  modify_Reservation = (e) => {
    ////console.log("hello",this.testchanges())

    let testprep = this.testchanges();
    //console.log("result", testprep[0].length, testprep[1].length);
    if (testprep[0].length !== 0 || testprep[1].length !== 0) {
      /*let modify_prompt=window.confirm("Do you want to modify the following reservation?")
        if(modify_prompt){*/
      //console.log("result", testprep[0]);
      /*let req = JSON.stringify({
                moveraaray: testprep[0],
                CIdate: this.state.CIdate,
                COdate: this.state.COdate,
                occupancychange: testprep[1],
                regNum: this.state.regNumEntered
            })
            let resp = ipcRenderer.sendSync('reservation-modification', req);*/

      axios({
        method: "post",
        url:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/reservation-modification"
            : "https://apiv1.reserv4me.com/reservation-modification",
        headers: {
          "Access-Control-Allow-Origin":
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://apiv1.reserv4me.com",
          "Content-Type": "application/json",
          Authorization: "internalvar.apikey",
        },
        data: {
          moveraaray: testprep[0],
          CIdate: this.state.CIdate,
          COdate: this.state.COdate,
          occupancychange: testprep[1],
          regNum: this.state.regNumEntered,
        },
        withCredentials: true,
      })
        .then((resp) => {
          //console.log(resp.data);
          if (resp.data.successful === true) {
            this.setState(
              (prevState) => {
                if (true) {
                  return InitialState;
                }
              },
              () => {
                this.sendinfo();
                this.onmainSelectionchanged();
                index_of_changes = [];
                let req = JSON.stringify({});
                //---let resp = ipcRenderer.sendSync('get-paymenttype', req);
                return axios({
                  method: "post",
                  url:
                    process.env.NODE_ENV === "development"
                      ? "http://localhost:3000/get-paymenttype"
                      : "https://apiv1.reserv4me.com/get-paymenttype",
                  headers: {
                    "Access-Control-Allow-Origin":
                      process.env.NODE_ENV === "development"
                        ? "http://localhost:3000"
                        : "https://apiv1.reserv4me.com",
                    "Content-Type": "application/json",
                    Authorization: "internalvar.apikey",
                  },
                  data: {},
                  withCredentials: true,
                })
                  .then((resp) => {
                    this.setState((prevStat) => ({
                      payment_types_array: resp.data.paymenttypes,
                      channel_types: resp.data.receivedBy,
                      channel: resp.data.receivedBy[0].received_type,
                      discount_Array: resp.data.discounts,
                      discount_selected: resp.data.discounts[0].discount_name,
                      DialogShellShown: true,
                      DialogShelltxt:
                        "Modifications have been made successfully ",
                      DialongShellHeader: "Successful!",
                    }));
                  })
                  .catch((error) => {
                    this.props.history.push({
                      pathname: `/`,
                    });
                  });
              }
            );
          }
        })
        .catch((error) => {
          this.props.history.push({
            pathname: `/`,
          });
        });

      //}
    } else {
      this.setState({
        DialogModify: false,
        DialogShellShown: true,
        DialogShelltxt:
          "Please note that there were no modifications found to be done",
        DialongShellHeader: "Modification(s) did not occured",
      });
      //console.log("there is nothing to modify yet");
    }
    if (this.state.client_info_updated === true) {
      /*let req = JSON.stringify({
                //moveraaray: testprep[0],
                //CIdate: this.state.CIdate,
                //COdate: this.state.COdate,
                //occupancychange: testprep[1],
                regNum: this.state.regNumEntered,
                client_id: this.state.client_id,
                client_fname: this.state.client_fname,
                client_lname: this.state.client_lname,
                client_phone_num: this.state.client_phone_num,
                client_email: this.state.client_email,
                client_address: this.state.client_address,
                client_city: this.state.client_city,
                client_state: this.state.client_state,
                client_zip: this.state.client_zip,
                channel: this.state.channel
            })
            let resp = ipcRenderer.sendSync('reservation-modification-client', req);*/

      axios({
        method: "post",
        url:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/reservation-modification-client"
            : "https://apiv1.reserv4me.com/reservation-modification-client",
        headers: {
          "Access-Control-Allow-Origin":
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://apiv1.reserv4me.com",
          "Content-Type": "application/json",
          Authorization: "internalvar.apikey",
        },
        data: {
          regNum: this.state.regNumEntered,
          client_id: this.state.client_id,
          client_fname: this.state.client_fname,
          client_lname: this.state.client_lname,
          client_phone_num: this.state.client_phone_num,
          client_email: this.state.client_email,
          client_address: this.state.client_address,
          client_city: this.state.client_city,
          client_state: this.state.client_state,
          client_zip: this.state.client_zip,
          channel: this.state.channel,
        },
        withCredentials: true,
      })
        .then((resp) => {
          if (resp.data.successful === true) {
            this.setState(
              (prevState) => {
                if (true) {
                  return InitialState;
                }
              },
              () => {
                this.sendinfo();
                this.onmainSelectionchanged();
                index_of_changes = [];
                let req = JSON.stringify({});
                //let resp = ipcRenderer.sendSync('get-paymenttype', req);
                return axios({
                  method: "post",
                  url:
                    process.env.NODE_ENV === "development"
                      ? "http://localhost:3000/get-paymenttype"
                      : "https://apiv1.reserv4me.com/get-paymenttype",
                  headers: {
                    "Access-Control-Allow-Origin":
                      process.env.NODE_ENV === "development"
                        ? "http://localhost:3000"
                        : "https://apiv1.reserv4me.com",
                    "Content-Type": "application/json",
                    Authorization: "internalvar.apikey",
                  },
                  data: {},
                  withCredentials: true,
                })
                  .then((resp) => {
                    this.setState((prevStat) => ({
                      payment_types_array: resp.data.paymenttypes,
                      channel_types: resp.data.receivedBy,
                      channel: resp.data.receivedBy[0].received_type,
                      discount_Array: resp.data.discounts,
                      discount_selected: resp.data.discounts[0].discount_name,
                      DialogShellShown: true,
                      DialogShelltxt:
                        "Modifications have been made successfully ",
                      DialongShellHeader: "Successful!",
                    }));
                  })
                  .catch((error) => {
                    this.props.history.push({
                      pathname: `/`,
                    });
                  });
              }
            );
          }
        })
        .catch((error) => {
          this.props.history.push({
            pathname: `/`,
          });
        });

      ////console.log(resp)
    } else {
      this.setState({
        DialogModify: false,
        DialogShellShown: true,
        DialogShelltxt:
          "Please note that there were no modifications found to be done",
        DialongShellHeader: "Modification(s) did not occured",
      });
      //console.log("there is nothing to modify yet");
    }
  };
  handleDialogModifyAsk = () => {
    this.setState((prevState) => ({ DialogModify: true }));
  };

  handleDialogModifyClose = () => {
    this.setState({ DialogModify: false });
  };

  handleDialogShellClose = () => {
    this.setState({ DialogShellShown: false });
  };

  handleDialogCancelRes = () => {
    this.setState((prevState) => ({ Dialog_Cancel: true }));
  };
  handleDialogClose = () => {
    this.setState({ Dialog_Cancel: false });
  };

  onRowDragMove = (event) => {
    let tempvar = this.state.CIdate;
    var movingNode = event.node;
    var overNode = event.overNode;
    var rowNeedsToMove = movingNode !== overNode;
    /*console.log(
      "is it?",
      movingNode,
      overNode,
      rowNeedsToMove,
      overNode.data.isShift === true,
      overNode.data.isAvail === true,
      movingNode.data.isShift === true,
      movingNode.data.isAvail === true,
      rowNeedsToMove &&
        overNode.data.isShift === true &&
        overNode.data.isAvail === true &&
        movingNode.data.isShift === true &&
        movingNode.data.isAvail === true
    );*/
    if (
      rowNeedsToMove &&
      overNode.data.isShift === true &&
      movingNode.data.isShift === true &&
      parseInt(
        movingNode.data[`regnum_${moment(tempvar).format("MM/DD/YYYY")}`].split(
          "-"
        )[0]
      ) === parseInt(this.state.regNumEntered)
    ) {
      //console.log("firstif");
      if (overNode.data.unittype === movingNode.data.unittype) {
        //console.log("sec iof");
        var movingData = movingNode.data;
        var overData = overNode.data;
        let temparrfrom = [
          [
            movingData.roomnumb,
            movingData.id,
            movingData[
              [`regnum_${moment(this.state.CIdate).format("MM/DD/YYYY")}`]
            ],
          ],
          [
            overData.roomnumb,
            overData.id,
            overData[
              [`regnum_${moment(this.state.CIdate).format("MM/DD/YYYY")}`]
            ],
          ],
        ];
        changed_from_reservation_shift.push(temparrfrom);
        //this.check_reservation_shift()
        var fromIndex = immutableStore.indexOf(movingData);
        var toIndex = immutableStore.indexOf(overData);
        var newStore = immutableStore.slice();
        this.moveInArray(newStore, fromIndex, toIndex);
        immutableStore = newStore;
        this.gridApi.setRowData(newStore);
        //this.gridApi.clearFocusedCell();
        //this.setState(prevState=>({}))
      } else if (
        overNode.data.unittype !== movingNode.data.unittype &&
        overNode.data.isAvail
      ) {
        //console.log("third if");
        var movingData = movingNode.data;
        var overData = overNode.data;
        let temparrfrom = [
          [
            movingData.roomnumb,
            movingData.id,
            movingData[
              [`regnum_${moment(this.state.CIdate).format("MM/DD/YYYY")}`]
            ],
          ],
          [
            overData.roomnumb,
            overData.id,
            overData[
              [`regnum_${moment(this.state.CIdate).format("MM/DD/YYYY")}`]
            ],
          ],
        ];
        changed_from_reservation_shift.push(temparrfrom);
        //this.check_reservation_shift()
        var fromIndex = immutableStore.indexOf(movingData);
        var toIndex = immutableStore.indexOf(overData);
        var newStore = immutableStore.slice();
        this.moveInArray(newStore, fromIndex, toIndex);
        immutableStore = newStore;
        this.gridApi.setRowData(newStore);
      }
    }

    ////console.log("yeap", movingNode, overNode, rowNeedsToMove)
  };

  moveInArray = (arr, fromIndex, toIndex) => {
    ////console.log(fromIndex, toIndex)
    const element = arr[fromIndex];
    const roomfrom = arr[fromIndex].roomnumb;
    const roomtypefrom = arr[fromIndex].unittype;
    const roomtypeIDfrom = arr[fromIndex].unittypeID;
    const roomto = arr[toIndex].roomnumb;
    const element2 = arr[toIndex];
    const roomtypeto = arr[toIndex].unittype;
    const roomtypeIDto = arr[toIndex].unittypeID;

    ////console.log(element,element2)
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);

    ////console.log(arr[fromIndex],arr[toIndex])
    if (fromIndex > toIndex) {
      arr.splice(toIndex + 1, 1);
      arr.splice(fromIndex, 0, element2);
    } else if (toIndex > fromIndex) {
      arr.splice(toIndex - 1, 1);
      arr.splice(fromIndex, 0, element2);
    }
    arr[fromIndex].roomnumb = roomfrom;
    arr[fromIndex].unittype = roomtypefrom;
    arr[fromIndex].unittypeID = roomtypeIDfrom;
    arr[toIndex].roomnumb = roomto;
    arr[toIndex].unittype = roomtypeto;
    arr[toIndex].unittypeID = roomtypeIDto;

    // let temparrto=[[arr[fromIndex].roomnumb,arr[fromIndex].id,arr[fromIndex][`regnum_${moment(this.state.CIdate).format("MM/DD/YYYY")}`]],
    // [arr[toIndex].roomnumb,arr[toIndex].id,arr[toIndex][`regnum_${moment(this.state.CIdate).format("MM/DD/YYYY")}`]]];

    // changed_to_reservation_shift.push(temparrto);
    ////console.log("from",changed_from_reservation_shift)//,this.state.room_inv_detail)
    let itemstoupdate = [];
    this.previewgrid.forEachNode((node, index) => {
      ////console.log(roomfrom,roomto)
      let data = node.data;
      if (data.room_num === roomfrom) {
        ////console.log("yeap")
        data.room_num = roomto;
        data.unittype = this.state.room_inv_detail[toIndex].typeofr;
        data.unittypeID = this.state.room_inv_detail[toIndex].type_id;
        //unittypeID:this.state.room_inv_detail[selectedindex[i].rowIndex].type_id,
        itemstoupdate.push(data);
      }

      //node.setDataValue(0,19)
      //node.data.room_num=19
    });
    //console.log("update", itemstoupdate);
    this.previewgrid.updateRowData({ update: itemstoupdate });
    this.getpricesdb();
    ////console.log(this.previewgrid.getModel().rowsToDisplay)
    /*this.setState(prevState=>({changed_from_reservation_shift:prevState.push(temparrfrom),changed_to_reservation_shift:prevState.push(temparrto)}),()=>{
            //console.log(this.state.changed_from_reservation_shift,this.state.changed_to_reservation_shift)
        })*/
  };

  getpricesdb = (e) => {
    //when the number of adults or children is changed then this even goes to the database to read the data and set the prices

    //console.log(e);

    if (e !== undefined) {
      let rowindex = e.rowIndex;
      if (index_of_changes.indexOf(rowindex) < 0) {
        index_of_changes.push(rowindex);
      }
    }

    /*let req = JSON.stringify(e !== undefined ? {
            rowData_preview: this.state.rowData_preview,
            index_of_changes: index_of_changes,
            discountsArray: this.state.discount_array_applied
        } : {
                rowData_preview: this.state.rowData_preview,
                discountsArray: this.state.discount_array_applied
            })
        let resp = ipcRenderer.sendSync('price-getter', req);*/

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/price-getter"
          : "https://apiv1.reserv4me.com/price-getter",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data:
        e !== undefined
          ? {
              rowData_preview: this.state.rowData_preview,
              index_of_changes: index_of_changes,
              discountsArray: this.state.discount_array_applied,
            }
          : {
              rowData_preview: this.state.rowData_preview,
              discountsArray: this.state.discount_array_applied,
            },
      withCredentials: true,
    })
      .then((resp) => {
        //console.log(resp.data);
        //console.log("here", index_of_changes);
        let temprowData = [];

        this.previewgrid.forEachNode((node, index) => {
          ////console.log(node.data)
          temprowData[index] = {
            room_num: node.data.room_num,
            unittype: node.data.unittype,
            unittypeID: node.data.unittypeID,
            date_CI: this.state.CIdate.format("MM/DD/YYYY"),
            date_CO: this.state.COdate.format("MM/DD/YYYY"), //moment(this.state.COdate.format("MM/DD/YYYY")).add(1,"days").format("MM/DD/YYYY"),
            num_Adg: node.data.num_Adg,
            num_CHg: node.data.num_CHg,
            total_price: `$${resp.data[0][index].total_cost_wo_tax}`,
            tax_room: `$${resp.data[0][index].tax_total}`,
          };
        });
        this.setState(
          (prevState) => ({ rowData_preview: temprowData }),
          () => {
            this.previewgrid.setRowData(temprowData);
            this.set_updated_prices(resp.data[1]);
          }
        );
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  set_updated_prices(value) {
    //updates grandtotal
    this.setState((prevState) => ({ grand_total: value }));
  }

  update_reservation = () => {};
  testchanges = (e) => {
    let update_object = {
      roomnumb_to: "",
      roomnumb_from: "",
      data_moved: "",
    };
    let shitfroomsarr = [];
    immutableStore.forEach((value, index) => {
      if (value.id !== this.state.rowData[index].id) {
        update_object.roomnumb_from = value.roomnumb;
        update_object.roomnumb_to = this.state.rowData[index].roomnumb;
        update_object.roomID_from = value.unittypeID;
        update_object.roomID_to = this.state.rowData[index].unittypeID;
        update_object.data_moved = this.state.rowData[index]; //value;
        shitfroomsarr[shitfroomsarr.length] = update_object;
        /*console.log(
          "from",
          value.roomnumb,
          "to",
          this.state.rowData[index].roomnumb,
          value,
          this.state.rowData[index]
        );*/
      }
      update_object = {
        roomnumb_to: "",
        roomnumb_from: "",
        data_moved: "",
      };
    });
    let occupancychange = [];
    let room_ocupancychange = {
      num_Adg: "",
      num_CHg: "",
      roomnumb: "",
    };
    index_of_changes.map((current, index) => {
      room_ocupancychange.num_Adg = this.state.rowData_preview[current].num_Adg;
      room_ocupancychange.num_CHg = this.state.rowData_preview[current].num_CHg;
      room_ocupancychange.roomnumb =
        this.state.rowData_preview[current].room_num;
      occupancychange[index] = room_ocupancychange;
      room_ocupancychange = {
        num_Adg: "",
        num_CHg: "",
        roomnumb: "",
      };
    });
    //console.log("shift", shitfroomsarr, occupancychange);
    return [shitfroomsarr, occupancychange];
  };

  handleModal_Notes_Show = () => {
    this.setState((prevState) => ({
      showModal_notes: !prevState.showModal_notes,
    }));
  };
  handleModal_res_not_found_Close = () => {
    this.setState((prevState) => ({
      showModal_res_not_found: !prevState.showModal_res_not_found,
    }));
  };

  onClickAddPayment = (e) => {
    this.handleModal_AddPayment_Show();
  };
  handleModal_AddPayment_Show = () => {
    this.setState((prevState) => ({
      showModal_AddPayment: !prevState.showModal_AddPayment,
    }));
  };
  onAddPaymentButton = (e) => {
    if (this.state.AmountValue !== "") {
      let addedpayment = {
        id: this.state.payment_types_array.find(
          (element) => element.paytype === this.state.SelectedPaymenttype
        ).payment_type_id,
        amount: this.state.AmountValue,
        payment_type: this.state.SelectedPaymenttype,
      };
      /*let req = JSON.stringify({
                regNum: this.state.regNumEntered,
                addedpayment: addedpayment
            })
            let resp = ipcRenderer.sendSync('reservation-PaymentAdded', req);*/

      axios({
        method: "post",
        url:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/reservation-PaymentAdded"
            : "https://apiv1.reserv4me.com/reservation-PaymentAdded",
        headers: {
          "Access-Control-Allow-Origin":
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://apiv1.reserv4me.com",
          "Content-Type": "application/json",
          Authorization: "internalvar.apikey",
        },
        data: {
          regNum: this.state.regNumEntered,
          addedpayment: addedpayment,
        },
        withCredentials: true,
      })
        .then((resp) => {
          //console.log(resp.data);

          this.setState((prevState) => ({
            addedPayment: prevState.addedPayment.concat(resp.data[0][0]),
            AmountValue: InitialState.AmountValue,
            SelectedPaymenttype: InitialState.SelectedPaymenttype,
          }));
        })
        .catch((error) => {
          this.props.history.push({
            pathname: `/`,
          });
        });
    } else {
      this.setState((prevState) => ({ ispaymentInvalidated: true }));
    }
    ////console.log(e,"here added")
  };

  onAddPayment_req_Button = (e) => {
    if (this.state.ispayment_req_Invalidated !== true) {
      console.log("test,working");
      axios({
        method: "post",
        url:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/v1/sendLink"
            : "https://apiv1.reserv4me.com/v1/sendLink",
        headers: {
          "Access-Control-Allow-Origin":
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://apiv1.reserv4me.com",
          "Content-Type": "application/json",
          Authorization: "internalvar.apikey",
        },
        data: {
          regNum: this.state.regNumEntered,
          client_info: {
            client_fname: this.state.client_fname,
            client_lname: this.state.client_lname,
            client_email: this.state.client_email,
          },
          CIdate: this.state.CIdate,
          COdate: this.state.COdate,
          reservation_info: this.state.rowData_preview,
          grand_total: this.state.grand_total,
          regNum: this.state.regNumEntered,
          payments: this.state.addedPayment,
          requestFull: this.state.requestFull,
          Amount_req: this.state.Amount_req,
        },
        withCredentials: true,
      })
        .then((resp) => {
          console.log(
            "🚀 ~ file: Main_Modify_res.js:2462 ~ .then ~ resp:",
            resp.data
          );
        })
        .catch((error) => {
          this.props.history.push({
            pathname: `/`,
          });
        });
    } else {
    }
  };

  onAmountChange = (e) => {
    ////console.log(e,"yes")

    let tempval = e.target.value;
    //tempval=parseFloat(tempval.replace("$",""))
    let regex =
      /^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/;
    ////console.log(regex.test(tempval))
    if (regex.test(tempval)) {
      return this.setState((prevState) => ({
        AmountValue: tempval,
        ispaymentInvalidated: false,
      }));
    } else {
      return null;
    }
  };
  onAmount_req_Change = (e) => {
    let tempval = e.target.value;
    //tempval=parseFloat(tempval.replace("$",""))
    let regex =
      /^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/;
    ////console.log(regex.test(tempval))
    if (regex.test(tempval)) {
      return this.setState((prevState) => ({
        Amount_req: tempval,
        ispayment_req_Invalidated: false,
      }));
    } else {
      return null;
    }
  };

  onrequestFullChange = (e) => {
    console.log("test");
    this.setState((prevState) => ({
      requestFull: !prevState.requestFull,
    }));
  };

  onPaymentTypeSelectedChange = (e) => {
    ////console.log(e.target.value)
    let selectedtype = e.target.value;
    this.setState((prevState) => ({ SelectedPaymenttype: selectedtype }));
  };

  oncanMoveChanged = (e) => {
    // //console.log(e,"changed")
    //here we will add the method to change move allow status
    /*let req = JSON.stringify({
            regNum: this.state.regNumEntered,
            canMove: this.state.canMove
        })
        let resp = ipcRenderer.sendSync('reservation-canMoveChanged', req);*/

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/reservation-canMoveChanged"
          : "https://apiv1.reserv4me.com/reservation-canMoveChanged",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        regNum: this.state.regNumEntered,
        canMove: this.state.canMove,
      },
      withCredentials: true,
    })
      .then((resp) => {
        //console.log("onCanMove", resp.data);
        this.setState(
          (prevState) => ({
            canMove: !prevState.canMove,
            rowData: [],
            columnDefs: InitialState.columnDefs,
            rowClassRules: InitialState.rowClassRules,
          }),
          () => {
            this.sendinfo();
            this.getReservationInfo();
          }
        );
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };
  handleModal_Notes_Close = () => {
    this.setState((prevState) => ({
      showModal_notes: !prevState.showModal_notes,
    }));
  };

  onNotesValueChanged = (e) => {
    // //console.log(e.target.value)
    let tempNotes = e.target.value;
    this.setState((prevState) => ({ Notes_res: tempNotes }));
  };

  onAddNoteButton = (e) => {
    ////console.log(e, "notes")
    if (this.state.Notes_res !== "") {
      /*let req = JSON.stringify({
                regNum: this.state.regNumEntered,
                note: this.state.Notes_res
            })
            let resp = ipcRenderer.sendSync('reservation-NoteAdded', req);*/

      axios({
        method: "post",
        url:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/reservation-NoteAdded"
            : "https://apiv1.reserv4me.com/reservation-NoteAdded",
        headers: {
          "Access-Control-Allow-Origin":
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://apiv1.reserv4me.com",
          "Content-Type": "application/json",
          Authorization: "internalvar.apikey",
        },
        data: {
          regNum: this.state.regNumEntered,
          note: this.state.Notes_res,
        },
        withCredentials: true,
      })
        .then((resp) => {
          ////console.log(resp.data[0][0])
          this.setState((prevState) => ({
            notesadded: prevState.notesadded.concat(resp.data[0][0]),
            Notes_res: InitialState.Notes_res,
          }));
        })
        .catch((error) => {
          this.props.history.push({
            pathname: `/`,
          });
        });
    } else {
      this.setState((prevState) => ({ isNotesInvalidated: true }));
    }
  };

  cancelReservation = () => {
    //console.log("cancelling reservation");
    //let cancelprompt = window.confirm("Do you want to cancel the following reservation?")
    /*if (cancelprompt) {*/
    /*let req = JSON.stringify({
            regNum: this.state.regNumEntered,
        })
        let resp = ipcRenderer.sendSync('reservation-Cancel', req);*/

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/reservation-Cancel"
          : "https://apiv1.reserv4me.com/reservation-Cancel",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        regNum: this.state.regNumEntered,
      },
      withCredentials: true,
    })
      .then((resp) => {
        //console.log(resp.data);
        this.setState(
          (prevState) => {
            return InitialState;
          },
          () => {
            this.sendinfo();
            this.onmainSelectionchanged();
            index_of_changes = [];
            /*let req = JSON.stringify({

            })*/
            //let resp = ipcRenderer.sendSync('get-paymenttype', req);
            return axios({
              method: "post",
              url:
                process.env.NODE_ENV === "development"
                  ? "http://localhost:3000/get-paymenttype"
                  : "https://apiv1.reserv4me.com/get-paymenttype",
              headers: {
                "Access-Control-Allow-Origin":
                  process.env.NODE_ENV === "development"
                    ? "http://localhost:3000"
                    : "https://apiv1.reserv4me.com",
                "Content-Type": "application/json",
                Authorization: "internalvar.apikey",
              },
              data: {},
              withCredentials: true,
            })
              .then((resp) => {
                this.setState((prevStat) => ({
                  payment_types_array: resp.data.paymenttypes,
                  channel_types: resp.data.receivedBy,
                  channel: resp.data.receivedBy[0].received_type,
                  discount_Array: resp.data.discounts,
                  discount_selected: resp.data.discounts[0].discount_name,
                }));
              })
              .catch((error) => {
                this.props.history.push({
                  pathname: `/`,
                });
              });
          }
        );
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });

    /*}
        else {

        }*/
  };

  handlereservation_cancelled = () => {
    //console.log("yeap");
    this.setState((prevState) => ({
      Modal_res_cancelled: !prevState.Modal_res_cancelled,
    }));
  };

  handlereservation_Pay_req = () => {
    this.setState((prevState) => ({
      showModal_pay_req: !prevState.showModal_pay_req,
    }));
  };

  resendEmailConfirmation = () => {
    //console.log("confirming");
    /*let req = JSON.stringify({
            client_info: {
                client_id: this.state.client_id,
                client_fname: this.state.client_fname,
                client_lname: this.state.client_lname,
                client_phone_num: this.state.client_phone_num,
                client_email: this.state.client_email,
                client_address: this.state.client_address,
                client_city: this.state.client_city,
                client_state: this.state.client_state,
                client_zip: this.state.client_zip,
            },
            CIdate: this.state.CIdate,
            COdate: this.state.COdate,
            reservation_info: this.state.rowData_preview,
            grand_total: this.state.grand_total,
            regNum: this.state.regNumEntered
        })

        let resp = ipcRenderer.sendSync('confirmation-email-res', req);*/

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/confirmation-email-res"
          : "https://apiv1.reserv4me.com/confirmation-email-res",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        client_info: {
          client_id: this.state.client_id,
          client_fname: this.state.client_fname,
          client_lname: this.state.client_lname,
          client_phone_num: this.state.client_phone_num,
          client_email: this.state.client_email,
          client_address: this.state.client_address,
          client_city: this.state.client_city,
          client_state: this.state.client_state,
          client_zip: this.state.client_zip,
        },
        CIdate: this.state.CIdate,
        COdate: this.state.COdate,
        reservation_info: this.state.rowData_preview,
        grand_total: this.state.grand_total,
        regNum: this.state.regNumEntered,
      },
      withCredentials: true,
    })
      .then((resp) => {
        //console.log(resp.data);
        if (resp.data.status === "ok") {
          this.setState((prevState) => ({
            DialogShellShown: true,
            DialogShelltxt: "Confirmation has been sent successfully",
            DialongShellHeader: "Successful Email Confirmation",
          }));
        }
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  onPrintRegistration = () => {
    ////console.log("connected")

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/hotel-test-print"
          : "https://apiv1.reserv4me.com/hotel-test-print",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        //Authorization: "internalvar.apikey"
      },
      data: {
        client_info: {
          client_id: this.state.client_id,
          client_fname: this.state.client_fname,
          client_lname: this.state.client_lname,
          client_phone_num: this.state.client_phone_num,
          client_email: this.state.client_email,
          client_address: this.state.client_address,
          client_city: this.state.client_city,
          client_state: this.state.client_state,
          client_zip: this.state.client_zip,
        },
        CIdate: this.state.CIdate,
        COdate: this.state.COdate,
        reservation_info: this.state.rowData_preview,
        grand_total: this.state.grand_total,
        regNum: this.state.regNumEntered,
        payments: this.state.addedPayment,
        channel: this.state.channel,
      },
      withCredentials: true,
    })
      .then((response) => {
        //console.log(response.data);
        printJS({
          printable: response.data.split(",")[1],
          type: "pdf",
          base64: true,
        });
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });

    /*.then((result) => {
      console.log(result.data);
      this.setState((prevState) => {
        return { printingview: result.data };
      });
    });*/
  };

  testbtn4temp = () => {
    // //console.log(this.state.addedPayment)
    this.testchanges();
  };

  handle_AddDiscountModal = () => {
    //console.log("modalhere");
    /*console.log(
      "stateof discounts",
      "apl",
      this.state.discount_array_applied,
      "todel",
      this.state.discounts_to_be_deleted,
      "newapp",
      this.state.new_discount_array_applied
    );*/

    this.setState((prevState) => ({
      showDiscount_Modal: !prevState.showDiscount_Modal,
    }));
  };
  onDiscountSelectedChange = (e) => {
    let tempval = e.target.value;
    ////console.log(e.value)
    this.setState((prevState) => ({ discount_selected: tempval }));
  };
  onAddDiscountButton = (e) => {
    let discountTemp = {
      id: this.state.discount_Array.find(
        (element) => element.discount_name === this.state.discount_selected
      ).discount_id,
      discount_name: this.state.discount_selected,
      discount_rate: this.state.discount_Array.find(
        (element) => element.discount_name === this.state.discount_selected
      ).discount_rate,
    };

    this.setState(
      (prevState) => ({
        new_discount_array_applied:
          prevState.new_discount_array_applied.concat(discountTemp),
        discount_array_applied:
          prevState.discount_array_applied.concat(discountTemp),
        discount_selected: this.state.discount_Array[0].discount_name,
      }),
      () => {
        let req = JSON.stringify({
          new_discount_array_applied: this.state.new_discount_array_applied,
          regNum: this.state.regNumEntered,
        });

        //---let resp = ipcRenderer.sendSync('add-discounts', req)
        axios({
          method: "post",
          url:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/add-discounts"
              : "https://apiv1.reserv4me.com/add-discounts",
          headers: {
            "Access-Control-Allow-Origin":
              process.env.NODE_ENV === "development"
                ? "http://localhost:3000"
                : "https://apiv1.reserv4me.com",
            "Content-Type": "application/json",
            Authorization: "internalvar.apikey",
          },
          data: {
            new_discount_array_applied: this.state.new_discount_array_applied,
            regNum: this.state.regNumEntered,
          },
          withCredentials: true,
        })
          .then((resp) => {
            //console.log("resp", resp.data);

            this.setState(
              (prevState) => ({
                discounts_to_be_deleted: [],
                discount_array_applied: resp.data,
                new_discount_array_applied: [],
              }),
              () => {
                this.getpricesdb();
              }
            );
          })
          .catch((error) => {
            this.props.history.push({
              pathname: `/`,
            });
          });
      }
    );
  };

  onDeleteDiscount = (e) => {
    //console.log(this.state.new_discount_array_applied);
    let tempvar = e.currentTarget.id;
    tempvar = tempvar.split("-");
    //console.log(tempvar);

    /*if(tempvar[1]==='N'){

            this.setState(prevState=>{

                const array_new=prevState.new_discount_array_applied.filter((item,j)=>j!==(parseInt(tempvar[0])-prevState.discount_array_applied.length))
                const array=prevState.discount_array_applied.filter((item,j)=>j!==parseInt(tempvar[0]));
                return(
                   {
                       discount_array_applied:array,
                       new_discount_array_applied:array_new
                    }
                )
                
    
            },()=>{

                this.getpricesdb()
            })

        }

        else{
*/

    this.setState(
      (prevState) => {
        const array = prevState.discount_array_applied.filter(
          (item, j) => j !== parseInt(tempvar[0])
        );

        return {
          discount_array_applied: array,
          discounts_to_be_deleted: prevState.discounts_to_be_deleted.concat(
            tempvar[1]
          ),
        };
      },
      () => {
        /* let req = JSON.stringify({
                 discounts_to_be_deleted: this.state.discounts_to_be_deleted,
                 regNum: this.state.regNumEntered,
             })
 
             let resp = ipcRenderer.sendSync('delete-discounts', req)*/

        axios({
          method: "post",
          url:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/delete-discounts"
              : "https://apiv1.reserv4me.com/delete-discounts",
          headers: {
            "Access-Control-Allow-Origin":
              process.env.NODE_ENV === "development"
                ? "http://localhost:3000"
                : "https://apiv1.reserv4me.com",
            "Content-Type": "application/json",
            Authorization: "internalvar.apikey",
          },
          data: {
            discounts_to_be_deleted: this.state.discounts_to_be_deleted,
            regNum: this.state.regNumEntered,
          },
          withCredentials: true,
        })
          .then((resp) => {
            //console.log("resp delete", resp.data);

            this.setState(
              (prevState) => ({
                discounts_to_be_deleted: [],
                discount_array_applied: resp.data,
                new_discount_array_applied: [],
              }),
              () => {
                this.getpricesdb();
              }
            );
          })
          .catch((error) => {
            this.props.history.push({
              pathname: `/`,
            });
          });
      }
    );
    //}
  };

  exportDataAsCSV = () => {
    /*var params={
            suppressQuotes: this.getValuesCVS("#suppressQuotes"),
            columnSeparator: this.getValuesCVS("#columnSeparator"),
            customHeader:this.getValuesCVS("#customHeader"),
            customFooter: this.getValuesCVS("#customFooter")
          }
*/
    this.gridApi.exportDataAsCsv({
      allColumns: true,
      fileName: "test.csv",
    });
  };

  clearForm = () => {
    //console.log("I'm here");

    this.setState(
      (prevState) => {
        if (true) {
          return InitialState;
        }
      },
      () => {
        this.sendinfo();
        this.onmainSelectionchanged();
        index_of_changes = [];
        let req = JSON.stringify({});
        //---let resp = ipcRenderer.sendSync('get-paymenttype', req);
        axios({
          method: "post",
          url:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/get-paymenttype"
              : "https://apiv1.reserv4me.com/get-paymenttype",
          headers: {
            "Access-Control-Allow-Origin":
              process.env.NODE_ENV === "development"
                ? "http://localhost:3000"
                : "https://apiv1.reserv4me.com",
            "Content-Type": "application/json",
            Authorization: "internalvar.apikey",
          },
          data: {},
          withCredentials: true,
        })
          .then((resp) => {
            this.setState((prevStat) => ({
              payment_types_array: resp.data.paymenttypes,
              channel_types: resp.data.receivedBy,
              channel: resp.data.receivedBy[0].received_type,
              discount_Array: resp.data.discounts,
              discount_selected: resp.data.discounts[0].discount_name,
            }));
          })
          .catch((error) => {
            this.props.history.push({
              pathname: `/`,
            });
          });
      }
    );
  };

  collect_Scheduled_Payment = () => {
    /*let req = JSON.stringify({
            webRegID: this.state.webRegID,
            scheduled_amount: this.state.scheduled_amount,
            regNum: this.state.regNumEntered
        })
        let resp = ipcRenderer.sendSync('collect-payment', req);*/

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/collect-payment"
          : "https://apiv1.reserv4me.com/collect-payment",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        webRegID: this.state.webRegID,
        scheduled_amount: this.state.scheduled_amount,
        regNum: this.state.regNumEntered,
      },
      withCredentials: true,
    })
      .then((resp) => {
        //console.log(resp);
        this.setState((prevState) => ({
          scheduled_payment_failed: resp.data.success === true ? false : true,
          schedule_payment_occured: true,
          scheduled_payment_was_collected: true,
        }));
        this.getReservationInfo();
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  sendPaymentLink = () => {};

  setSidebarOpen = () => {
    this.setState((prevState) => ({ sidebarOpen: !prevState.sidebarOpen }));
  };
  onDateSelectionChange = (CIdate, COdate) => {
    this.setState(
      (prevState) => ({
        CIdate: CIdate,
        COdate: COdate,
        rowData: [],
        columnDefs: InitialState.columnDefs,
        rowClassRules: InitialState.rowClassRules,
      }),
      () => {
        this.sendinfo();
        this.onmainSelectionchanged();
      }
    );
  };

  onAnalyticsMode = () => {
    this.setState((prevState) => ({
      analytics_mode_disabled: !prevState.analytics_mode_disabled,
    }));
  };

  setAnalitycsDate = (date) => {
    this.setState(
      (prevState) => ({
        date_analytics: date,
      }),
      () => {
        this.sendinfo();
      }
    );
  };

  render() {
    return (
      <div className="flex flex-col min-h-screen overflow-hidden">
        <SideBar
          sidebarOpen={this.state.sidebarOpen}
          setSidebarOpen={this.setSidebarOpen}
        />
        <div className="absolute flex flex-col flex-1 overflow-y-auto overflow-x-hidden w-full">
          <Header
            sidebarOpen={this.state.sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            className="z-90"
            userName={window.sessionStorage.getItem("uname")}
          />
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
              <div className="grid grid-flow-row   lg:grid-cols-12  gap-4 ">
                <div
                  className="col-span-12 lg:col-span-4  space-y-4 border
                 border-gray-200 shadow-lg p-2 rounded-sm order-2 lg:order-1">
                  <div className="">
                    <Client_Form
                      client_info={{
                        fname: this.state.client_fname,
                        lname: this.state.client_lname,
                        phone_num: this.state.client_phone_num,
                        email: this.state.client_email,
                        address: this.state.client_address,
                        city: this.state.client_city,
                        state: this.state.client_state,
                        zipcode: this.state.client_zip,
                        channel: this.state.channel,
                      }}
                      onChange={this.clientinfor_change}
                      channel_types={this.state.channel_types}
                      fnameval={this.state.fnameval}
                      lnameval={this.state.lnameval}
                      phone_numval={this.state.phone_numval}
                      email_addrval={this.state.email_addrval}
                      onSubmit={this.onClientFormSearch}
                    />
                  </div>
                  <div>
                    <div
                      className="h-60"
                      hidden={this.state.preview_grid_visible}>
                      <Grid_preview
                        Columns={this.state.columnDefs_preview}
                        //Rows={this.state.rowData_preview}
                        rowClassRules={this.state.rowClassRules_preview}
                        onCellValueChanged={this.getpricesdb}
                        onGridReady={this.onPreviewReady}
                      />
                    </div>
                    <div
                      className="h-60"
                      hidden={this.state.client_grid_visible}>
                      <span
                        hidden={this.state.button_clientgrid_nofound_visible}>
                        <button
                          className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12  "
                          onClick={this.handleClientGridclose}>
                          <svg
                            className="shrink-0 h-6 w-6"
                            viewBox="0 0 500 500">
                            <path
                              fill="currentColor"
                              d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-83.6 290.5c4.8 4.8 4.8 12.6 0 17.4l-40.5 40.5c-4.8 4.8-12.6 4.8-17.4 0L256 313.3l-66.5 67.1c-4.8 4.8-12.6 4.8-17.4 0l-40.5-40.5c-4.8-4.8-4.8-12.6 0-17.4l67.1-66.5-67.1-66.5c-4.8-4.8-4.8-12.6 0-17.4l40.5-40.5c4.8-4.8 12.6-4.8 17.4 0l66.5 67.1 66.5-67.1c4.8-4.8 12.6-4.8 17.4 0l40.5 40.5c4.8 4.8 4.8 12.6 0 17.4L313.3 256l67.1 66.5z"></path>
                          </svg>
                        </button>
                      </span>
                      <Client_preview_grid
                        Columns={this.state.columDefs_client}
                        Rows={this.state.rowData_client}
                        rowClassRules={this.state.rowClassRules_preview}
                        //onCellValueChanged={this.getpricesdb}
                        onGridReady={this.onClientGridREady}
                        onRowDoubleClicked={this.onClientrowDoubleClick}
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-12 grid-flow-row gap-4 ">
                    <div className=" col-span-12 lg:col-span-4 order-2 lg:order-1">
                      <Button_array
                        onClick={this.onClickAddPayment}
                        disableModals={this.state.disableModals}
                        onDiscountClick={this.handle_AddDiscountModal}
                      />
                    </div>
                    <div className="col-span-12 lg:col-span-8 order-1 lg:order-2">
                      <Price_box grand_total={this.state.grand_total} />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-8 order-1 lg:order-2">
                  <div className="space-y-2 border border-gray-200 shadow-lg p-2 rounded-sm ">
                    <div className="flex flex-wrap gap-2 justify-center md:justify-start">
                      <div className="">
                        <RegNumBox
                          formSubmit={this.formSubmit}
                          regNumEntered={this.state.regNumEntered}
                          onRegNumChange={this.onRegNumChange}
                        />
                      </div>
                      <div className="flex items-center space-x-2 ">
                        <span>Dates:</span>
                        <div className="relative  ">
                          <Flatpickr
                            value={[
                              this.state.CIdate.toDate(),
                              this.state.COdate.toDate(),
                            ]}
                            className="form-input  w-64 pl-9 text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
                            options={{
                              mode: "range",
                              static: true,
                              monthSelectorType: "static",
                              dateFormat: "M j, Y",
                              defaultDate: [
                                this.state.CIdate.toDate(),
                                this.state.COdate.toDate(),
                              ],
                              prevArrow:
                                '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
                              nextArrow:
                                '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
                            }}
                            onReady={(selectedDates, dateStr, instance) => {
                              instance.element.value = dateStr.replace(
                                "to",
                                "-"
                              );
                              instance.calendarContainer.classList.add(
                                `flatpickr-right`
                              );
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                              if (selectedDates.length === 1) {
                                //this.date_CI_change(moment(selectedDates[0]));
                              } else {
                                //this.date_CI_change(moment(selectedDates[0]));
                                //this.date_CO_change(moment(selectedDates[1]));
                                this.onDateSelectionChange(
                                  moment(selectedDates[0]),
                                  moment(selectedDates[1])
                                );
                              }
                            }}
                            onClose={(selectedDates, dateStr, instance) => {
                              if (selectedDates.length == 1) {
                                // console.log(
                                //   "🚀 ~ file: Main_Modify_res.js ~ line 2449 ~ Modify_res ~ render ~ selectedDates",
                                //   selectedDates[0]
                                // );

                                // this.date_CO_change(
                                //   moment(selectedDates[0]).add(1, "d")
                                // );
                                this.onDateSelectionChange(
                                  moment(selectedDates[0]),
                                  moment(selectedDates[0]).add(1, "d")
                                );

                                instance.setDate([
                                  selectedDates[0],
                                  moment(selectedDates[0]).add(1, "d").toDate(),
                                  true,
                                ]);
                                instance.element.value = dateStr.replace(
                                  "to",
                                  "-"
                                );
                              }
                            }}
                          />
                          <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
                            <svg
                              className="w-4 h-4 fill-current text-gray-500 ml-3"
                              viewBox="0 0 16 16">
                              <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                            </svg>
                          </div>
                        </div>
                        {/* <Datepicker
                          CIdate={this.state.CIdate}
                          COdate={this.state.COdate}
                          onChangeCI={this.date_CI_change}
                          onChangeCO={this.date_CO_change}
                          options={this.state.options}
                        ></Datepicker> */}
                      </div>
                      <div className="flex md:flex-nowrap flex-wrap items-center space-x-2 ">
                        <div className="flex items-center justify-start ">
                          <label
                            for="toggleDate"
                            className="flex items-center cursor-pointer">
                            {/* <!-- toggle --> */}
                            <div className="relative">
                              {/* <!-- input --> */}
                              <input
                                type="checkbox"
                                id="toggleDate"
                                className="sr-only"
                                onChange={this.onAnalyticsMode}
                              />
                              {/* <!-- line --> */}
                              <div
                                className={`block ${
                                  this.state.analytics_mode_disabled === true
                                    ? "bg-gray-400  "
                                    : " bg-blue-600 "
                                }  w-14 h-8 rounded-full`}></div>
                              {/* <!-- dot --> */}
                              <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                            </div>
                            {/* <!-- label --> */}
                            <div className="ml-3 text-gray-700 font-medium ">
                              Analitics Mode:
                            </div>
                          </label>
                        </div>

                        <div
                          className=" flex items-center space-x-2"
                          hidden={
                            this.state.analytics_mode_disabled === true
                              ? true
                              : false
                          }>
                          <span>Dates:</span>
                          <div className="relative  ">
                            <Flatpickr
                              value={this.state.date_analytics.toDate()}
                              className="form-input  w-64 pl-9 text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
                              options={{
                                mode: "single",
                                static: true,
                                monthSelectorType: "static",
                                dateFormat: "M j, Y",
                                defaultDate: [
                                  this.state.date_analytics.toDate(),
                                ],
                                prevArrow:
                                  '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
                                nextArrow:
                                  '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
                              }}
                              onReady={(selectedDates, dateStr, instance) => {
                                console.log(instance.isMobile, instance);
                                instance.element.value = dateStr.replace(
                                  "to",
                                  "-"
                                );

                                if (instance.isMobile) {
                                  instance.mobileInput.classList.add(
                                    `flatpickr-right`
                                  );
                                } else {
                                  instance.calendarContainer.classList.add(
                                    `flatpickr-right`
                                  );
                                }
                              }}
                              onChange={(selectedDates, dateStr, instance) => {
                                if (selectedDates.length === 1) {
                                  //this.date_CI_change(moment(selectedDates[0]));
                                } else {
                                  //this.date_CI_change(moment(selectedDates[0]));
                                  //this.date_CO_change(moment(selectedDates[1]));
                                  this.onDateSelectionChange(
                                    moment(selectedDates[0]),
                                    moment(selectedDates[1])
                                  );
                                }
                              }}
                              onClose={(selectedDates, dateStr, instance) => {
                                if (selectedDates.length == 1) {
                                  // console.log(
                                  //   "🚀 ~ file: Main_Modify_res.js ~ line 2449 ~ Modify_res ~ render ~ selectedDates",
                                  //   selectedDates[0]
                                  // );

                                  // this.date_CO_change(
                                  //   moment(selectedDates[0]).add(1, "d")
                                  // );
                                  this.setAnalitycsDate(
                                    moment(selectedDates[0])
                                  );

                                  instance.setDate(selectedDates[0]);
                                  instance.element.value = dateStr.replace(
                                    "to",
                                    "-"
                                  );
                                }
                              }}
                            />
                            <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
                              <svg
                                className="w-4 h-4 fill-current text-gray-500 ml-3"
                                viewBox="0 0 16 16">
                                <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <Datepicker
                          CIdate={this.state.CIdate}
                          COdate={this.state.COdate}
                          onChangeCI={this.date_CI_change}
                          onChangeCO={this.date_CO_change}
                          options={this.state.options}
                        ></Datepicker> */}
                        </div>
                      </div>
                    </div>
                    <div className="h-96 lg:h-132">
                      <Grid_main
                        Columns={this.state.columnDefs}
                        //Rows={this.state.rowData}
                        rowClassRules={this.state.rowClassRules}
                        onSelectionChanged={this.onmainSelectionchanged}
                        rowSelection={this.state.rowSelection}
                        onGridReady={this.onGridReady}
                        isRowSelectable={this.state.isRowSelectable}
                        onRowDragMove={this.onRowDragMove}
                      />
                    </div>
                  </div>
                </div>
                <div className=" col-span-12 lg:pl-4 lg:col-start-5 lg:col-end-13 order-3 ">
                  <Button_bar
                    modify_Reservation={this.handleDialogModifyAsk}
                    handleshownotes={this.handleModal_Notes_Show}
                    testchanges={this.exportDataAsCSV}
                    disableModals={this.state.disableModals}
                    cancelReservation={this.handleDialogCancelRes}
                    disableModify={this.state.disableModify}
                    resendEmailConfirmation={this.resendEmailConfirmation}
                    onPrintRegistration={this.onPrintRegistration}
                    resetForm={this.clearForm}
                    content={this.componentRef}
                    reservation_is_cancelled={
                      this.state.reservation_is_cancelled
                    }
                    handlePay_Req={this.handlereservation_Pay_req}
                  />
                </div>
              </div>
            </div>
          </main>
          <Modal_res_not_found
            handleClose={this.handleModal_res_not_found_Close}
            show={this.state.showModal_res_not_found}
          />
          <Modal_notes
            canMovechanged={this.oncanMoveChanged}
            canMove={this.state.canMove}
            handleClose={this.handleModal_Notes_Close}
            show={this.state.showModal_notes}
            value={this.state.Notes_res}
            onValueChanged={this.onNotesValueChanged}
            onAddNoteButton={this.onAddNoteButton}
            notesadded={this.state.notesadded}
            isNotesInvalidated={this.state.isNotesInvalidated}
            pagedisplay="modify"
          />
          <Modal_AddPayment
            show={this.state.showModal_AddPayment}
            handleClose={this.handleModal_AddPayment_Show}
            onAddPaymentButton={this.onAddPaymentButton}
            AmountValue={this.state.AmountValue}
            onAmountChange={this.onAmountChange}
            addedPayment={this.state.addedPayment}
            SelectedPaymenttype={this.state.SelectedPaymenttype}
            onPaymentTypeSelectedChange={this.onPaymentTypeSelectedChange}
            ispaymentInvalidated={this.state.ispaymentInvalidated}
            payment_types_array={this.state.payment_types_array}
            pagedisplay="modify"
            channel={this.state.channel}
            due_date={this.state.schedule_payment_time}
            checkin={this.state.CIdate}
            scheduled_amount={this.state.scheduled_amount}
            collect_payment={this.collect_Scheduled_Payment}
            scheduled_payment_failed={this.state.scheduled_payment_failed}
            schedule_payment_occured={this.state.schedule_payment_occured}
            scheduled_payment_was_collected={
              this.state.scheduled_payment_was_collected
            }
          />
          <Modal_Add_Discount
            show={this.state.showDiscount_Modal}
            handleClose={this.handle_AddDiscountModal}
            discounttypeArray={this.state.discount_Array}
            discountAdded={this.state.discount_array_applied}
            discountValue={this.state.discount_selected}
            pagedisplay="modify"
            onDiscountChange={this.onDiscountSelectedChange}
            onAddDiscountButton={this.onAddDiscountButton}
            onDeleteDiscount={this.onDeleteDiscount}
          />

          <Modal_cancel_res
            show={this.state.Dialog_Cancel}
            handleClose={this.handleDialogClose}
            agree={this.cancelReservation}
          />

          <Modal_res_was_cancelled
            handleClose={this.handlereservation_cancelled}
            show={this.state.Modal_res_cancelled}></Modal_res_was_cancelled>

          <Modal_Handle_Pay_req
            handleClose={this.handlereservation_Pay_req}
            show={this.state.showModal_pay_req}
            Amount_req={this.state.Amount_req}
            onAmount_req_Change={this.onAmount_req_Change}
            ispayment_req_Invalidated={this.state.ispayment_req_Invalidated}
            onAddPayment_req_Button={this.onAddPayment_req_Button}
            requestFull={this.state.requestFull}
            onrequestFullChange={this.onrequestFullChange}
          />

          <DialogModify
            open={this.state.DialogModify}
            disagree={this.handleDialogModifyClose}
            agree={this.modify_Reservation}
          />
          <DialogShell
            open={this.state.DialogShellShown}
            agree={this.handleDialogShellClose}
            DialongShellHeader={this.state.DialongShellHeader}
            DialogShelltxt={this.state.DialogShelltxt}
          />

          <Registration_Card
            html_obj={this.state.printingview}
            ref={(el) => (this.componentRef = el)}></Registration_Card>
        </div>
      </div>
    );
  }
}
export default Modify_res;
