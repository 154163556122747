import React from "react";
import Payment_Grid from "./Payments_Added";
import moment from "moment";
//import AddPayment_Grid from './Add_Patment_grid'

const Modal_Add_Payment = ({
  handleClose,
  show,
  value,
  onValueChanged,
  canMove,
  canMovechanged,
  onAddPaymentButton,
  AmountValue,
  onAmountChange,
  addedPayment,
  SelectedPaymenttype,
  onPaymentTypeSelectedChange,
  ispaymentInvalidated,
  payment_types_array,
  pagedisplay,
  channel,
  due_date,
  checkin,
  scheduled_amount,
  collect_payment,
  scheduled_payment_failed,
  schedule_payment_occured,
  scheduled_payment_was_collected,
}) => {
  ////console.log(scheduled_payment_failed)
  return (
    <>
      <div
        className={`modal fade fixed top-0 left-0  w-full h-full outline-none overflow-x-hidden overflow-y-auto  bg-black/75 ${
          show === true ? "show block" : ""
        }`}
        id="exampleModal"
      >
        <div className="modal-dialog relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalLabel"
              >
                Add Payment:
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body relative p-4">
              {channel === "Reserv4me-Setup" ? (
                <div className="flex space-x-8 items-center justify-between">
                  <span>
                    Schedule Payment of {scheduled_amount} due{" "}
                    {moment(checkin)
                      .subtract(due_date, "days")
                      .format("MM/DD/YYYY")}
                  </span>
                  <button
                    onClick={collect_payment}
                    disabled={scheduled_payment_was_collected}
                    className="px-6
                      py-2.5
                      bg-blue-600
                      text-white
                      font-medium
                      text-xs
                      leading-tight
                      uppercase
                      rounded
                      shadow-md
                      hover:bg-blue-700 hover:shadow-lg
                      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                      active:bg-blue-800 active:shadow-lg
                      transition
                      duration-150
                      ease-in-out
                      ml-1 "
                  >
                    Collect
                  </button>
                </div>
              ) : null}
              {schedule_payment_occured === true ? (
                <div>
                  <span>
                    {`Scheduled Payment has ${
                      scheduled_payment_failed === true
                        ? "failed"
                        : "been proceessed successfully"
                    }`}
                  </span>
                </div>
              ) : null}
              <div className="flex justify-between items-start  space-x-2">
                <div className="flex flex-wrap w-2/4 justify-between items-center">
                  <label for="amount">Amount</label>

                  <input
                    className="w-2/4 form-input text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
                    id="amount"
                    type="text"
                    placeholder="$0.00"
                    value={AmountValue}
                    onChange={onAmountChange}
                  />
                  <div className="flex justify-end w-full pt-2 pr-4">
                    <p
                      className={`text-red-500 ${
                        ispaymentInvalidated === true ? "show" : "hidden"
                      }`}
                    >
                      Field needed
                    </p>
                  </div>
                </div>

                <select
                  value={SelectedPaymenttype}
                  onChange={onPaymentTypeSelectedChange}
                  className="w-1/4 form-input text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
                >
                  {payment_types_array.map((current, index) => {
                    return (
                      <option
                        key={current.payment_type_id}
                        value={current.paytype}
                      >
                        {current.paytype}
                      </option>
                    );
                  })}
                </select>
                <button
                  onClick={onAddPaymentButton}
                  className="px-6
                      py-2.5
                      bg-blue-600
                      text-white
                      font-medium
                      text-xs
                      leading-tight
                      uppercase
                      rounded
                      shadow-md
                      hover:bg-blue-700 hover:shadow-lg
                      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                      active:bg-blue-800 active:shadow-lg
                      transition
                      duration-150
                      ease-in-out
                      ml-1 "
                >
                  Add Payment
                </button>
              </div>
              <div className="flex">
                <div className="w-1/3">Type</div>
                <div className="w-1/3">Amount</div>
                <div className="w-1/3">Date</div>
              </div>
              <Payment_Grid
                payment_array={addedPayment}
                pagedisplay={pagedisplay}
              ></Payment_Grid>
            </div>
            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button
                type="button"
                className="px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out
      ml-1"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal_Add_Payment;
