import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import ProtectedRoute from "./components/Protected_Route/Protected_Route";
import App_Signin from "./components/App_Signin/App_Signin";
import NotFound from "./NotFound";
import Main_menu from "./components/Main_menu";
import Main_Make_res from "./components/Make_reservation/Main_Make_res";
import Main_Modify_res from "./components/Modify_reservation/Main_Modify_res";
import Main_Daily_rate from "./components/Daily_rate/Main_Daily_rate";
import Main_Sign_up from "./components/Sign_up_page/Main_Sign_up";
import SettingsPage from "./components/SettingsPage/Main_settings";
import Daily_Demand_Chart from "./components/Daily_Demand_Chart/Daily_Demand_Chart";
import Info_Review from "./components/Info_Review/Info_Review";
import Reservation_Explorer from "./components/Explore_Reservations/Main_Explore_Reservation";

class App extends React.Component {
  constructor() {
    super();
  }
  componentDidMount() {
    ////console.log(this.props);
  }
  render() {
    return (
      <Fragment>
        <Switch>
          <Route
            exact
            path="/"
            component={() => {
              return <App_Signin />;
            }}
          />
          <ProtectedRoute
            exact
            path="/mainmenu"
            component={() => {
              return <Main_menu />;
            }}
          />
          <ProtectedRoute
            exact
            path="/makeres"
            component={() => {
              return <Main_Make_res />;
            }}
          />
          <ProtectedRoute
            exact
            path="/modifyres"
            component={() => {
              return <Main_Modify_res />;
            }}
          />
          <ProtectedRoute
            exact
            path="/dailyrate"
            component={() => {
              return <Main_Daily_rate />;
            }}
          />
          <Route
            exact
            path="/signup"
            component={() => {
              return <Main_Sign_up />;
            }}
          />
          <ProtectedRoute
            exact
            path="/settings"
            component={() => {
              return <SettingsPage />;
            }}
          />
          <ProtectedRoute
            exact
            path="/Daily_Demand"
            component={() => {
              return <Daily_Demand_Chart />;
            }}
          />
          <ProtectedRoute
            exact
            path="/waiting_review"
            component={() => {
              return <Info_Review />;
            }}
          />
          <ProtectedRoute
            exact
            path="/reservation_search"
            component={() => {
              return <Reservation_Explorer />;
            }}
          />

          <Route component={NotFound} />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(App);
