import React from "react";
import { Bar, Pie } from "react-chartjs-2";
import { withRouter } from "react-router-dom";
import axios from "axios";

import moment from "moment";
import SideBar from "./Header Menu/SideBar";
import Header from "./Header Menu/Header";
import Flatpickr from "react-flatpickr";

const InitialState = {};

class Main_Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed_daily_payment: {},
      displayed_data: {},
      displayed_daily_check_avail: {},
      startdate: moment().subtract(7, "days"),
      enddate: moment(),
      startdate_individuals: moment().subtract(7, "days"),
      enddate_individuals: moment(),
      startdate_payments: moment().subtract(7, "days"),
      enddate_payments: moment(),
      addingdate: false,
      shouldClose: false,
      sidebarOpen: false,
    };
  }

  componentDidMount() {
    this.read_daily_demand();
    this.read_payment_daily();
    this.read_daily_check_avail();
  }

  read_daily_demand = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/daily-demand"
          : "https://apiv1.reserv4me.com/daily-demand",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        fromdate: moment(this.state.startdate),
        todate: moment(this.state.enddate),
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log("itis", response);
        // if (response.status === 401) {
        //   this.props.history.push({
        //     pathname: `/`,
        //   });
        // } else {
        this.setState((prevState) => {
          return {
            displayed_data: {
              labels: response.data.labels,
              datasets: response.data.datasets,
            },
          };
        });
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });
  };
  read_payment_daily = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/v1/payment_daily"
          : "https://apiv1.reserv4me.com/v1/payment_daily",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        fromdate: moment(this.state.startdate_payments).format("MM/DD/YYYY"),
        todate: moment(this.state.enddate_payments).format("MM/DD/YYYY"),
      },
      withCredentials: true,
    })
      .then((response) => {
        //console.log(response.data);
        this.setState((prevState) => {
          return {
            displayed_daily_payment: {
              labels: response.data.labels,
              datasets: response.data.datasets,
            },
          };
        });
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });
  };

  read_daily_check_avail = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/v1/check_avail_analytic"
          : "https://apiv1.reserv4me.com/v1/check_avail_analytic",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        fromdate: moment(this.state.startdate_individuals),
        todate: moment(this.state.enddate_individuals),
      },
      withCredentials: true,
    })
      .then((response) => {
        // console.log(
        //   "🚀 ~ file: Main_menu.js ~ line 119 ~ Main_Menu ~ response",
        //   response
        // );
        this.setState((prevState) => {
          return {
            displayed_daily_check_avail: {
              labels: response.data.labels,
              datasets: response.data.datasets,
            },
          };
        });
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });
  };

  routeController = (e, path) => {
    switch (path) {
      case "mainmenu": {
        this.props.history.push({
          pathname: `/mainmenu`,
        });
        break;
      }
    }
  };

  // onDateChange = (value) => {
  //   //const [start, end] = value;
  //   //console.log(value);
  //   if (this.state.addingdate === false) {
  //     this.setState(
  //       (prevState) => {
  //         return {
  //           startdate: value,
  //           enddate: value,
  //           addingdate: true,
  //           shouldClose: true,
  //         };
  //       },
  //       () => {
  //         //this.read_daily_demand();
  //       }
  //     );
  //   } else {
  //     this.setState(
  //       (prevState) => {
  //         return {
  //           enddate: value,
  //           addingdate: false,
  //           shouldClose: false,
  //         };
  //       },
  //       () => {
  //         this.read_daily_demand();
  //         this.read_payment_daily();
  //       }
  //     );
  //   }
  // };

  setSidebarOpen = () => {
    this.setState((prevState) => ({ sidebarOpen: !prevState.sidebarOpen }));
  };
  onDateSelectionChange = (CIdate, COdate, type) => {
    if (type === "demand") {
      this.setState(
        (prevState) => ({
          startdate: CIdate,
          enddate: COdate,
        }),
        () => {
          this.read_daily_demand();
        }
      );
    } else if (type === "clicks") {
      this.setState(
        (prevState) => ({
          startdate_individuals: CIdate,
          enddate_individuals: COdate,
        }),
        () => {
          this.read_daily_check_avail();
        }
      );
    } else if (type === "daily") {
      this.setState(
        (prevState) => ({
          startdate_payments: CIdate,
          enddate_payments: COdate,
        }),
        () => {
          this.read_payment_daily();
        }
      );
    }
  };

  render() {
    return (
      <>
        <div className="flex flex-col min-h-screen overflow-hidden ">
          <SideBar
            sidebarOpen={this.state.sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
          />
          <div className="absolute flex flex-col flex-1 overflow-y-auto overflow-x-hidden w-full">
            <Header
              sidebarOpen={this.state.sidebarOpen}
              setSidebarOpen={this.setSidebarOpen}
              userName={window.sessionStorage.getItem("uname")}
            />
            <main className=" w-screen ">
              <div className="flex flex-wrap gap-4 p-4">
                <div className="rounded border shadow-3xl w-screen">
                  <div className="flex items-center space-x-2 p-2">
                    <span>Dates:</span>
                    <div className="relative  ">
                      <Flatpickr
                        value={[
                          this.state.startdate.toDate(),
                          this.state.enddate.toDate(),
                        ]}
                        className="form-input  w-64 pl-9 text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
                        options={{
                          mode: "range",
                          static: true,
                          monthSelectorType: "static",
                          dateFormat: "M j, Y",
                          defaultDate: [
                            this.state.startdate.toDate(),
                            this.state.enddate.toDate(),
                          ],
                          prevArrow:
                            '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
                          nextArrow:
                            '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
                        }}
                        onReady={(selectedDates, dateStr, instance) => {
                          instance.element.value = dateStr.replace("to", "-");
                          instance.calendarContainer.classList.add(
                            `flatpickr-right`
                          );
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          if (selectedDates.length === 1) {
                            //this.date_CI_change(moment(selectedDates[0]));
                          } else {
                            //this.date_CI_change(moment(selectedDates[0]));
                            //this.date_CO_change(moment(selectedDates[1]));
                            this.onDateSelectionChange(
                              moment(selectedDates[0]),
                              moment(selectedDates[1]),
                              "demand"
                            );
                          }
                          console.log(
                            "🚀 ~ file: Main_Modify_res.js ~ line 2423 ~ Modify_res ~ render ~ selectedDates, dateStr, instance",
                            selectedDates,
                            dateStr,
                            instance
                          );
                        }}
                        onClose={(selectedDates, dateStr, instance) => {
                          if (selectedDates.length == 1) {
                            // console.log(
                            //   "🚀 ~ file: Main_Modify_res.js ~ line 2449 ~ Modify_res ~ render ~ selectedDates",
                            //   selectedDates[0]
                            // );
                            // this.date_CO_change(
                            //   moment(selectedDates[0]).add(1, "d")
                            // );
                            this.onDateSelectionChange(
                              moment(selectedDates[0]),
                              moment(selectedDates[0]).add(1, "d"),
                              "demand"
                            );
                            instance.setDate([
                              selectedDates[0],
                              moment(selectedDates[0]).add(1, "d").toDate(),
                              true,
                            ]);
                            instance.element.value = dateStr.replace("to", "-");
                          }
                        }}
                      />
                      <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
                        <svg
                          className="w-4 h-4 fill-current text-gray-500 ml-3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="h-96 p-4">
                    <Bar
                      data={this.state.displayed_data}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        title: { display: true, text: "Demand" },
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true,
                              },
                            },
                          ],
                        },
                      }}
                      getElementAtEvent={(event) => {
                        //console.log(event);
                      }}
                    />
                  </div>
                </div>
                <div className="grid grid-flow-row gap-4 grid-cols-2 w-screen">
                  <div className="rounded shadow-3xl p-4 col-span-2 md:col-span-1">
                    <div className="flex items-center space-x-2 p-2">
                      <span>Dates:</span>
                      <div className="relative  ">
                        <Flatpickr
                          value={[
                            this.state.startdate_individuals.toDate(),
                            this.state.enddate_individuals.toDate(),
                          ]}
                          className="form-input  w-64 pl-9 text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
                          options={{
                            mode: "range",
                            static: true,
                            monthSelectorType: "static",
                            dateFormat: "M j, Y",
                            defaultDate: [
                              this.state.startdate_individuals.toDate(),
                              this.state.enddate_individuals.toDate(),
                            ],
                            prevArrow:
                              '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
                            nextArrow:
                              '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
                          }}
                          onReady={(selectedDates, dateStr, instance) => {
                            instance.element.value = dateStr.replace("to", "-");
                            instance.calendarContainer.classList.add(
                              `flatpickr-right`
                            );
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            if (selectedDates.length === 1) {
                              //this.date_CI_change(moment(selectedDates[0]));
                            } else {
                              //this.date_CI_change(moment(selectedDates[0]));
                              //this.date_CO_change(moment(selectedDates[1]));
                              this.onDateSelectionChange(
                                moment(selectedDates[0]),
                                moment(selectedDates[1]),
                                "clicks"
                              );
                            }
                            // console.log(
                            //   "🚀 ~ file: Main_Modify_res.js ~ line 2423 ~ Modify_res ~ render ~ selectedDates, dateStr, instance",
                            //   selectedDates,
                            //   dateStr,
                            //   instance
                            // );
                          }}
                          onClose={(selectedDates, dateStr, instance) => {
                            if (selectedDates.length == 1) {
                              // console.log(
                              //   "🚀 ~ file: Main_Modify_res.js ~ line 2449 ~ Modify_res ~ render ~ selectedDates",
                              //   selectedDates[0]
                              // );
                              // this.date_CO_change(
                              //   moment(selectedDates[0]).add(1, "d")
                              // );
                              this.onDateSelectionChange(
                                moment(selectedDates[0]),
                                moment(selectedDates[0]).add(1, "d"),
                                "clicks"
                              );
                              instance.setDate([
                                selectedDates[0],
                                moment(selectedDates[0]).add(1, "d").toDate(),
                                true,
                              ]);
                              instance.element.value = dateStr.replace(
                                "to",
                                "-"
                              );
                            }
                          }}
                        />
                        <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
                          <svg
                            className="w-4 h-4 fill-current text-gray-500 ml-3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="h-96 p-4">
                      <Bar
                        data={this.state.displayed_daily_check_avail}
                        options={{
                          maintainAspectRatio: false,
                          responsive: true,
                          title: {
                            display: true,
                            text: "Individual daily clicks",
                          },
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                        getElementAtEvent={(event) => {
                          //console.log(event);
                        }}
                      />
                    </div>
                  </div>

                  <div className=" rounded shadow-3xl p-4 col-span-2 md:col-span-1">
                    <div className="flex items-center space-x-2 p-2">
                      <span>Dates:</span>
                      <div className="relative  ">
                        <Flatpickr
                          value={[
                            this.state.startdate_payments.toDate(),
                            this.state.enddate_payments.toDate(),
                          ]}
                          className="form-input  w-64 pl-9 text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
                          options={{
                            mode: "range",
                            static: true,
                            monthSelectorType: "static",
                            dateFormat: "M j, Y",
                            defaultDate: [
                              this.state.startdate_payments.toDate(),
                              this.state.enddate_payments.toDate(),
                            ],
                            prevArrow:
                              '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
                            nextArrow:
                              '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
                          }}
                          onReady={(selectedDates, dateStr, instance) => {
                            instance.element.value = dateStr.replace("to", "-");
                            instance.calendarContainer.classList.add(
                              `flatpickr-right`
                            );
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            if (selectedDates.length === 1) {
                              //this.date_CI_change(moment(selectedDates[0]));
                            } else {
                              //this.date_CI_change(moment(selectedDates[0]));
                              //this.date_CO_change(moment(selectedDates[1]));
                              this.onDateSelectionChange(
                                moment(selectedDates[0]),
                                moment(selectedDates[1]),
                                "daily"
                              );
                            }
                            // console.log(
                            //   "🚀 ~ file: Main_Modify_res.js ~ line 2423 ~ Modify_res ~ render ~ selectedDates, dateStr, instance",
                            //   selectedDates,
                            //   dateStr,
                            //   instance
                            // );
                          }}
                          onClose={(selectedDates, dateStr, instance) => {
                            if (selectedDates.length == 1) {
                              // console.log(
                              //   "🚀 ~ file: Main_Modify_res.js ~ line 2449 ~ Modify_res ~ render ~ selectedDates",
                              //   selectedDates[0]
                              // );
                              // this.date_CO_change(
                              //   moment(selectedDates[0]).add(1, "d")
                              // );
                              this.onDateSelectionChange(
                                moment(selectedDates[0]),
                                moment(selectedDates[0]).add(1, "d"),
                                "daily"
                              );
                              instance.setDate([
                                selectedDates[0],
                                moment(selectedDates[0]).add(1, "d").toDate(),
                                true,
                              ]);
                              instance.element.value = dateStr.replace(
                                "to",
                                "-"
                              );
                            }
                          }}
                        />
                        <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
                          <svg
                            className="w-4 h-4 fill-current text-gray-500 ml-3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="h-96 p-4">
                      <Bar
                        data={this.state.displayed_daily_payment}
                        options={{
                          maintainAspectRatio: false,
                          responsive: true,
                          title: { display: true, text: "Daily Payment total" },
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                        getElementAtEvent={(event) => {
                          //console.log(event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>

        {/*
          <Bar
          width={100}
          height={100}
          data={this.state.displayed_data}
          options={{ maintainAspectRatio: false }}
          getElementAtEvent={(event) => {
            //console.log(event);
          }}
        />
        <Button onClick={this.read_daily_demand}>TEst</Button>
        */}
      </>
    );
  }
}

export default withRouter(Main_Menu);

// import React, { Fragment } from "react";
// import { withRouter } from "react-router-dom";
// import "./main_menu.css";
// import axios from "axios";
// import Header from "./Header Menu/Header";
// import SideBar from "./Header Menu/SideBar";
// require("dotenv").config();

// //const { ipcRenderer } = window.require("electron");
// //const appVersion = window.require("electron").remote.app.getVersion();
// //const appVersion = 1.0;

// const InitialState = {
//   user: null,
//   disable_strbtn: false,
//   sidebarOpen: false,
// };

// //class Unitsavailable extends Component {
// class Main_menu extends React.Component {
//   constructor(props) {
//     super(props);
//     //this.onRegNumChange=this.onRegNumChange.bind(this)
//     this.state = {
//       user: null,
//       disable_strbtn: false,
//       sidebarOpen: false,
//     };
//   }
//   Main_btn_click = (e) => {
//     //console.log(e.target);
//     switch (e.target.id) {
//       case "reservation_search": {
//         this.props.history.push({
//           pathname: `/reservation_search`,
//         });
//         break;
//       }
//       case "gotomain": {
//         this.props.history.push({
//           pathname: `/`,
//         });
//         break;
//       }
//       case "makeres": {
//         this.props.history.push({
//           pathname: `/makeres`,
//         });
//         break;
//       }
//       case "modifyres": {
//         this.props.history.push({
//           pathname: `/modifyres`,
//         });
//         break;
//       }
//       case "dailyrate": {
//         this.props.history.push({
//           pathname: `/dailyrate`,
//         });
//         break;
//       }
//       case "sign_up": {
//         this.props.history.push({
//           pathname: `/signup`,
//         });
//         break;
//       }
//       case "settings": {
//         this.props.history.push({
//           pathname: `/settings`,
//         });
//         break;
//       }
//       case "daily_demand": {
//         this.props.history.push({
//           pathname: `/Daily_Demand`,
//         });
//         break;
//       }

//       default: {
//         this.props.history.push({
//           pathname: `/`,
//         });
//         break;
//       }
//     }
//   };

//   onLogout_pressed = () => {
//     axios({
//       method: "post",
//       url:
//         process.env.NODE_ENV === "development"
//           ? "http://localhost:3000/logout-local"
//           : "https://apiv1.reserv4me.com/logout-local",
//       headers: {
//         "Access-Control-Allow-Origin":
//           process.env.NODE_ENV === "development"
//             ? "http://localhost:3000"
//             : "https://apiv1.reserv4me.com",
//         "Content-Type": "application/json",
//       },
//       data: {},
//       withCredentials: true,
//     }).then((result) => {
//       window.sessionStorage.clear();
//       //console.log(result.data);
//       this.props.history.push({
//         pathname: `/`,
//       });
//     });
//   };
//   setSidebarOpen = () => {
//     this.setState((prevState) => ({ sidebarOpen: !prevState.sidebarOpen }));
//   };

//   render() {
//     //console.log(appVersion);
//     return (
//       <>
//         <div className="flex flex-col min-h-screen overflow-hidden">
//           <SideBar
//             sidebarOpen={this.state.sidebarOpen}
//             setSidebarOpen={this.setSidebarOpen}
//           />
//           <Header
//             sidebarOpen={this.state.sidebarOpen}
//             setSidebarOpen={this.setSidebarOpen}
//             className="z-90"
//             userName={window.sessionStorage.getItem("uname")}
//           />
//           <main></main>
//         </div>
//       </>
//     );
//   }
// }
// export default withRouter(Main_menu);
// {/* <Jumbotron>
//             <center>
//               <h1>{`Management Software `}</h1>
//             </center>
//             <div className="userbox-main">
//               <p>{`Logged in as: ${window.sessionStorage.getItem("uname")}`}</p>
//             </div>
//           </Jumbotron>
//           <Container>
//             <Row className="Btn_main_padding aligntoright ">
//               <Col>
//                 {
//                   <a
//                     onClick={this.Main_btn_click}
//                     id="settings"
//                     className="settings-btn"
//                   >
//                     <SettingsIcon className="settings-btn void_event_path" />
//                   </a>
//                 }
//                 {/*<a onClick={this.Main_btn_click} href="javascript:void(0);" id="settings">
// 								<SettingsIcon onClick={this.Main_btn_click} id="settings" />
// 		</a>*/}
//               </Col>
//             </Row>
//             <Row className="Btn_main_padding">
//               <Col>
//                 <Button onClick={this.Main_btn_click} id="gotomain">
//                   Go Back to Main
//                 </Button>
//               </Col>
//             </Row>
//             <Row className="Btn_main_padding">
//               <Col>
//                 <Button onClick={this.Main_btn_click} id="makeres">
//                   Make a Reservation
//                 </Button>
//               </Col>
//             </Row>
//             <Row className="Btn_main_padding">
//               <Col>
//                 <Button onClick={this.Main_btn_click} id="modifyres">
//                   Modify a Reservation
//                 </Button>
//               </Col>
//             </Row>
//             <Row className="Btn_main_padding">
//               <Col>
//                 <Button onClick={this.Main_btn_click} id="dailyrate">
//                   Daily Rates
//                 </Button>
//               </Col>
//             </Row>
//             <Row
//               className="Btn_main_padding"
//               hidden={
//                 process.env.NODE_ENV === "development"
//                   ? false
//                   : window.sessionStorage.getItem("hassignedup") === "true"
//                   ? true
//                   : false
//               }
//             >
//               <Col>
//                 <Button onClick={this.Main_btn_click} id="sign_up">
//                   Sign Up
//                 </Button>
//               </Col>
//             </Row>
//             <Row className="Btn_main_padding">
//               <Col>
//                 <Button onClick={this.onLogout_pressed} id="logout">
//                   Log Out
//                 </Button>
//               </Col>
//             </Row>
//             <Row className="Btn_main_padding">
//               <Col>
//                 <Button onClick={this.Main_btn_click} id="daily_demand">
//                   Daily Demand
//                 </Button>
//               </Col>
//             </Row>
//             <Row className="Btn_main_padding">
//               <Col>
//                 <Button onClick={this.Main_btn_click} id="reservation_search">
//                   Reservation Search
//                 </Button>
//               </Col>
//             </Row>
//             {/*
//                     <Row className="Btn_main_padding">
//                         <Col><Button onClick={this.Main_btn_click} id="settings">Settings</Button></Col>
//                     </Row>
//                     */}
//           </Container> */}
