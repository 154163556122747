import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
/*const { ipcRenderer } = window.require("electron");
const { shell } = window.require("electron");*/

class StripeConnection extends React.Component {
  //this.props.stripe_acct_id

  componentDidMount() {
    ////console.log(this.props);
    if (
      this.props.stripe_acc_id === "" ||
      this.props.stripe_verification_passed === false
    ) {
      this.props.set_continue_disabled("disable");
    }
  }

  connect_to_Stripe = () => {
    this.props.set_str_btn_disabled(true);
    this.props.set_continue_disabled("disable");
    this.props.onStripe_loading_Change(true);
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/stripecreate"
          : "https://apiv1.reserv4me.com/stripecreate",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
      },
      data: {},
      withCredentials: true,
    })
      .then((result) => {
        //console.log("after sending to stripe", result.data);
        this.props.stripe_acc_setter(result.data.acc_id);
        this.props.onStripe_loading_Change(false);

        window.location.replace(result.data.url);

        /*let req = JSON.stringify({
        ...result.data,
      });*/
        //this.setState(prevState => ({ disable_strbtn: true }))
        //let resultafter = ipcRenderer.sendSync("chanel1", req);
        // //console.log("result", resultafter);
        /*if (resultafter.exit === true) {
        axios({
          method: "post",
          url:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/v1/verify_stripe"
              : "https://apiv1.reserv4me.com/v1/verify_stripe",
          headers: {
            "Access-Control-Allow-Origin":
              process.env.NODE_ENV === "development"
                ? "http://localhost:3000"
                : "https://apiv1.reserv4me.com",
            "Content-Type": "application/json",
          },
          data: {
            str_acc: this.props.str_acc,
          },
          withCredentials: true,
        }).then((result) => {
          //console.log("verification", result.data);
          if (
            result.data.charges_enabled === false &&
            result.data.details_submitted === false
          ) {
            this.props.close_Stripe_Modal(false);
          }
          if (
            result.data.charges_enabled === false &&
            result.data.details_submitted === true
          ) {
            this.props.close_Missing_Modal();
            this.props.set_continue_disabled("enable");
            this.props.stripe_verification_setter(true);
          } else {
            this.props.set_continue_disabled("enable");
            this.props.stripe_verification_setter(true);
          }
        });
      }*/
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  render() {
    return (
      <>
        <div className="mx-auto w-full md:w-8/12 space-y-2">
          <h1 className="mx-auto  font-bold text-lg text-center ">
            Online Payment with Stripe{" "}
          </h1>
          <div>
            <div className="mx-auto">
              <span>
                Reserv4me (owned by Engsys Analyics LLC) uses Stripe to accept
                online payments all funds collected will be deposited directly
                to the business account you provide during the sign up process
                (Please make sure that only business accounts are used no
                personal accounts will be accepted as per Stripe policies),
                Engsys Analytics LLC nor Reserv4me.com have any access to any
                funds at any time;this was done by design. Your account will
                show new funds within 2-3 business days, all is handles by
                Stripe ( If you want to know more about Stripe and how they help
                handle online transactions almost everywhere please visit{" "}
                <a
                  className="text-blue-600 cursor-pointer"
                  href="https://www.stripe.com"
                  target="_blank"
                >
                  this page
                </a>
                )
              </span>
              <div className="pt-10 mx-auto flex justify-center">
                <a
                  onClick={this.connect_to_Stripe}
                  className={
                    this.props.str_btn_deactivated === true
                      ? "cursor-default pointer-events-none ease-in-out hover:scale-110 hover:shadow-lg"
                      : "cursor-pointer ease-in-out hover:scale-110 hover:shadow-lg "
                  }
                >
                  <img
                    src="https://images.reserv4me.com/blue-on-light@3x[1].png"
                    width="250"
                  />
                </a>
              </div>
            </div>
          </div>

          <div
            hidden={!this.props.loading_stripe}
            className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  "
          >
            <div className="loading"></div>
            <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
              <p className="text-2xl text-white -translate-y-16">
                Please be patient
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(StripeConnection);
