import React from "react";
import moment from "moment";
import "./dailyrate.css";

const InitialState = {};

class Rate_Display extends React.Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
  }

  render() {
    ////console.log(this.props.rates)
    const { rates, idbox, onChangePrice, idRow, DNE } = this.props;
    return (
      <>
        <div
          key={`${idbox}_${moment(rates.dateofday).format("DD MMM")}`}
          className={`space-y-2 ${
            parseInt(rates.numofroomavail) === 0 ? "bg-red-500" : ""
          }`}
        >
          <div className="flex justify-between">
            <p>{moment(rates.dateofday).format("DD MMM")}</p>
            <p hidden={DNE === true ? false : true} className="text-red-500">
              Rate DNE
            </p>
          </div>

          <div className="flex justify-between">
            <p className="text-xs">Daily Rate</p>
            <input
              onBlur={onChangePrice}
              className="form-input w-2/4 text-xs text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
              id={`${idRow}${idbox}${0}`}
              type="text"
              placeholder="Enter Rate"
              defaultValue={rates.basecostp}
              disabled={DNE === true ? true : false}
            />
          </div>
          <div className="flex justify-between">
            <p className="text-xs">Minimum Stay</p>
            <input
              id={`${idRow}${idbox}${1}`}
              onBlur={onChangePrice}
              className="form-input w-2/4 text-xs text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
              type="text"
              placeholder="Enter Units"
              defaultValue={rates.minimumstay}
              disabled={DNE === true ? true : false}
            />
          </div>
          <div className="flex justify-between">
            <p className="text-xs">Units Avail</p>
            <input
              id={`${idRow}${idbox}${2}`}
              onBlur={onChangePrice}
              type="text"
              placeholder="Enter Units"
              defaultValue={rates.numofroomavail}
              className="form-input w-2/4 text-xs text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
              disabled={DNE === true ? true : false}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Rate_Display;
