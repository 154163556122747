import React from "react";

class Daily_Rate_Settings extends React.Component {
  render() {
    return (
      <>
        <div>
          <h3>Daily Rate Settings Test</h3>
        </div>
      </>
    );
  }
}

export default Daily_Rate_Settings;
