import React from "react";
import moment from "moment";

const discount_grid = ({ discountAdded, pagedisplay, onDeleteDiscount }) => {
  ////console.log(pagedisplay)
  return (
    <>
      {discountAdded.map((discount, i) => {
        //console.log("discountnull?",discount.discountapid===undefined)
        return (
          <div className="flex" key={i}>
            <div className="w-1/4">{discount.discount_name}</div>
            <div className="w-1/4">{discount.discount_rate}</div>
            <div className="w-1/4">
              {pagedisplay === "maker"
                ? moment().format("MM/DD/YYYY")
                : moment(discount.datemade).format("MM/DD/YYYY")}
            </div>
            <div className="w-1/4">
              <button
                id={
                  pagedisplay === "maker"
                    ? i
                    : discount.discountapid === undefined
                    ? `${i}-N`
                    : `${i}-${discount.discountapid}`
                }
                onClick={onDeleteDiscount}
              >
                <svg viewBox="0 0 512 512" className="h-6">
                  <path
                    fill="currentColor"
                    d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default discount_grid;
