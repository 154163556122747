import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Main_settings.css";
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/Home";
import Modal_Success_Updates from "./Modal_Success_Updates";
import Email_Settings from "./Email_Settings";
import Daily_Rate_Settings from "./Daily_Rate_Settings";
import Inventory_Management from "./Inventory_Management";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Modal_Request_Inv from "./Modal_Inventory_Request";
import Modal_Inv_Req_fail from "./Modal_Inv_Req_fail";
import SideBar from "../Header Menu/SideBar";
import Header from "../Header Menu/Header";
import Apperance from "./Apperance";
import Room_Editing from "./Room_Editing";
//const { shell } = window.require("electron");

const InitialState = {
  stage: 0,
  email_settings: [],
  hidde_savebtn: true,
  email_settings_updates: [],
  saveSettings_Modal_Show: false,
  latest_inv_date: null,
  hotel_local_id: 0,
  room_types: [],
  addingroom: false,
  successModalReqisOpen: false,
  failModalReqisOpen: false,
};

/**
 * stage is how we will dynamically draw the settings view when each tab is pressed
 * 0:Email
 * 1:Daily Rate
 */

class SettingsPage extends React.Component {
  constructor(props) {
    //let location = useLocation();
    //console.log(location.userProps);
    super(props);
    this.state = {
      stage: props.location.userProps.stage,
      email_settings: [],
      hidde_savebtn: true,
      email_settings_updates: [],
      saveSettings_Modal_Show: false,
      latest_inv_date: null,
      hotel_local_id: 0,
      room_types: [],
      addingroom: false,
      successModalReqisOpen: false,
      failModalReqisOpen: false,
      sidebarOpen: false,
    };
  }

  componentDidMount() {
    this.readEmailSettings();
    this.readDateofInventory();
  }

  Stage_Change = (e) => {
    let stagetemp = e.target.id;
    ////console.log("target", e.target.id);
    this.setState(
      (prevState) => ({
        stage: parseInt(stagetemp),
        addingroom: false,
      }),
      () => {
        this.readEmailSettings();
      }
    );
  };

  readEmailSettings = () => {
    /*
    will read receivedby channel from database pass it onto the state so we 
    can change it an determine whether emails will be sent automatically or
    manually after review of reservation under modify reservation
    */
    if (this.state.stage === 0) {
      axios({
        method: "post",
        url:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/v1/getEmailSettings"
            : "https://apiv1.reserv4me.com/v1/getEmailSettings",
        headers: {
          "Access-Control-Allow-Origin":
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://apiv1.reserv4me.com",
          "Content-Type": "application/json",
          Authorization: "internalvar.apikey",
        },
        data: {},
        withCredentials: true,
      })
        .then((resp) => {
          //console.log("email test", resp);
          this.setState((prevState) => ({
            email_settings: resp.data,
          }));
        })
        .catch((error) => {
          this.props.history.push({
            pathname: `/`,
          });
        });
    }
  };

  onEmailMethodChange = (e) => {
    let temp = e.target;
    ////console.log(temp.value.split("-")[1] === "null");
    if (temp.value.split("-")[1] !== "null") {
      this.setState((prevState) => {
        //list takes care of updating the eamil settings
        //object
        const list = prevState.email_settings.map((item, j) => {
          if (j === parseInt(temp.value.split("-")[0])) {
            item.immediate_email =
              temp.value.split("-")[1] === "true" ? true : false;
            return { ...item };
          } else {
            return { ...item };
          }
        });
        let updatearr = [];

        if (
          prevState.email_settings_updates.some(
            (email_set) =>
              email_set.receivedBy_id ===
              prevState.email_settings[parseInt(temp.value.split("-")[0])]
                .receivedBy_id
          )
        ) {
          updatearr = prevState.email_settings_updates.map((item, j) => {
            if (
              prevState.email_settings[parseInt(temp.value.split("-")[0])]
                .receivedBy_id ===
              prevState.email_settings_updates[j].receivedBy_id
            ) {
              item.immediate_email =
                temp.value.split("-")[1] === "true" ? true : false;
              return { ...item };
            } else {
              return { ...item };
            }
          });
        } else {
          updatearr = [
            ...prevState.email_settings_updates,
            {
              receivedBy_id:
                prevState.email_settings[temp.value.split("-")[0]]
                  .receivedBy_id,
              immediate_email:
                temp.value.split("-")[1] === "true" ? true : false,
            },
          ];
        }
        return {
          email_settings: list,
          hidde_savebtn: false,
          email_settings_updates: updatearr,
        };
      });
    }
  };

  saveEmailSettings = () => {
    //console.log(this.state.email_settings, this.state.email_settings_updates);
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/v1/updateEmailSettings"
          : "https://apiv1.reserv4me.com/v1/updateEmailSettings",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        email_settings_updates: this.state.email_settings_updates,
      },
      withCredentials: true,
    })
      .then((result) => {
        if (result.data.sucessful === true) {
          this.setState(
            (prevState) => {
              return InitialState;
            },
            () => {
              this.setState(
                () => {
                  return {
                    saveSettings_Modal_Show: true,
                  };
                },
                () => {
                  this.readEmailSettings();
                }
              );
            }
          );
        }
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  handleCloseModal_Sucess = () => {
    this.setState((prevState) => {
      return {
        saveSettings_Modal_Show: !prevState.saveSettings_Modal_Show,
      };
    });
  };

  returntoMain = () => {
    this.props.history.push({
      pathname: `/mainmenu`,
    });
  };

  readDateofInventory = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/v1/getLatestInv"
          : "https://apiv1.reserv4me.com/v1/getLatestInv",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {},
      withCredentials: true,
    })
      .then((result) => {
        // console.log("here data", result.data);
        this.setState(() => {
          return {
            latest_inv_date: result.data.latest_date,
            hotel_local_id: result.data.hotel_local_id,
            room_types: result.data.room_types,
          };
        });
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  addingNewRoom = () => {
    this.setState(() => {
      return { addingroom: true };
    });
  };

  request_Inventory_Creation = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/v1/RequesAdditionalInv"
          : "https://apiv1.reserv4me.com/v1/RequesAdditionalInv",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {},
      withCredentials: true,
    })
      .then((result) => {
        if (result.data.successful === true) {
          this.handle_successModalRequestInv();
        } else {
        }
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  handle_successModalRequestInv = () => {
    this.setState((prevState) => {
      return { successModalReqisOpen: !prevState.successModalReqisOpen };
    });
  };

  handle_FailModalReqInv = () => {
    this.setState((prevState) => {
      return { failModalReqisOpen: !prevState.failModalReqisOpen };
    });
  };

  setSidebarOpen = () => {
    this.setState((prevState) => ({ sidebarOpen: !prevState.sidebarOpen }));
  };

  render() {
    return (
      <div className="flex flex-col min-h-screen overflow-hidden">
        <SideBar
          sidebarOpen={this.state.sidebarOpen}
          setSidebarOpen={this.setSidebarOpen}
        />
        <div className="absolute flex flex-col flex-1 overflow-y-auto overflow-x-hidden w-full">
          <Header
            sidebarOpen={this.state.sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            className="z-90"
            userName={window.sessionStorage.getItem("uname")}
          />
          <main className="w-screen">
            <div className="px-4 sm:px-6 lg:px-8 py-8 ">
              {(() => {
                //console.log("here", this.state.stage);
                switch (this.state.stage) {
                  case 0: {
                    return (
                      <>
                        <Email_Settings
                          email_settings={this.state.email_settings}
                          onEmailMethodChange={this.onEmailMethodChange}
                          hidde_savebtn={this.state.hidde_savebtn}
                          saveEmailSettings={this.saveEmailSettings}
                        />
                      </>
                    );
                  }
                  case 1: {
                    return (
                      <>
                        <Daily_Rate_Settings />
                      </>
                    );
                  }
                  case 2: {
                    return (
                      <>
                        <Inventory_Management
                          latest_inv_date={this.state.latest_inv_date}
                          room_types={this.state.room_types}
                          addingNewRoom={this.addingNewRoom}
                          addingroom={this.state.addingroom}
                          request_Inventory_Creation={
                            this.request_Inventory_Creation
                          }
                        />
                      </>
                    );
                  }
                  case 3: {
                    return (
                      <>
                        <Apperance />
                      </>
                    );
                  }
                  case 4: {
                    return (
                      <>
                        <Room_Editing />
                      </>
                    );
                  }
                }
              })()}
            </div>
          </main>
          <Modal_Success_Updates
            show={this.state.saveSettings_Modal_Show}
            handleClose={this.handleCloseModal_Sucess}
          />
          <Modal_Request_Inv
            show={this.state.successModalReqisOpen}
            handleClose={this.handle_successModalRequestInv}
          />
          <Modal_Inv_Req_fail
            show={this.state.failModalReqisOpen}
            handleClose={this.handle_FailModalReqInv}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(SettingsPage);

{
  /* <Row>
                <Col className="main_Jumbo-Container " xs={3}>
                  <Row className="mainsettings_row border_separation ">
                    <Col xs={3}>
                      <Button>
                        <SearchIcon className="" />
                      </Button>
                    </Col>
                    <Col xs={6} className="">
                      <h4>Settings</h4>
                    </Col>
                    <Col xs={3}>
                      <Button>
                        <HomeIcon onClick={this.returntoMain} />
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      id={0}
                      onClick={this.Stage_Change}
                      className={
                        this.state.stage == 0 ? "setting_selected " : ""
                      }
                    >
                      <p id={0} onClick={this.Stage_Change}>
                        Email
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      id={1}
                      onClick={this.Stage_Change}
                      className={
                        this.state.stage == 1 ? "setting_selected " : ""
                      }
                    >
                      <p id={1} onClick={this.Stage_Change}>
                        Daily Rate
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      id={2}
                      onClick={this.Stage_Change}
                      className={
                        this.state.stage == 2 ? "setting_selected " : ""
                      }
                    >
                      <p id={2} onClick={this.Stage_Change}>
                        Inventory
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col className="main_Jumbo-Container " xs={9}>
                  {(() => {
                    //console.log("here", this.state.stage);
                    switch (this.state.stage) {
                      case 0: {
                        return (
                          <>
                            <Email_Settings
                              email_settings={this.state.email_settings}
                              onEmailMethodChange={this.onEmailMethodChange}
                              hidde_savebtn={this.state.hidde_savebtn}
                              saveEmailSettings={this.saveEmailSettings}
                            />
                          </>
                        );
                      }
                      case 1: {
                        return (
                          <>
                            <Daily_Rate_Settings />
                          </>
                        );
                      }
                      case 2: {
                        return (
                          <>
                            <Inventory_Management
                              latest_inv_date={this.state.latest_inv_date}
                              room_types={this.state.room_types}
                              addingNewRoom={this.addingNewRoom}
                              addingroom={this.state.addingroom}
                              request_Inventory_Creation={
                                this.request_Inventory_Creation
                              }
                            />
                          </>
                        );
                      }
                    }
                  })()}
                </Col>
              </Row> */
}
