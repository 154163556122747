import React from "react";

function FieldGroup({
  validationState,
  id,
  label,
  help,
  type,
  options,
  disabled,
  ...props
}) {
  return (
    <div className=" ">
      {/* <label
        className="block text-black text-sm font-medium mb-1"
        htmlFor={label}
      >
        {label} <span className="text-red-600">*</span>
      </label> */}

      {type === "textarea" ? (
        <textarea
          id={id}
          {...props}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner "
        ></textarea>
      ) : type === "select" ? (
        <select
          id={id}
          {...props}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
        >
          {options()}
        </select>
      ) : (
        <input
          id={id}
          type={type}
          disabled={disabled}
          className={`form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner ${
            disabled === true ? "bg-gray-300" : "bg-white"
          }`}
          {...props}
        />
      )}

      {type === "textarea" ? (
        <p hidden={validationState} className=" text-sm mt-2 flex justify-end">
          {help}
        </p>
      ) : (
        <p hidden={validationState} className="text-red-500 text-sm mt-2">
          {help}
        </p>
      )}
    </div>
  );
}

class Rooms_and_Rates extends React.Component {
  render() {
    const {
      basic_info,
      basic_info_validation,
      onBasicInfo_Change,
      room_type_list,
      room_type_temp_list,
      room_type_temp_valid,
      adding_room_type,
      empty_room_type,
      room_type_initial_Change,
      onAddRooms,
      onRoomTypeChange,
      room_type_verified,
    } = this.props;
    return (
      <>
        <div className="space-y-2">
          <h1 className="text-lg">{`Rooms & Rates`}</h1>
          <p className=" shadow-div">
            In the following form you can fill in the type of room(s), the base
            rates (the base rate is the rate that the initial inventory will be
            started, don't worry you can change this at any time),Max Occupancy,
            Base Occupancy
          </p>
          <div className="space-y-2 pt-4">
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <span>Enter the Total Number of Rooms in this Facility:</span>
              <FieldGroup
                id="total_numb_rooms"
                type="text"
                placeholder="Enter Total Number of Rooms"
                value={basic_info.total_numb_rooms}
                onChange={onBasicInfo_Change}
                validationState={
                  room_type_verified === false
                    ? false
                    : basic_info_validation.total_numb_rooms
                }
                help={`Enter a valid number of rooms greater than 0`}
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={onAddRooms}
                className="
              lg:order-1
              order-2
              px-6
              py-2.5
              bg-blue-600
              text-white
              font-medium
              text-xs
              leading-tight
              uppercase
              rounded
              shadow-md
              hover:bg-blue-700 hover:shadow-lg
              focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
              active:bg-blue-800 active:shadow-lg
              transition
              duration-150
              ease-in-out
              ml-1"
              >
                {adding_room_type === false ? `Add Room` : "Save Room"}
              </button>
            </div>
            <div className="grid grid-flow-col grid-cols-5 gap-4">
              <p>Room Type Name</p>
              <p>Base Rate</p>
              <p>Max Occupancy</p>
              <p>Base Occupancy</p>
              <p>Extra Guest Cost</p>
            </div>

            <div
              className="grid grid-flow-col grid-cols-5 gap-4"
              hidden={!adding_room_type}
            >
              <FieldGroup
                id="room_type_name"
                type="text"
                placeholder="Enter Room Type Name"
                autoFocus
                value={room_type_temp_list.room_type_name}
                onChange={(e) => {
                  onRoomTypeChange(e);
                }}
                validationState={
                  room_type_temp_list.room_type_name === ""
                    ? true
                    : room_type_temp_valid.room_type_name
                }
                help={`Please Fill in the Field`}
              />
              <FieldGroup
                id="base_rate"
                type="text"
                placeholder="Enter Base Rate"
                value={room_type_temp_list.base_rate}
                onChange={(e) => {
                  onRoomTypeChange(e);
                }}
                validationState={
                  room_type_temp_list.base_rate === ""
                    ? true
                    : room_type_temp_valid.base_rate
                }
                help={`Please Fill in the Field and/or a price that is higher
                  than min. rate`}
              />
              <FieldGroup
                id="max_occupancy"
                type="text"
                placeholder="Enter Max Occupancy"
                value={room_type_temp_list.max_occupancy}
                onChange={(e) => {
                  onRoomTypeChange(e);
                }}
                validationState={
                  room_type_temp_list.max_occupancy === ""
                    ? true
                    : room_type_temp_valid.max_occupancy
                }
                help={`Please Fill in the Field`}
              />
              <FieldGroup
                id="base_occupancy"
                type="text"
                placeholder="Enter Base Occupancy"
                value={room_type_temp_list.base_occupancy}
                onChange={(e) => {
                  onRoomTypeChange(e);
                }}
                validationState={
                  room_type_temp_list.base_occupancy === ""
                    ? true
                    : room_type_temp_valid.base_occupancy
                }
                help={`Please Fill in the Field`}
              />
              <FieldGroup
                id="extraguestc"
                type="text"
                placeholder="Enter extra guest cost "
                value={room_type_temp_list.extraguestc}
                onChange={(e) => {
                  onRoomTypeChange(e);
                }}
                validationState={
                  room_type_temp_list.extraguestc === ""
                    ? true
                    : room_type_temp_valid.extraguestc
                }
                help={`Please Fill in the Field using numbers only`}
              />
            </div>

            {room_type_list.map((current, index) => {
              return (
                <>
                  <div
                    className="grid grid-flow-col grid-cols-5 gap-4"
                    key={index.toString()}
                  >
                    <div>{current.room_type_name}</div>
                    <div>{current.base_rate}</div>
                    <div>{current.max_occupancy}</div>
                    <div>{current.base_occupancy}</div>
                    <div>{current.extraguestc}</div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
export default Rooms_and_Rates;
