import React from "react";

const Modal_Handle_Pay_req = ({
  handleClose,
  show,
  Amount_req,
  onAmount_req_Change,
  ispayment_req_Invalidated,
  onAddPayment_req_Button,
  requestFull,
  onrequestFullChange,
}) => {
  return (
    <>
      <div
        className={`modal fade fixed top-0 left-0  w-full h-full outline-none overflow-x-hidden overflow-y-auto  bg-black/75 ${
          show === true ? "show block" : ""
        }`}
        id="exampleModal">
        <div className="modal-dialog relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalLabel">
                Request Payment:
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                onClick={handleClose}></button>
            </div>
            <div className="modal-body relative p-4">
              <div>
                <div className="flex items-center justify-start w-full mb-12">
                  <label
                    for="toggleC"
                    className="flex items-center cursor-pointer">
                    {/* <!-- toggle --> */}
                    <div className="relative">
                      {/* <!-- input --> */}
                      <input
                        type="checkbox"
                        id="toggleC"
                        className="sr-only"
                        onChange={onrequestFullChange}
                      />
                      {/* <!-- line --> */}
                      <div
                        className={`block ${
                          requestFull === false
                            ? "bg-gray-400  "
                            : " bg-blue-600 "
                        }  w-14 h-8 rounded-full`}></div>
                      {/* <!-- dot --> */}
                      <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                    </div>
                    {/* <!-- label --> */}
                    <div className="ml-3 text-gray-700 font-medium">
                      Request Full Payment
                    </div>
                  </label>
                </div>
                <div className="flex justify-between items-start  space-x-2">
                  <div className="flex flex-wrap w-2/4 justify-between items-center">
                    <label for="amount">Amount</label>

                    <input
                      className={`w-2/4 form-input ${
                        requestFull === false
                          ? "text-black  "
                          : " text-gray-600 "
                      }  focus:border-blue-500 rounded  border border-gray-400 shadow-inner`}
                      id="amount"
                      type="text"
                      placeholder="$0.00"
                      value={Amount_req}
                      onChange={onAmount_req_Change}
                      disabled={requestFull === true ? true : false}
                    />
                    <div className="flex justify-end w-full pt-2 pr-4">
                      <p
                        className={`text-red-500 ${
                          ispayment_req_Invalidated === true ? "show" : "hidden"
                        }`}>
                        Field needed
                      </p>
                    </div>
                  </div>

                  <button
                    onClick={onAddPayment_req_Button}
                    className="px-6
                      py-2.5
                      bg-blue-600
                      text-white
                      font-medium
                      text-xs
                      leading-tight
                      uppercase
                      rounded
                      shadow-md
                      hover:bg-blue-700 hover:shadow-lg
                      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                      active:bg-blue-800 active:shadow-lg
                      transition
                      duration-150
                      ease-in-out
                      ml-1 ">
                    Request Payment
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button
                type="button"
                className="px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out
      ml-1"
                onClick={handleClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal_Handle_Pay_req;
