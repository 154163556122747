import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
class Review_Info extends React.Component {
  onLogout_pressed = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/logout-local"
          : "https://apiv1.reserv4me.com/logout-local",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
      },
      data: {},
      withCredentials: true,
    })
      .then((result) => {
        window.sessionStorage.clear();
        //console.log(result.data);
        this.props.history.push({
          pathname: `/`,
        });
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });
  };
  render() {
    return (
      <div
        className="p-4 absolute -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2
      rounded shadow-3xl overflow-hidden container w-full md:w-8/12 "
      >
        <h1 className="mx-auto w-8/12 font-bold text-lg text-center">
          Welcome to Reserv4me Management
        </h1>

        <div className="mx-auto w-8/12">
          <span>
            {`We are working to review your information, Please allow up to 24 hrs
            for our team to review your content, we will be in touch shortly. We
            can always be reached by email: `}
            <a href="mailto:support@reserv4me.com" className="text-blue-600">
              support@reserv4me.com
            </a>
          </span>
          <div className="stripebtn">
            <button
              className="font-semibold  bg-blue-500 text-white  p-2 rounded "
              onClick={this.onLogout_pressed}
            >
              Log Out
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Review_Info);
