import React, { Fragment } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Occup_sel from "./occupancy_selector";

class Client_preview_grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      components: {
        //selectcellEditor:getnumber(value),
        //selectcellEditor_child:getnumber2(value),
      },
      frameworkComponents: {
        selectcellEditor: Occup_sel,
      },
    };
  }

  render() {
    return (
      <Fragment>
        <div
          className="ag-theme-balham"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <AgGridReact
            columnDefs={this.props.Columns}
            rowData={this.props.Rows}
            rowClassRules={this.props.rowClassRules}
            frameworkComponents={this.state.frameworkComponents}
            onCellValueChanged={(e) => this.props.onCellValueChanged(e)}
            onGridReady={this.props.onGridReady}
            onRowDoubleClicked={this.props.onRowDoubleClicked}
            rowSelection="single"
          ></AgGridReact>
        </div>
      </Fragment>
    );
  }
}

export default Client_preview_grid;
