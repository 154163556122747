import React from "react";

class Basic_Info extends React.Component {
  componentDidMount() {
    this.props.set_continue_disabled("enable");
  }

  render() {
    return (
      <>
        <div className="mx-auto w-full md:w-8/12 space-y-2">
          <h1 className="mx-auto  font-bold text-lg text-center ">
            Online Integration
          </h1>
          <div>
            <div className="mx-auto  ">
              <span>
                By agreeing to use our booking engine, you are accepting to pay
                a 6% commision of all online sales made directly through
                Reserv4me.com to Engsys Analytics LLC at the end of each month
                the usage of our local booking engine or Control Management
                Propery (CMP) will subject to the continued correct usage of our
                online booking engine, once the usage or commision payment is
                stopped then your license to usa any of our software or company
                name will be automatically removed you also agree to our{" "}
                <a
                  href="https://www.reserv4me.com/terms-and-conditions"
                  target="_blank"
                  className="text-blue-600"
                >
                  Term and Services
                </a>
              </span>
              <div className="flex justify-center space-x-2 pt-4 w-90 mx-auto">
                <input
                  className="rounded  border border-gray-400 shadow-inner"
                  type="checkbox"
                  id="formBasicCheckbox"
                  name="formBasicCheckbox"
                  checked={this.props.isTermsAgreed}
                  onChange={this.props.TermsAgreedto_Change}
                />
                <label for="formBasicCheckbox">
                  I have read,understand and agree to all the Terms and
                  Conditions
                </label>
                {/* <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    checked={this.props.isTermsAgreed}
                    onChange={this.props.TermsAgreedto_Change}
                    type="checkbox"
                    label="I have read,understand and agree to all the Terms and Conditions"
                  />
                </Form.Group> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Basic_Info;
