import React from "react";
import Basic_Info from "./Apperance_Module/Basic_Info_Edit";
import axios from "axios";
import { Prompt, withRouter } from "react-router-dom";
import Modal_Success_Updates from "./Modal_Success_Updates";

const InitialState = {
  hotel_basicinfo: {
    hotelemail: "",
    hotel_phone: "",
    hotel_fax: "",
    hotel_address: "",
    hotel_town: "",
    hotel_state: "",
    hotel_zip_code: "",
    hotel_min_price: "",
    hotelinsta: "",
    hoteltwitter: "",
    hotelfblink: "",
    hotel_min_price: "",
  },
  basic_info_validation: {
    hotel_phone: false,
    hotelemail: false,
    hotel_fax: false,
    hotel_address: false,
    hotel_town: false,
    hotel_state: false,
    hotel_zip_code: false,
    hotelfblink: false,
    hoteltwitter: false,
    hotelinsta: false,
    hotel_min_price: false,
  },
  data_changed: {},
  hotel_logo_verification: null,
  basic_is_verified: false,
  selectedFiles: "",

  temp_logo_URL: "",
  file_too_Large: false,
  hotel_logo: "",
  new_logo: false,
  isBlocking: false,
  saveSettings_Modal_Show: false,
};

class Apperance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel_basicinfo: {
        hotelemail: "",
        hotel_phone: "",
        hotel_fax: "",
        hotel_address: "",
        hotel_town: "",
        hotel_state: "",
        hotel_zip_code: "",
        hotel_min_price: "",
        hotelinsta: "",
        hoteltwitter: "",
        hotelfblink: "",
        hotel_min_price: "",
      },
      basic_info_validation: {
        hotel_phone: false,
        hotelemail: false,
        hotel_fax: false,
        hotel_address: false,
        hotel_town: false,
        hotel_state: false,
        hotel_zip_code: false,
        hotelfblink: false,
        hoteltwitter: false,
        hotelinsta: false,
        hotel_min_price: false,
      },
      data_changed: {},
      hotel_logo_verification: null,
      basic_is_verified: false,
      selectedFiles: "",

      temp_logo_URL: "",
      file_too_Large: false,
      hotel_logo: "",
      new_logo: false,
      isBlocking: false,
      saveSettings_Modal_Show: false,
    };
  }
  componentDidMount() {
    this.LoadinforBasicData();
  }

  LoadinforBasicData = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/v1/getBatsicInfo"
          : "https://apiv1.reserv4me.com/v1/getBatsicInfo",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {},
      withCredentials: true,
    })
      .then((resp) => {
        // console.log("🚀 ~ file: Apperance.js ~ line 65 ~ Apperance ~ resp", resp);
        this.setState((prevState) => {
          return {
            hotel_basicinfo: { ...resp.data },
          };
        });
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  onLogoImgSelected = (e) => {
    //console.log("selected", e.target.files);
    const reader = new FileReader();

    let tempFiles = Array.from(e.target.files);
    if (tempFiles[0].size < 100000) {
      //console.log("selected", tempFiles)
      reader.onload = () => {
        if (reader.readyState === 2) {
          // console.log(reader.result);
          this.setState({
            temp_logo_URL: reader.result,
            file_too_Large: false,
            hotel_logo: tempFiles,
            new_logo: true,
            isBlocking: true,
          });
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    } else {
      ////console.log("Please select a file that is less than 100KB in size for your logo")
      this.setState({
        hotel_logo: InitialState.hotel_logo,
        file_too_Large: true,
      });
    }
  };

  basicValidation_Text = (id, currentValue) => {
    switch (id) {
      case "hotel_fax":
      case "hotel_phone": {
        let previoustestRegEx = new RegExp(
          /^((\+1|1)?( |-)?)?(\(([2-9])?([0-9]{2})?)$/
        );
        /*console.log(
          "test12",
          previoustestRegEx.test(currentValue),
          currentValue
        );*/
        if (previoustestRegEx.test(currentValue)) {
          currentValue = currentValue.substring(0, currentValue.length - 1);
          //console.log("check", currentValue);
        }
        //console.log("check2".currentValue);
        ////console.log("test12",previoustestRegEx.test(currentValue))
        //if(currentValue)
        let temppast = currentValue;
        let tempcur = currentValue.replace(/[\/\-\+()]/, "");
        while (tempcur !== temppast) {
          temppast = temppast.replace(/[\/\-\+()]/, "");
          tempcur = tempcur.replace(/[\/\-\+()]/, "");
        }
        if (tempcur.substring(0, 1) === "1") {
          if (tempcur.substring(1, 2) === " ") {
            tempcur = tempcur.substring(2);
          } else {
            tempcur = tempcur.substring(1);
          }
        }

        //console.log("we ended", tempcur.substring(0, 1), tempcur, currentValue);
        let displaynumb = null;
        if (tempcur.length <= 3) {
          if (tempcur === "") {
            displaynumb = "";
          } else {
            displaynumb = `+1 (${tempcur})`;
          }

          //console.log("a", displaynumb);
        } else if (tempcur.length <= 6) {
          displaynumb = `+1 (${tempcur.substring(0, 3)})-${tempcur.substring(
            3,
            6
          )}`;
          //console.log("b", displaynumb);
        } else if (tempcur.length <= 10) {
          displaynumb = `+1 (${tempcur.substring(0, 3)})-${tempcur.substring(
            3,
            6
          )}-${tempcur.substring(6)}`;
          //console.log("c", displaynumb);
        }
        //console.log("d", displaynumb);
        //else if(tempcur.len)
        let validatorRegExp = new RegExp(
          /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/
        ); //only works for US numbers
        // console.log(
        //   "🚀 ~ file: Apperance.js ~ line 171 ~ Apperance ~ validatorRegExp",
        //   validatorRegExp
        // );

        if (validatorRegExp.test(displaynumb)) {
          return [id, displaynumb, false];
        } else {
          return [id, displaynumb, true];
        }
      }
      case "hotelemail": {
        let validatorRegExp = new RegExp(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        );
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
      case "hotel_zip_code": {
        let validatorRegExp = new RegExp(/^\d{5}(-\d{4})?$/); ///^\d{5}(-\d{4})?$/
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
      case "hotelinsta":
      case "hoteltwitter":
      case "hotelfblink": {
        let validatorRegExp = new RegExp(
          /^(https)?(:)?(\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
        );
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
      case "hotel_town":
      case "hotel_state": {
        let validatorRegExp = new RegExp(/^[a-zA-Z\s]+$/);
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
      case "hotel_min_price": {
        let validatorRegExp = new RegExp(/^[0-9]+$/);
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
      default: {
        let validatorRegExp = new RegExp(/^[a-zA-Z0-9\s]+$/);
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
    }
  };

  onBasicInfo_Change = (e) => {
    //console.log("change", e.target.id);
    let tempvar = e.target;
    //console.log(tempvar);
    let validated = this.basicValidation_Text(tempvar.id, tempvar.value);
    //console.log(validated);
    this.setState((prevState) => {
      const list = prevState.hotel_basicinfo;
      list[tempvar.id] = validated[1];
      const listvalidator = prevState.basic_info_validation;
      listvalidator[tempvar.id] = validated[2];
      if (validated[2] === false) {
        const list_edited = prevState.data_changed;
        list_edited[tempvar.id] = validated[1];
        return {
          hotel_basicinfo: list,
          data_changed: list_edited,
          basic_info_validation: listvalidator,
          isBlocking: true,
        };
      } else {
        return {
          hotel_basicinfo: list,
          basic_info_validation: listvalidator,
          isBlocking: true,
        };
      }
    });
  };
  data_determination = () => {
    //console.log(this.state.temp_logo_URL === "");
    if (this.state.temp_logo_URL !== "") {
      return {
        updates: this.state.data_changed,
        hotel_logo: this.state.temp_logo_URL,
        hotel_webid: this.state.hotel_basicinfo.hotel_web_id,
      };
    } else {
      return {
        updates: this.state.data_changed,
        hotel_webid: this.state.hotel_basicinfo.hotel_web_id,
      };
    }
  };

  onSaveData = () => {
    // console.log(
    //   "fater chanhe",
    //   this.state.data_changed,
    //   this.data_determination()
    // );

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/v1/updateBasicInfo"
          : "https://apiv1.reserv4me.com/v1/updateBasicInfo",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: this.data_determination(),
      withCredentials: true,
    })
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.success === true) {
          //console.log("we are updated", resp.data.hotel_logo_latest);
          this.setState(
            () => {
              return InitialState;
            },
            () => {
              this.LoadinforBasicData();
              if (resp.data.hotel_logo_latest !== undefined) {
                window.sessionStorage.setItem(
                  "hotel_logo",
                  resp.data.hotel_logo_latest
                );
              }

              this.setState(() => {
                return { saveSettings_Modal_Show: true };
              });
            }
          );
        }
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  handleCloseModal_Sucess = () => {
    this.setState((prevState) => {
      return {
        saveSettings_Modal_Show: !prevState.saveSettings_Modal_Show,
      };
    });
  };

  render() {
    return (
      <>
        <div
          className="flex justify-end "
          hidden={this.state.isBlocking === true ? false : true}
        >
          <button
            onClick={this.onSaveData}
            className="font-normal  bg-blue-500 text-white p-2 rounded inline  "
          >
            Save Changes
          </button>
        </div>
        <div className="">
          <h3 className="text-center shadow-divs">Basic Information</h3>
        </div>
        <div className="pt-4">
          <Basic_Info
            isBlocking={this.state.isBlocking}
            onLogoImgSelected={this.onLogoImgSelected}
            selectedFiles={this.state.hotel_logo}
            new_hotel_logo={this.state.temp_logo_URL}
            file_too_Large={this.state.file_too_Large}
            basic_info={this.state.hotel_basicinfo}
            onBasicInfo_Change={this.onBasicInfo_Change}
            basic_info_validation={this.state.basic_info_validation}
            hotel_logo_verification={this.state.hotel_logo_verification}
            basic_is_verified={this.state.basic_is_verified}
          />
        </div>
        <Prompt
          when={this.state.isBlocking}
          message={() => `Are you sure you want to leave without saving?`}
        />
        <Modal_Success_Updates
          show={this.state.saveSettings_Modal_Show}
          handleClose={this.handleCloseModal_Sucess}
        />
      </>
    );
  }
}

export default withRouter(Apperance);
