import React from "react";

function FieldGroup({
  validationState,
  id,
  mdsize,
  label,
  help,
  type,
  isInvalid,
  disabled,
  ...props
}) {
  return (
    <div className="">
      {type === "checkbox" ? (
        <div className="space-x-2 ">
          <input
            id={id}
            type={type}
            {...props}
            disabled={disabled}
            className="space-x-2"
          />
          <label>{label}</label>
        </div>
      ) : (
        <div>
          <label>{label}</label>
          <input
            id={id}
            type={type}
            {...props}
            disabled={disabled}
            className={`form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner ${
              disabled === true ? "bg-gray-300" : "bg-white"
            }`}
          />
        </div>
      )}

      <p
        className="text-red-500 text-sm mt-2"
        hidden={isInvalid === false ? true : false}
      >
        {help}
      </p>
    </div>
  );
}

class Additional_Info extends React.Component {
  render() {
    const {
      onPolicyChange,
      policy_info,
      onAddDiscounts,
      adding_discounts,
      discount_templist,
      onDiscountChange,
      discount_temp_valid,
      empty_discount,
      discounts_list,
      policy_info_validation,
      policy_late_verified,
      stage_policies_disc_verified,
    } = this.props;

    return (
      <>
        <div>
          <h1 className="text-lg">{`Policies & Charges`}</h1>
          <div className="space-y-2">
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <div className="grid grid-flow-row">
                <p>Do you want to use Later Payment Set up?</p>
                <div className="flex justify-between px-12">
                  <div>
                    <input
                      id="later_payment"
                      type="radio"
                      name="paymetlater"
                      value={"true"}
                      checked={policy_info.later_payment === "true"}
                      onChange={onPolicyChange}
                    />
                    <label htmlFor="paymetlater_yes">Yes</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="paymetlater"
                      value={"false"}
                      checked={policy_info.later_payment === "false"}
                      onChange={onPolicyChange}
                      id="later_payment"
                    />
                    <label htmlFor="paymetlater_no">No</label>
                  </div>
                </div>
              </div>
              <FieldGroup
                id="payment_schedule_date"
                label="Later Set up payment Schedule Date prior to arrival"
                type="text"
                placeholder="Enter in round numbers the amount of days prior to a payment"
                value={policy_info.payment_schedule_date}
                onChange={onPolicyChange}
                disabled={policy_info.later_payment === "false" ? true : false}
                isInvalid={
                  policy_late_verified === false
                    ? false
                    : policy_info.later_payment === "false"
                    ? false
                    : policy_info_validation.payment_schedule_date
                }
                help={`Please enter a number greater than 1 day this will determine
              how many days in advance does a guest have to put a down
              payment`}
              />
            </div>
            <div className="grid grid-flow-row ">
              <h1>{`Discounts`}</h1>
              <div className="flex flex-wrap gap-4 items-center justify-between">
                <p>
                  Here you can add discounts that are used internally as well as
                  offers for lower prices for longer stays for example
                  reservations for more than 1 week long
                </p>

                <button
                  onClick={() => {
                    onAddDiscounts();
                  }}
                  className="
                                lg:order-1
                                order-2
                                px-6
                                py-2.5
                                bg-blue-600
                                text-white
                                font-medium
                                text-xs
                                leading-tight
                                uppercase
                                rounded
                                shadow-md
                                hover:bg-blue-700 hover:shadow-lg
                                focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                                active:bg-blue-800 active:shadow-lg
                                transition
                                duration-150
                                ease-in-out
                                ml-1 "
                >
                  {adding_discounts === true ? "Save" : "Add Discount"}
                </button>
              </div>
            </div>
            <div className="grid grid-flow-col grid-cols-4 gap-4">
              <span>Discount Name</span>
              <span>Discount %</span>
              <span>Available Online</span>
              <span>Applicable if stay longer than</span>
            </div>
            <div
              className="grid grid-flow-col grid-cols-4 gap-4"
              hidden={!adding_discounts}
            >
              <FieldGroup
                id="discount_name"
                type="text"
                placeholder="Enter Discount Name"
                value={discount_templist.discount_name}
                onChange={(e) => {
                  onDiscountChange(e);
                }}
                isInvalid={
                  empty_discount === true &&
                  discount_temp_valid.discount_name === false
                }
                help={`Please Fill in the Field`}
              />
              <FieldGroup
                id="discount_rate"
                type="text"
                placeholder="Enter Discount Rate in %"
                value={discount_templist.discount_rate}
                onChange={(e) => {
                  onDiscountChange(e);
                }}
                isInvalid={
                  discount_templist.discount_rate === "" &&
                  empty_discount === false
                    ? false
                    : !discount_temp_valid.discount_rate
                }
                help="Please Fill in the Field using a number in the format XXX.XX"
              />
              <FieldGroup
                id="available_online"
                type="checkbox"
                label="Online Only"
                value={discount_templist.available_online}
                onChange={(e) => {
                  onDiscountChange(e);
                }}
                isInvalid={
                  empty_discount &&
                  discount_temp_valid.available_online === false
                }
                checked={discount_templist.available_online}
                help="Please Fill in the Field"
              />
              <FieldGroup
                id="applicable_if_stay_longer"
                type="text"
                placeholder="Enter # of minimum stay"
                value={discount_templist.applicable_if_stay_longer}
                onChange={(e) => {
                  onDiscountChange(e);
                }}
                isInvalid={
                  discount_templist.applicable_if_stay_longer === "" &&
                  empty_discount === false
                    ? false
                    : !discount_temp_valid.applicable_if_stay_longer

                  /* empty_discount &&
              discount_temp_valid.applicable_if_stay_longer === false*/
                }
                help="Please enter a round number which will determine the
              minimum night requirement to qualify for this discount"
              />
            </div>

            <div className="grid grid-flow-row">
              {discounts_list.map((current, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="grid grid-flow-col grid-cols-4 gap-4"
                    >
                      <div>{current.discount_name}</div>
                      <div>{current.discount_rate}</div>
                      <div>
                        {current.available_online === true ? "YES" : "NO"}
                      </div>
                      <div>{current.applicable_if_stay_longer}</div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Additional_Info;
