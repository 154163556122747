import React from "react";
import { Bar, Pie } from "react-chartjs-2";
import { withRouter } from "react-router-dom";
import axios from "axios";
import "./Daily_Demand_Chart.css";
import moment from "moment";
import SideBar from "../Header Menu/SideBar";
import Header from "../Header Menu/Header";
import Flatpickr from "react-flatpickr";

const InitialState = {};

class Daily_Demand_Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed_daily_payment: {},
      displayed_data: {},
      startdate: moment(),
      enddate: moment().add(7, "days"),
      addingdate: false,
      shouldClose: false,
      sidebarOpen: false,
    };
  }

  componentDidMount() {
    this.read_daily_demand();
    this.read_payment_daily();
  }

  read_daily_demand = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/daily-demand"
          : "https://apiv1.reserv4me.com/daily-demand",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        fromdate: moment(this.state.startdate),
        todate: moment(this.state.enddate),
      },
      withCredentials: true,
    })
      .then((response) => {
        //console.log(response.data);
        this.setState((prevState) => {
          return {
            displayed_data: {
              labels: response.data.labels,
              datasets: response.data.datasets,
            },
          };
        });
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });
  };
  read_payment_daily = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/v1/payment_daily"
          : "https://apiv1.reserv4me.com/v1/payment_daily",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        fromdate: moment(this.state.startdate).format("MM/DD/YYYY"),
        todate: moment(this.state.enddate).format("MM/DD/YYYY"),
      },
      withCredentials: true,
    })
      .then((response) => {
        //console.log(response.data);
        this.setState((prevState) => {
          return {
            displayed_daily_payment: {
              labels: response.data.labels,
              datasets: response.data.datasets,
            },
          };
        });
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });
  };

  routeController = (e, path) => {
    switch (path) {
      case "mainmenu": {
        this.props.history.push({
          pathname: `/mainmenu`,
        });
        break;
      }
    }
  };

  onDateChange = (value) => {
    //const [start, end] = value;
    //console.log(value);
    if (this.state.addingdate === false) {
      this.setState(
        (prevState) => {
          return {
            startdate: value,
            enddate: value,
            addingdate: true,
            shouldClose: true,
          };
        },
        () => {
          //this.read_daily_demand();
        }
      );
    } else {
      this.setState(
        (prevState) => {
          return {
            enddate: value,
            addingdate: false,
            shouldClose: false,
          };
        },
        () => {
          this.read_daily_demand();
          this.read_payment_daily();
        }
      );
    }
  };

  setSidebarOpen = () => {
    this.setState((prevState) => ({ sidebarOpen: !prevState.sidebarOpen }));
  };
  onDateSelectionChange = (CIdate, COdate) => {
    this.setState(
      (prevState) => ({
        startdate: CIdate,
        enddate: COdate,
        rowData: [],
        columnDefs: InitialState.columnDefs,
        rowClassRules: InitialState.rowClassRules,
      }),
      () => {
        this.read_daily_demand();
        this.read_payment_daily();
      }
    );
  };

  render() {
    return (
      <>
        <div className="flex flex-col min-h-screen overflow-hidden ">
          <SideBar
            sidebarOpen={this.state.sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
          />
          <div className="absolute flex flex-col flex-1 overflow-y-auto overflow-x-hidden w-full">
            <Header
              sidebarOpen={this.state.sidebarOpen}
              setSidebarOpen={this.setSidebarOpen}
              userName={window.sessionStorage.getItem("uname")}
            />
            <main className=" ">
              <div className="flex flex-wrap gap-4 p-4">
                <div className="rounded border shadow-3xl w-screen">
                  <div className="flex items-center space-x-2 p-2">
                    <span>Dates:</span>
                    <div className="relative  ">
                      <Flatpickr
                        value={[
                          this.state.startdate.toDate(),
                          this.state.enddate.toDate(),
                        ]}
                        className="form-input  w-64 pl-9 text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
                        options={{
                          mode: "range",
                          static: true,
                          monthSelectorType: "static",
                          dateFormat: "M j, Y",
                          defaultDate: [
                            this.state.startdate.toDate(),
                            this.state.enddate.toDate(),
                          ],
                          prevArrow:
                            '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
                          nextArrow:
                            '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
                        }}
                        onReady={(selectedDates, dateStr, instance) => {
                          instance.element.value = dateStr.replace("to", "-");
                          instance.calendarContainer.classList.add(
                            `flatpickr-right`
                          );
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          if (selectedDates.length === 1) {
                            //this.date_CI_change(moment(selectedDates[0]));
                          } else {
                            //this.date_CI_change(moment(selectedDates[0]));
                            //this.date_CO_change(moment(selectedDates[1]));
                            this.onDateSelectionChange(
                              moment(selectedDates[0]),
                              moment(selectedDates[1])
                            );
                          }
                        }}
                        onClose={(selectedDates, dateStr, instance) => {
                          if (selectedDates.length == 1) {
                            // console.log(
                            //   "🚀 ~ file: Main_Modify_res.js ~ line 2449 ~ Modify_res ~ render ~ selectedDates",
                            //   selectedDates[0]
                            // );
                            // this.date_CO_change(
                            //   moment(selectedDates[0]).add(1, "d")
                            // );
                            this.onDateSelectionChange(
                              moment(selectedDates[0]),
                              moment(selectedDates[0]).add(1, "d")
                            );
                            instance.setDate([
                              selectedDates[0],
                              moment(selectedDates[0]).add(1, "d").toDate(),
                              true,
                            ]);
                            instance.element.value = dateStr.replace("to", "-");
                          }
                        }}
                      />
                      <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
                        <svg
                          className="w-4 h-4 fill-current text-gray-500 ml-3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="h-96 p-4">
                    <Bar
                      data={this.state.displayed_data}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        title: { display: true, text: "Demand" },
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true,
                              },
                            },
                          ],
                        },
                      }}
                      getElementAtEvent={(event) => {
                        //console.log(event);
                      }}
                    />
                  </div>
                </div>

                <div className=" h-96 w-screen md:w-2/4 rounded shadow-3xl p-4">
                  <Bar
                    data={this.state.displayed_daily_payment}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      title: { display: true, text: "Daily Payment total" },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                    getElementAtEvent={(event) => {
                      //console.log(event);
                    }}
                  />
                </div>
              </div>
            </main>
          </div>
        </div>

        {/*
          <Bar
          width={100}
          height={100}
          data={this.state.displayed_data}
          options={{ maintainAspectRatio: false }}
          getElementAtEvent={(event) => {
            //console.log(event);
          }}
        />
        <Button onClick={this.read_daily_demand}>TEst</Button>
        */}
      </>
    );
  }
}

export default withRouter(Daily_Demand_Chart);

// <Row>
//                 <Col xs={12}>
//                   <Row>
//                     <Col>
//                       <p>Report Overview</p>
//                     </Col>
//                     <Col>
//                       <Button onClick={this.read_payment_daily}>Print</Button>
//                     </Col>
//                     <Col>
//                       <Button onClick={this.read_daily_demand}>
//                         Read Demand
//                       </Button>
//                     </Col>
//                     <Col>
//                       <Button
//                         onClick={(e) => {
//                           this.routeController(e, "mainmenu");
//                         }}
//                       >
//                         <HomeIcon />
//                       </Button>
//                     </Col>
//                     <Col>
//                       <p>{`Logged in as: ${window.sessionStorage.getItem(
//                         "uname"
//                       )}`}</p>
//                     </Col>
//                   </Row>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col xs={12} className="bar_height">
//                   <Row className="outline">
//                     <Col xs={2}>Reserve Now Clicks</Col>
//                     <Col xs={2}>
//                       <DatePicker
//                         selected={this.state.startdate}
//                         startDate={this.state.startdate}
//                         endDate={this.state.enddate}
//                         onChange={this.onDateChange}
//                         selectsEnd
//                         shouldCloseOnSelect={this.state.shouldClose}
//                         customInput={
//                           <Button
//                             variant="primary"
//                             onClick={this.onbtnDateClick}
//                           >{`${this.state.startdate.format(
//                             "MM/DD/YYYY"
//                           )} - ${this.state.enddate.format(
//                             "MM/DD/YYYY"
//                           )}`}</Button>
//                         }
//                       />
//                     </Col>
//                   </Row>
//                   <Row className="container-chart">
//                     <Col>
//                       <div className=" container-chart-height outline">
//                         <Bar
//                           data={this.state.displayed_data}
//                           options={{
//                             maintainAspectRatio: false,
//                             responsive: true,
//                             title: { display: true, text: "Demand" },
//                             scales: {
//                               yAxes: [
//                                 {
//                                   ticks: {
//                                     beginAtZero: true,
//                                   },
//                                 },
//                               ],
//                             },
//                           }}
//                           getElementAtEvent={(event) => {
//                             //console.log(event);
//                           }}
//                         />
//                       </div>
//                     </Col>
//                   </Row>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col xs={6} className="bar_height">
//                   <Pie
//                     data={{
//                       labels: [
//                         "Red",
//                         "Blue",
//                         "Yellow",
//                         "Green",
//                         "Purple",
//                         "Orange",
//                       ],
//                       datasets: [
//                         {
//                           label: "# of Votes",
//                           data: [12, 19, 3, 5, 2, 3],
//                           backgroundColor: [
//                             "rgba(255, 99, 132, 0.2)",
//                             "rgba(54, 162, 235, 0.2)",
//                             "rgba(255, 206, 86, 0.2)",
//                             "rgba(75, 192, 192, 0.2)",
//                             "rgba(153, 102, 255, 0.2)",
//                             "rgba(255, 159, 64, 0.2)",
//                           ],
//                           borderColor: [
//                             "rgba(255, 99, 132, 1)",
//                             "rgba(54, 162, 235, 1)",
//                             "rgba(255, 206, 86, 1)",
//                             "rgba(75, 192, 192, 1)",
//                             "rgba(153, 102, 255, 1)",
//                             "rgba(255, 159, 64, 1)",
//                           ],
//                           borderWidth: 1,
//                         },
//                       ],
//                     }}
//                     options={{
//                       maintainAspectRatio: false,
//                       responsive: true,
//                       title: { display: true, text: "Total # of Rooms Sold" },
//                       scales: {
//                         yAxes: [
//                           {
//                             ticks: {
//                               beginAtZero: true,
//                             },
//                           },
//                         ],
//                       },
//                     }}
//                     getElementAtEvent={(event) => {
//                       //console.log(event);
//                     }}
//                   />{" "}
//                 </Col>
//                 <Col xs={6} className="bar_height">
//                   <Bar
//                     data={this.state.displayed_daily_payment}
//                     options={{
//                       maintainAspectRatio: false,
//                       responsive: true,
//                       title: { display: true, text: "Daily Payment total" },
//                       scales: {
//                         yAxes: [
//                           {
//                             ticks: {
//                               beginAtZero: true,
//                             },
//                           },
//                         ],
//                       },
//                     }}
//                     getElementAtEvent={(event) => {
//                       //console.log(event);
//                     }}
//                   />
//                 </Col>
//               </Row>
