import React from "react";
import axios from "axios";
import { Prompt, withRouter } from "react-router-dom";
import Room_Image_Preview from "../Sign_up_page/Room_Image_Preview";
import Modal_Shell from "../Modal Skeleton/Modal_Shell";

const InitialState = {
  isBlocking: false,
  saveSettings_Modal_Show: false,
  current_room_Selected: 0,
  room_type_list: [],
  edited_images_details: [],
  disable_new_room_selection: false,
  show_disabled_roomChange: false,
  changes_happened: false,
  hotel_web_id: 0,
};
function FieldGroup({
  validationState,
  id,
  label,
  help,
  type,
  options,
  disabled,
  readonly,
  ...props
}) {
  return (
    <div className=" ">
      {/* <label
        className="block text-black text-sm font-medium mb-1"
        htmlFor={label}
      >
        {label} <span className="text-red-600">*</span>
      </label> */}

      {type === "textarea" ? (
        <textarea
          id={id}
          {...props}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner "
        ></textarea>
      ) : type === "select" ? (
        <select
          id={id}
          {...props}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
        >
          {options()}
        </select>
      ) : (
        <input
          id={id}
          type={type}
          disabled={disabled}
          className={`form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner ${
            disabled === true ? "bg-gray-300" : "bg-white"
          }`}
          readOnly={readonly}
          {...props}
        />
      )}

      {type === "textarea" ? (
        <p hidden={validationState} className=" text-sm mt-2 flex justify-end">
          {help}
        </p>
      ) : (
        <p hidden={validationState} className="text-red-500 text-sm mt-2">
          {help}
        </p>
      )}
    </div>
  );
}

class Room_Editing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBlocking: false,
      saveSettings_Modal_Show: false,
      current_room_Selected: 0,
      room_type_list: [],
      edited_images_details: [],
      disable_new_room_selection: false,
      show_disabled_roomChange: false,
      changes_happened: false,
      hotel_web_id: 0,
      file_too_Large: false,
      updated_image: "",
      show_success_data: false,
    };
  }

  componentDidMount() {
    this.getRoomData();
  }

  getRoomData = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/v1/getRoomInfo"
          : "https://apiv1.reserv4me.com/v1/getRoomInfo",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {},
      withCredentials: true,
    })
      .then((result) => {
        this.setState((prevState) => ({
          room_type_list: result.data.room_Info,
          hotel_web_id: result.data.hotel_web_id,
        }));
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  current_Room_selected = (e) => {
    if (this.state.disable_new_room_selection === true) {
      this.close_disable_room_change();
    } else {
      let tempvar = e.currentTarget;
      ////console.log("roomselected",this.state.current_room_Selected,tempvar.getAttribute("tabIndex"))
      this.setState(
        (prevState) => ({
          current_room_Selected: parseInt(tempvar.getAttribute("tabIndex")),
        }),
        () => {
          ////console.log("roomselected",this.state.current_room_Selected,tempvar.getAttribute("tabIndex"))
        }
      );
      ////console.log("item",)
    }
  };

  onImgDescriptionChange = (e) => {
    let tempvar = e.target;
    // //console.log("testval",tempvar)

    this.setState((prevState) => {
      const image_desc = prevState.room_type_list;
      image_desc[prevState.current_room_Selected].photourls[
        parseInt(tempvar.id.split("-")[0])
      ][
        tempvar.id.split("-")[1] === "title"
          ? "photoname"
          : tempvar.id.split("-")[1] === "description"
          ? "photodescription"
          : "any"
      ] = tempvar.value;
      const img_edit = prevState.edited_images_details;
      let test_existance = prevState.edited_images_details.findIndex(
        (element) => element.index === tempvar.id.split("-")[0]
      );

      if (test_existance === -1) {
        img_edit[img_edit.length] = {
          index: tempvar.id.split("-")[0],
          photoname:
            image_desc[prevState.current_room_Selected].photourls[
              parseInt(tempvar.id.split("-")[0])
            ]["photoname"],
          photodescription:
            image_desc[prevState.current_room_Selected].photourls[
              parseInt(tempvar.id.split("-")[0])
            ]["photodescription"],
          hotelphotoid:
            image_desc[prevState.current_room_Selected].photourls[
              parseInt(tempvar.id.split("-")[0])
            ]["hotelphotoid"],
        };
        return {
          room_type_list: image_desc,
          edited_images_details: img_edit,
          isBlocking: true,
          disable_new_room_selection: true,
          changes_happened: true,
        };
      } else {
        img_edit[test_existance]["photoname"] =
          image_desc[prevState.current_room_Selected].photourls[
            parseInt(tempvar.id.split("-")[0])
          ]["photoname"];
        img_edit[test_existance]["photodescription"] =
          image_desc[prevState.current_room_Selected].photourls[
            parseInt(tempvar.id.split("-")[0])
          ]["photodescription"];
        img_edit[test_existance]["hotelphotoid"] =
          image_desc[prevState.current_room_Selected].photourls[
            parseInt(tempvar.id.split("-")[0])
          ]["hotelphotoid"];
        return {
          room_type_list: image_desc,
          edited_images_details: img_edit,
          isBlocking: true,
          disable_new_room_selection: true,
          changes_happened: true,
        };
      }
    });
  };

  close_disable_room_change = () => {
    this.setState((prevState) => ({
      show_disabled_roomChange: !prevState.show_disabled_roomChange,
    }));
  };
  handle_success_Modal = () => {
    this.setState((prevState) => ({
      show_success_data: !prevState.show_success_data,
    }));
  };

  save_updated_room_data = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/v1/updateImageDetails"
          : "https://apiv1.reserv4me.com/v1/updateImageDetails",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        edited_images_details: this.state.edited_images_details,
        hotel_web_id: this.state.hotel_web_id,
      },
      withCredentials: true,
    })
      .then((result) => {
        if (result.data.success === true) {
          this.setState(
            (prevState) => ({
              ...InitialState,
            }),
            () => {
              this.getRoomData();
              this.handle_success_Modal();
            }
          );
        }
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  onEdit_button_pressed = (e) => {
    let tempvar = e.target;

    switch (tempvar.id.split("-")[2]) {
      case "delete": {
        axios({
          method: "post",
          url:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/v1/deleteImage"
              : "https://apiv1.reserv4me.com/v1/deleteImages",
          headers: {
            "Access-Control-Allow-Origin":
              process.env.NODE_ENV === "development"
                ? "http://localhost:3000"
                : "https://apiv1.reserv4me.com",
            "Content-Type": "application/json",
            Authorization: "internalvar.apikey",
          },
          data: {
            hotel_web_id: this.state.hotel_web_id,
            hotelphotoid: parseInt(tempvar.id.split("-")[1]),
          },
          withCredentials: true,
        })
          .then((result) => {
            if (result.data.success === true) {
              this.setState(
                (prevState) => ({
                  ...InitialState,
                }),
                () => {
                  this.getRoomData();
                  this.handle_success_Modal();
                }
              );
            } else {
              return null;
            }
          })
          .catch((error) => {
            this.props.history.push({
              pathname: `/`,
            });
          });
        break;
      }
      case "replace": {
        const reader = new FileReader();
        let tempFiles = Array.from(e.target.files);

        if (tempFiles[0].size < 5e6) {
          //console.log("selected", tempFiles)
          reader.onload = () => {
            if (reader.readyState === 2) {
              // console.log(reader.result);
              this.setState(
                {
                  updated_image: reader.result,
                  file_too_Large: false,
                  isBlocking: true,
                },
                () => {
                  axios({
                    method: "post",
                    url:
                      process.env.NODE_ENV === "development"
                        ? "http://localhost:3000/v1/replaceImage"
                        : "https://apiv1.reserv4me.com/v1/replaceImage",
                    headers: {
                      "Access-Control-Allow-Origin":
                        process.env.NODE_ENV === "development"
                          ? "http://localhost:3000"
                          : "https://apiv1.reserv4me.com",
                      "Content-Type": "application/json",
                      Authorization: "internalvar.apikey",
                    },
                    data: {
                      hotel_web_id: this.state.hotel_web_id,
                      hotelphotoid: parseInt(tempvar.id.split("-")[1]),
                      hotel_image: this.state.updated_image,
                    },
                    withCredentials: true,
                  })
                    .then((result) => {
                      if (result.data.success === true) {
                        this.setState(
                          (prevState) => ({
                            ...InitialState,
                          }),
                          () => {
                            this.getRoomData();
                            this.handle_success_Modal();
                          }
                        );
                      } else {
                        return null;
                      }
                    })
                    .catch((error) => {
                      this.props.history.push({
                        pathname: `/`,
                      });
                    });
                }
              );
            }
          };

          reader.readAsDataURL(e.target.files[0]);
        } else {
          ////console.log("Please select a file that is less than 100KB in size for your logo")
          this.setState({
            file_too_Large: true,
          });
        }
      }
      default: {
        return null;
      }
    }
  };

  render() {
    // console.log(
    //   "🚀 ~ file: Room_Editing.js ~ line 237 ~ Room_Editing ~ render ~ render",
    //   this.state
    // );

    if (this.state.room_type_list.length === 0) {
      return (
        <div className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  ">
          <div className="loading"></div>
          <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
            <p className="text-2xl text-white -translate-y-16">Loading...</p>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="space-y-2">
            <div className="">
              <h3 className="text-center shadow-divs">Room(s) and Images</h3>
            </div>

            <div className="flex justify-end pt-2">
              <button
                hidden={this.state.changes_happened === true ? false : true}
                className="font-normal  bg-blue-500 text-white p-2 rounded inline  "
                onClick={this.save_updated_room_data}
              >
                Save Changes
              </button>
            </div>

            <div className="grid grid-flow-col grid-cols-3 gap-4">
              <p>Room Type Name</p>
              <p>Max Occupancy</p>
              <p>Base Occupancy</p>
            </div>

            <div className="grid grid-flow-col grid-cols-3 gap-4 pb-4">
              <FieldGroup
                id="room_type_name"
                type="text"
                placeholder="Enter Room Type Name"
                autoFocus
                value={
                  this.state.room_type_list[this.state.current_room_Selected]
                    .roomtype
                }
                readonly={true}
                // onChange={(e) => {
                //   onRoomTypeChange(e);
                // }}
                // validationState={
                //   room_type_temp_list.room_type_name === ""
                //     ? true
                //     : room_type_temp_valid.room_type_name
                // }
                help={`Please Fill in the Field`}
              />

              <FieldGroup
                id="max_occupancy"
                type="text"
                placeholder="Enter Max Occupancy"
                value={
                  this.state.room_type_list[this.state.current_room_Selected]
                    .maxoccupancy
                }
                // onChange={(e) => {
                //   onRoomTypeChange(e);
                // }}
                // validationState={
                //   room_type_temp_list.max_occupancy === ""
                //     ? true
                //     : room_type_temp_valid.max_occupancy
                // }
                help={`Please Fill in the Field`}
              />
              <FieldGroup
                id="base_occupancy"
                type="text"
                placeholder="Enter Base Occupancy"
                value={
                  this.state.room_type_list[this.state.current_room_Selected]
                    .baseoccupancy
                }
                // onChange={(e) => {
                //   onRoomTypeChange(e);
                // }}
                // validationState={
                //   room_type_temp_list.base_occupancy === ""
                //     ? true
                //     : room_type_temp_valid.base_occupancy
                // }
                help={`Please Fill in the Field`}
              />
            </div>
            <div className="flex flex-wrap w-full h-112 overflow-y-scroll scrollbar-blue-500 gap-4">
              {(() => {
                if (this.state.room_type_list.length === 0) {
                  return <></>;
                } else {
                  ////console.log("sellll",this.props.preview_selected_Images)
                  return this.state.room_type_list[
                    this.state.current_room_Selected
                  ].photourls.map((current, index) => {
                    //console.log("cur", current);
                    return (
                      /*<Col className="image_room--container"><img src={current} className="image_logo"/></Col>*/
                      <div className="mx-auto">
                        <Room_Image_Preview
                          imgsrc={current.photourl}
                          description={current.photodescription}
                          title={current.photoname}
                          index={index}
                          onImgDescriptionChange={this.onImgDescriptionChange}
                          photoID={current.hotelphotoid}
                          typeofpreview={"edit"}
                          editbutton_pressed={this.onEdit_button_pressed}
                        />
                      </div>
                    );
                  });
                }
              })()}
            </div>

            <div class="flex justify-center pt-4">
              <nav>
                <ul class="flex list-style-none">
                  {this.state.room_type_list.map((current, index) => {
                    return (
                      <>
                        <li
                          class={`page-item  rounded py-1  ${
                            this.state.current_room_Selected === index
                              ? "bg-blue-500 "
                              : this.state.disable_new_room_selection === true
                              ? ""
                              : "hover:bg-gray-200"
                          }`}
                        >
                          <a
                            tabIndex={index}
                            class={`no-underline hover:no-underline 
                            relative block py-1.5 px-3 border-0 bg-transparent 
                        outline-none transition-all duration-300 rounded ${
                          this.state.current_room_Selected === index
                            ? "text-white "
                            : this.state.disable_new_room_selection === true
                            ? "text-gray-500"
                            : "text-gray-800 "
                        } 
                        hover:text-gray-800  focus:shadow-none`}
                            onClick={this.current_Room_selected}
                          >
                            {index + 1}
                          </a>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>

          <Prompt
            when={this.state.isBlocking}
            message={() => `Are you sure you want to leave without saving?`}
          />
          <Modal_Shell
            handleClose={this.close_disable_room_change}
            show={this.state.show_disabled_roomChange}
            title={"Alert"}
            modalBody={
              <>
                <h5>
                  At the moment selection of other rooms is disbled until
                  changes are saved or discarded
                </h5>
              </>
            }
          />
          <Modal_Shell
            handleClose={this.handle_success_Modal}
            show={this.state.show_success_data}
            title={"Success"}
            modalBody={
              <>
                <h5>All the information was updated sucessfuly.</h5>
              </>
            }
          />
        </>
      );
    }
  }
}

export default withRouter(Room_Editing);
