import React from "react";

function FieldGroup({
  validationState,
  id,
  label,
  help,
  type,
  options,
  disabled,
  ...props
}) {
  return (
    <div className=" ">
      {/* <label
        className="block text-black text-sm font-medium mb-1"
        htmlFor={label}
      >
        {label} <span className="text-red-600">*</span>
      </label> */}

      {type === "textarea" ? (
        <textarea
          id={id}
          {...props}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner "
        ></textarea>
      ) : type === "select" ? (
        <select
          id={id}
          {...props}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
        >
          {options()}
        </select>
      ) : (
        <input
          id={id}
          type={type}
          disabled={disabled}
          className={`form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner ${
            disabled === true ? "bg-gray-300" : "bg-white"
          }`}
          {...props}
        />
      )}

      {type === "textarea" ? (
        <p hidden={validationState} className=" text-sm mt-2 flex justify-end">
          {help}
        </p>
      ) : (
        <p hidden={validationState} className="text-red-500 text-sm mt-2">
          {help}
        </p>
      )}
    </div>
  );
}

class ReceivedBy_info extends React.Component {
  render() {
    const {
      adding_received_by,
      onAddChannel,
      channel_type_list,
      channel_type_temp_valid,
      channel_type_temp,
      empty_channel_type,
      onChannelAddChange,
      channel_initial_change,
      channel_is_verified,
    } = this.props;

    return (
      <>
        <div className="">
          <h1 className="text-lg">{`Rooms & Rates`}</h1>
          <p className="shadow-div">
            In the following you need to fill in the name of the channel through
            which you expect to get reservations, for example by phone you can
            write down Phone, this will be a way to diferenciate which channel
            your customers are using.By default you will start with at least 2
            channels Reserv4me and Reserv4me-Setup (if you are pressed yes on
            the previous page for Later Set up payments), Now please type any
            method you expect your reservations to come in,
            Walk-in,Phone,Email,Any other online source.
          </p>
          <div className="space-y-2">
            <div className="flex justify-between pt-4">
              <p
                className="text-red-500"
                hidden={
                  channel_is_verified === false
                    ? true
                    : channel_type_list.length === 0
                    ? false
                    : true
                }
              >
                Please add a name of a channel where reservations come through
              </p>
              <button
                onClick={onAddChannel}
                className="
                lg:order-1
                order-2
                px-6
                py-2.5
                bg-blue-600
                text-white
                font-medium
                text-xs
                leading-tight
                uppercase
                rounded
                shadow-md
                hover:bg-blue-700 hover:shadow-lg
                focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                active:bg-blue-800 active:shadow-lg
                transition
                duration-150
                ease-in-out
                ml-1 "
              >
                {adding_received_by === false ? `Add Channel` : "Save Channel"}
              </button>
            </div>
            <div className="grid grid-flow-col grid-cols-3 gap-4">
              <p>Channel Num</p>
              <p>Channel Name</p>
              <p>Quick Email</p>
            </div>
            <div
              className="grid grid-flow-col grid-cols-3 gap-4"
              hidden={!adding_received_by}
            >
              <FieldGroup
                id="channel_num"
                type="text"
                placeholder="Channel Number"
                autoFocus
                value={channel_type_temp.channel_num}
                onChange={(e) => {
                  onChannelAddChange(e);
                }}
                disabled={true}
              />
              <FieldGroup
                id="channel_name"
                type="text"
                placeholder="Enter Channel Name"
                value={channel_type_temp.channel_name}
                onChange={(e) => {
                  onChannelAddChange(e);
                }}
                validationState={
                  channel_type_temp_valid.channel_name === true ? true : false
                }
                disabled={false}
                help="Please Fill in the Field"
              />
              <FieldGroup
                id="channel_immediateness"
                type="select"
                value={channel_type_temp.channel_immediateness}
                onChange={(e) => {
                  onChannelAddChange(e);
                }}
                validationState={
                  channel_type_temp_valid.channel_immediateness === true
                    ? true
                    : false
                }
                options={() => {
                  return (
                    <>
                      <option key={`Immediateness-null`} value={0}>
                        Choose...
                      </option>
                      <option key={`Immediateness-0`} value={true}>
                        true
                      </option>
                      <option key={`Immediateness-1`} value={false}>
                        false
                      </option>
                    </>
                  );
                }}
                disabled={false}
                help="Please Fill in the Field"
              />
            </div>

            {channel_type_list.map((current, index) => {
              return (
                <>
                  <div
                    className="grid grid-flow-col grid-cols-3 gap-4"
                    key={index.toString()}
                  >
                    <div>{current.channel_num}</div>
                    <div>{current.channel_name}</div>
                    <div>{current.channel_immediateness}</div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default ReceivedBy_info;
