import React from "react";
import { Col, Row, Button } from "react-bootstrap";

class Inventory_Management extends React.Component {
  render() {
    const {
      latest_inv_date,
      room_types,
      addingNewRoom,
      addingroom,
      request_Inventory_Creation,
    } = this.props;
    return (
      <>
        <div>
          <h3 className="text-center shadow-divs">Inventory Management</h3>
          <div className="flex pt-2 flex-wrap justify-end">
            <p className="">
              {`Your current inventory exist up to ${latest_inv_date} to create additional
                inventory you can click the button below to submit a request to
                create inventory for up to 2 years at a time`}
            </p>
            <button
              onClick={request_Inventory_Creation}
              className="font-normal bg-blue-500 text-white p-2 rounded  whitespace-nowrap "
            >
              Request Creation
            </button>
          </div>
          <p className="text-xl pb-2">Current room types in existance:</p>
          <div className="grid grid-flow-col grid-cols-3">
            <p>Room Types</p>
            <p>Maximum Occupancy</p>
            <p>Base Occupancy</p>
          </div>
          {(() => {
            //console.log(room_types);
            if (room_types.length === 0) {
              return <p>No rooms exist</p>;
            } else {
              return room_types.map((current, index) => {
                return (
                  <div className="grid grid-flow-col grid-cols-3">
                    <p>{current.typeofr}</p>
                    <p>{current.maximumocup}</p>
                    <p>{current.baseoccupancy}</p>
                  </div>
                );
              });
            }
          })()}
          <div className="flex justify-end">
            <button
              onClick={addingNewRoom}
              className="font-normal bg-blue-500 text-white p-2 rounded  whitespace-nowrap "
            >
              Add New Type
            </button>
          </div>
          <div hidden={!addingroom}>
            <p>
              Adding a new room type will send a request to create a new room
              using the settings you will select below:
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Inventory_Management;

{
  /* <>
        <Row className="border_separation mainsettings_row">
          <Col className="">
            <h3>Inventory Management</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <p>
              {`Your current inventory exist up to ${latest_inv_date} to create additional
              inventory you can click the button below to submit a request to
              create inventory for up to 2 years at a time`}
            </p>
          </Col>
          <Col xs={4}>
            <Button variant="primary" onClick={request_Inventory_Creation}>
              Request Creation
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Current room types in existance:</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Room Types</p>
          </Col>
          <Col>
            <p>Maximum Occupancy</p>
          </Col>
          <Col>
            <p>Base Occupancy</p>
          </Col>
        </Row>
        {(() => {
          //console.log(room_types);
          if (room_types.length === 0) {
            return <p>No rooms exist</p>;
          } else {
            return room_types.map((current, index) => {
              return (
                <Row>
                  <Col>
                    <p>{current.typeofr}</p>
                  </Col>
                  <Col>
                    <p>{current.maximumocup}</p>
                  </Col>
                  <Col>
                    <p>{current.baseoccupancy}</p>
                  </Col>
                </Row>
              );
            });
          }
        })()}
        <Row>
          <Col xs={8}></Col>
          <Col xs={4}>
            <Button variant="primary" onClick={addingNewRoom}>
              Add New Type
            </Button>
          </Col>
        </Row>
        <Row hidden={!addingroom}>
          <Col>
            <p>
              Adding a new room type will send a request to create a new room
              using the settings you will select below:
            </p>
          </Col>
        </Row>
      </> */
}
