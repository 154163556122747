import React, { Fragment } from "react";

function FieldGroup({
  validationState,
  id,
  mdsize,
  label,
  help,
  type,
  isInvalid,
  ...props
}) {
  return (
    <div className="px-3">
      <label>{label}</label>

      <input
        id={id}
        type={type}
        {...props}
        className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
      />
      <p
        className="text-red-500 text-sm mt-2"
        hidden={isInvalid === false ? true : false}
      >
        {help}
      </p>
    </div>
  );
}

const Client_form_builder = ({
  client_info,
  onChange,
  fnameval,
  lnameval,
  phone_numval,
  email_addrval,
  onSubmit,
  channel_types,
}) => {
  return (
    <form id="Client_form" onSubmit={onSubmit}>
      <div className="grid grid-flow-col">
        <FieldGroup
          validationState="invalid"
          id="fname"
          required
          label={"First Name"}
          type="text"
          placeholder="First Name"
          isInvalid={fnameval}
          value={client_info.fname}
          onChange={onChange}
          help={"This field is required"}
        />
        <FieldGroup
          validationState="invalid"
          id="lname"
          required
          label={"Last Name"}
          type="text"
          placeholder="Last Name"
          isInvalid={lnameval}
          value={client_info.lname}
          onChange={onChange}
          help={"This field is required"}
        />
      </div>

      <div className="grid grid-flow-col">
        <FieldGroup
          validationState="invalid"
          id="phonenumb"
          required={true}
          label={"Phone Number"}
          type="text"
          placeholder="Phone Number"
          isInvalid={phone_numval}
          value={client_info.phone_num}
          onChange={onChange}
          help={"This field is required"}
        />
        <FieldGroup
          validationState="invalid"
          id="Email"
          required={false}
          label={"Email"}
          type="email"
          placeholder="Email"
          isInvalid={email_addrval}
          value={client_info.email}
          onChange={onChange}
          help={"This field is required"}
        />
      </div>
      <div className="grid grid-flow-col">
        <FieldGroup
          validationState="invalid"
          id="Street"
          required={false}
          label={"Address"}
          type="text"
          placeholder="Address eg.(123 Street Ave.)"
          isInvalid={false}
          //isValid={false}
          value={client_info.address}
          onChange={onChange}
        />
        <FieldGroup
          validationState="invalid"
          id="Cityname"
          required={false}
          label={"City"}
          type="text"
          placeholder="City eg.(Los Angeles)"
          isInvalid={false}
          //isValid={false}
          value={client_info.city}
          onChange={onChange}
        />
      </div>
      <div className="grid grid-flow-col">
        <FieldGroup
          validationState="invalid"
          id="clstate"
          required={false}
          label={"State"}
          type="text"
          placeholder="State eg.(New York, NJ)"
          isInvalid={false}
          //isValid={false}
          value={client_info.state}
          onChange={onChange}
        />
        <FieldGroup
          validationState="invalid"
          id="zip"
          required={false}
          label={"Zip"}
          type="text"
          placeholder="Zip code"
          isInvalid={false}
          //isValid={false}
          value={client_info.zipcode}
          onChange={onChange}
        />
        <div className="px-3 ">
          <label for={"Channel"}>{"Channel"}</label>
          <select
            className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
            validationState="invalid"
            id="channel"
            required={false}
            name={"Channel"}
            type="text"
            isInvalid={false}
            //isValid={false}
            value={client_info.channel}
            onChange={onChange}
          >
            {channel_types.map((elem, index) => {
              return (
                <option key={elem.receivedBy_id} value={elem.received_type}>
                  {elem.received_type}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </form>
  );
};

export default Client_form_builder;
