import React, { Fragment } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

class Grid_main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    ////console.log(this.props)
    return (
      <Fragment>
        <div
          className="ag-theme-balham"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <AgGridReact
            columnDefs={this.props.Columns}
            rowData={this.props.Rows}
            rowClassRules={this.props.rowClassRules}
            onSelectionChanged={this.props.onSelectionChanged}
            rowSelection={this.props.rowSelection}
            onGridReady={this.props.onGridReady}
            isRowSelectable={this.props.isRowSelectable}
            onRowDragEnd={this.props.onRowDragMove}
            animateRows={true}
          ></AgGridReact>
        </div>
      </Fragment>
    );
  }
}

export default Grid_main;
