import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import MainFooter from "../Footer/MainFooter";

const InitialState = {
  logininput: {
    username: "",
    pwd: "",
  },
  login_failed: null,
  loading_login: false,
};

class App_Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logininput: {
        username: "",
        pwd: "",
      },
      login_success: true,
      loading_login: false,
    };
  }

  componentDidMount() {
    //this is temporary to make app creating faster
    //pushes to make res or whatever I work on immidiately
    /*this.props.history.push({
             pathname: `/mainmenu`
         })*/
  }

  onLoginclick = (e) => {
    e.preventDefault();
    //console.log(process.env.NODE_ENV);
    this.setState(
      (prevState) => {
        return { loading_login: true };
      },
      () => {
        axios({
          method: "post",
          url:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/login/local"
              : "https://apiv1.reserv4me.com/login/local",
          headers: {
            "Access-Control-Allow-Origin":
              process.env.NODE_ENV === "development"
                ? "http://localhost:3000"
                : "https://apiv1.reserv4me.com",
            "Content-Type": "application/json",
          },
          data: {
            uname: this.state.logininput.username,
            pw: this.state.logininput.pwd,
          },
          withCredentials: true,
        })
          .then((result) => {
            console.log(
              "🚀 ~ file: App_Signin.js ~ line 63 ~ App_Signin ~ result",
              result.data
            );
            //console.log("log in", result.data);

            if (result.data.success === true) {
              window.sessionStorage.setItem(
                "uname",
                this.state.logininput.username
              );
              window.sessionStorage.setItem(
                "hotel_logo",
                result.data.hotel_logo
              );
              window.sessionStorage.setItem("hassignedup", true);
              this.setState(InitialState, () => {
                if (
                  result.data.hassignedup === true &&
                  result.data.reviewing_info === false
                ) {
                  this.props.history.push({
                    pathname: `/mainmenu`,
                  });
                } else if (
                  result.data.hassignedup === true &&
                  result.data.reviewing_info === true
                ) {
                  this.props.history.push({
                    pathname: `/waiting_review`,
                  });
                } else {
                  this.props.history.push({
                    pathname: `/signup`,
                  });
                }
              });
            } else {
              this.setState({
                login_success: result.data.success,
                loading_login: false,
              });
              //console.log("You inputed the wrong username and password");
            }
          })
          .catch((error) => {
            this.props.history.push({
              pathname: `/`,
            });
            //console.log("massive error", error);
          });
      }
    );
  };

  onUserInput_Login = (e) => {
    let tempvar = e.target;
    this.setState(
      (prevState) => {
        const list = prevState.logininput;
        list[tempvar.id] = tempvar.value;

        return { logininput: list };
      },
      () => {
        //console.log("polci", this.state.logininput);
      }
    );
  };

  render() {
    ////console.log(window.location.href)
    return (
      <>
        <div className="flex flex-col min-h-80 overflow-hidden bg-blue-500">
          <div
            className="grid grid-rows-5 grid-flow-row 
            absolute left-1/2 top-1/2  
          -translate-y-2/4 -translate-x-2/4 
          shadow-3xl rounded max-w-6xl p-4 bg-gray-900 w-full md:w-132
          space-y-6"
          >
            <div className="row-span-1 text-center">
              <span className="text-4xl font-light text-white ">Login</span>
            </div>
            <div
              className={
                this.state.login_success
                  ? "hidden row-span-1"
                  : "text-red-500 row-span-1 text-center"
              }
            >
              <span>Username or Password are incorrect.</span>
            </div>
            <div className="row-span-1">
              <form onSubmit={this.onLoginclick} className="space-x-4">
                <label for="username" className="text-white">
                  Username:
                </label>
                <input
                  id="username"
                  className="rounded-full border border-gray-400 shadow-inner w-full"
                  type="text"
                  placeholder="Enter Username"
                  value={this.state.logininput.username}
                  onChange={this.onUserInput_Login}
                ></input>
              </form>
            </div>
            <div className="row-span-1 ">
              <form onSubmit={this.onLoginclick} className="space-x-4">
                <label for="pwd" className="text-white">
                  Password:
                </label>
                <input
                  id="pwd"
                  className="rounded-full  border border-gray-400 shadow-inner w-full"
                  type="password"
                  placeholder="Enter Password"
                  value={this.state.logininput.pwd}
                  onChange={this.onUserInput_Login}
                ></input>
              </form>
            </div>
            <div className="row-span-1 mx-auto">
              <button
                onClick={this.onLoginclick}
                className="font-normal  bg-blue-500 text-white p-2
                 rounded-full inline w-60 text-2xl"
              >
                Login
              </button>
            </div>
            <div className="row-span-1 r">
              <img
                src="https://res.cloudinary.com/dykrzfpex/image/upload/v1584917661/Reserv4me_logo_main_page_kwksny.png"
                className=" mx-auto"
              />
            </div>
          </div>
        </div>
        <div
          hidden={this.state.loading_login === false ? true : false}
          className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  "
        >
          <div className="loading"></div>
          <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
            <p className="text-2xl text-white -translate-y-16">Loading...</p>
          </div>
        </div>
        <MainFooter />
      </>
    );
  }
}
export default withRouter(App_Signin);
