import React from "react";

import { withRouter } from "react-router-dom";

import "./Main_Sign_up.css";
import "../Make_reservation/Main_Make_res.css";

import Welcome_Stage from "./Welcome_Stage";
import IsAdd2Online from "./IsAdd2Online";
import Basic_Info from "./Basic_Info";
import Additional_Info from "./Additional_Info";
import Additional_Info_2 from "./Additional_Info_2";
import Rooms_and_Rates from "./Rooms_and_Rates";
import Rooms_and_Rates_Additional from "./Rooms_and_Rates_Additional";
import Room_Ranges from "./Room_Ranges";
import Dialog_Page from "./Dialog_Page";
import Finalize_Signup from "./Finalize_Signup";
import ReceivedBy_info from "./ReceivedBy_info";
import StripeConnection from "./StripeConnection/StripeConnection";
import Modal_Stripe_Failed from "./Modal_Stripe_Failed";
import Inventory_successful_Modal from "./successful_Modal";
import Modal_Stripe_Missing_Info from "./Stripe_Modal_MissingInfo";

import axios from "axios";

//const { ipcRenderer } = window.require("electron");

const InitialState = {
  stage: 0,
  progress: 0,
  isTermsAgreed: false,
  hotel_logo: "",
  file_too_Large: false,
  hotel_logo_verification: null, //does it exist?
  temp_logo_URL: "",
  selectedRoomImages: [],
  preview_selected_Images: [],
  image_stage_verified: false,
  current_room_Selected: 0,
  room_Image_Exeeds: false,
  image_Dialog: false,
  basic_info: {
    hotel_name: "",
    hotel_rep: "",
    hotel_phone: "",
    hotel_email: "",
    hotel_fax: "",
    hotel_baddress: "",
    hotel_town: "",
    hotel_State: "",
    hotel_zip: "",
    hotel_fblink: "",
    hotel_twlink: "",
    hotel_inlink: "",
    total_numb_rooms: "",
  },
  basic_info_validation: {
    //isInvalid?
    hotel_name: false,
    hotel_rep: false,
    hotel_phone: true,
    hotel_email: true,
    hotel_fax: true,
    hotel_baddress: false,
    hotel_town: false,
    hotel_State: false,
    hotel_zip: true,
    hotel_fblink: true,
    hotel_twlink: true,
    hotel_inlink: true,
    total_numb_rooms: true,
  },
  basic_is_verified: false,
  policy_info: {
    total_taxes: "",
    pet_policy: "",
    parking_policy: "",
    breakfast_type: "",
    wifi_type: "",
    free_cancellation_period: "",
    down_payment_cost: "",
    cancellation_cost: "",
    checkin_time: "",
    checkout_time: "",
    minimum_room_cost: "",
    additional_policies: "",
    later_payment: "true",
    payment_schedule_date: "",
  },
  policy_info_validation: {
    total_taxes: true,
    pet_policy: true,
    parking_policy: true,
    breakfast_type: true,
    wifi_type: true,
    free_cancellation_period: true,
    down_payment_cost: true,
    cancellation_cost: true,
    checkin_time: true,
    checkout_time: true,
    minimum_room_cost: true,
    additional_policies: false,
    later_payment: true,
    payment_schedule_date: true,
  },
  policycheckv1: false,
  policy_late_verified: false,
  policyonlinedata: {},
  discounts_list: [],
  discount_templist: {
    discount_name: "",
    discount_rate: "",
    available_online: true,
    applicable_if_stay_longer: "",
  },
  discount_temp_valid: {
    discount_name: false,
    discount_rate: false,
    available_online: true,
    applicable_if_stay_longer: false,
  },
  adding_discounts: false,
  empty_discount: false,
  discount_initial_Change: false,

  room_type_list: [],
  room_type_temp_list: {
    room_type_name: "",
    base_rate: "",
    max_occupancy: "",
    base_occupancy: "",
    extraguestc: "",
  },
  room_type_temp_valid: {
    room_type_name: false,
    base_rate: false,
    max_occupancy: false,
    base_occupancy: false,
    extraguestc: false,
  },
  adding_room_type: false,
  empty_room_type: false,
  room_type_initial_Change: false,
  room_type_verified: false,

  temp_range_high: 0,
  temp_range_low: 1,

  room_range_list: [],
  adding_room_range: false,
  temp_range_sel_rm: `${0}-Choose...`,
  room_selection_empty: false,
  range_overlap: false,
  all_ranges_covered: false,
  range_is_verified: false,

  hotel_local_id: null,
  hotel_web_id: null,
  hotel_tax_online_id: null,
  hotel_policy_id: null,
  local_r_types: [],

  //ReceivedBy

  adding_received_by: false,
  channel_type_temp: {
    channel_num: "",
    channel_name: "",
    channel_immediateness: 0,
  },
  channel_type_temp_valid: {
    channel_num: true,
    channel_name: false,
    channel_immediateness: false,
  },
  channel_type_list: [
    { channel_num: 0, channel_name: "Reserv4me", channel_immediateness: false },
    {
      channel_num: 1,
      channel_name: "Reserv4me-Setup",
      channel_immediateness: false,
    },
  ],
  empty_channel_type: false,
  channel_initial_change: false,
  channel_is_verified: false, //is verified

  internalapi_id: 0,
  externalapi_id: null,

  stripe_acct_id: null,

  continue_btn_deactivated: false,
  str_btn_deactivated: false,
  show_modal_stripe: false,
  show_successful_modal: false,
  loading_stripe: false,
  stripe_verification_passed: false,
  show_modal_info_miss: false,
  stage_policies_disc_verified: false,
  isloadingInventoryCreatedFN: true,
  isBtnFinishDisabled: false,
};

class Main_Sign_up extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      progress: 0,
      isTermsAgreed: false,
      hotel_logo: "",
      file_too_Large: false,
      hotel_logo_verification: null, //does it exist?
      temp_logo_URL: "",
      selectedRoomImages: [],
      preview_selected_Images: [],
      image_stage_verified: false,
      current_room_Selected: 0,
      room_Image_Exeeds: false,
      image_Dialog: false,
      basic_info: {
        hotel_name: "",
        hotel_rep: "",
        hotel_phone: "",
        hotel_email: "",
        hotel_fax: "",
        hotel_baddress: "",
        hotel_town: "",
        hotel_State: "",
        hotel_zip: "",
        hotel_fblink: "",
        hotel_twlink: "",
        hotel_inlink: "",
        total_numb_rooms: "",
      },
      basic_info_validation: {
        //isInvalid?
        hotel_name: false,
        hotel_rep: false,
        hotel_phone: true,
        hotel_email: true,
        hotel_fax: true,
        hotel_baddress: false,
        hotel_town: false,
        hotel_State: false,
        hotel_zip: true,
        hotel_fblink: true,
        hotel_twlink: true,
        hotel_inlink: true,
        total_numb_rooms: true,
      },
      basic_is_verified: false,
      policy_info: {
        total_taxes: "",
        pet_policy: "",
        parking_policy: "",
        breakfast_type: "",
        wifi_type: "",
        free_cancellation_period: "",
        down_payment_cost: "",
        cancellation_cost: "",
        checkin_time: "",
        checkout_time: "",
        minimum_room_cost: "",
        additional_policies: "",
        later_payment: "true",
        payment_schedule_date: "",
      },
      policy_info_validation: {
        total_taxes: true,
        pet_policy: true,
        parking_policy: true,
        breakfast_type: true,
        wifi_type: true,
        free_cancellation_period: true,
        down_payment_cost: true,
        cancellation_cost: true,
        checkin_time: true,
        checkout_time: true,
        minimum_room_cost: true,
        additional_policies: false,
        later_payment: true,
        payment_schedule_date: true,
      },
      policycheckv1: false,
      policy_late_verified: false,
      policyonlinedata: {},
      discounts_list: [],
      discount_templist: {
        discount_name: "",
        discount_rate: "",
        available_online: true,
        applicable_if_stay_longer: "",
      },
      discount_temp_valid: {
        discount_name: false,
        discount_rate: false,
        available_online: true,
        applicable_if_stay_longer: false,
      },
      adding_discounts: false,
      empty_discount: false,
      discount_initial_Change: false,

      room_type_list: [],
      room_type_temp_list: {
        room_type_name: "",
        base_rate: "",
        max_occupancy: "",
        base_occupancy: "",
        extraguestc: "",
      },
      room_type_temp_valid: {
        room_type_name: false,
        base_rate: false,
        max_occupancy: false,
        base_occupancy: false,
        extraguestc: false,
      },
      adding_room_type: false,
      empty_room_type: false,
      room_type_initial_Change: false,
      room_type_verified: false,

      temp_range_high: 0,
      temp_range_low: 1,

      room_range_list: [],
      adding_room_range: false,
      temp_range_sel_rm: `${0}-Choose...`,
      room_selection_empty: false,
      range_overlap: false,
      all_ranges_covered: false,
      range_is_verified: false,

      hotel_local_id: null,
      hotel_web_id: null,
      hotel_tax_online_id: null,
      hotel_policy_id: null,
      local_r_types: [],

      //ReceivedBy

      adding_received_by: false,
      channel_type_temp: {
        channel_num: "",
        channel_name: "",
        channel_immediateness: 0,
      },
      channel_type_temp_valid: {
        channel_num: true,
        channel_name: false,
        channel_immediateness: false,
      },
      channel_type_list: [
        {
          channel_num: 0,
          channel_name: "Reserv4me",
          channel_immediateness: "false",
        },
        {
          channel_num: 1,
          channel_name: "Reserv4me-Setup",
          channel_immediateness: "false",
        },
      ],
      empty_channel_type: false,
      channel_initial_change: false,
      channel_is_verified: false, //is verified

      internalapi_id: 0,
      externalapi_id: null,

      stripe_acct_id: null,
      continue_btn_deactivated: false,
      str_btn_deactivated: false,
      show_successful_modal: false,
      loading_stripe: false,
      stripe_verification_passed: false,
      stage_policies_disc_verified: false,
      isloadingInventoryCreatedFN: false,
      isBtnFinishDisabled: false,
    };
  }

  componentDidMount() {
    this.policy_gather_intro();

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/v1/verify_stripe"
          : "https://apiv1.reserv4me.com/v1/verify_stripe",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
      },
      data: {
        str_acc: "this.props.str_acc",
      },
      withCredentials: true,
    })
      .then((result) => {
        // console.log(
        // "🚀 ~ file: Main_Sign_up.js ~ line 390 ~ Main_Sign_up ~ componentDidMount ~ data",
        // result.data
        // );
        if (result.data.isAccFound === false) {
          this.setState((prevState) => {
            return {
              isloadingInventoryCreatedFN: true,
            };
          });
        } else {
          this.setState((prevState) => {
            return {
              stage: 3,
              isTermsAgreed: true,
              str_btn_deactivated: true,
              loading_stripe: false,
              stripe_verification_passed: true,
              stripe_acct_id: result.data.stripe_acct_id,
              isloadingInventoryCreatedFN: true,
              progress: 30,
            };
          });
        }
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  }

  policy_gather_intro = () => {
    /*let req = JSON.stringify({

        })*/
    //policy-data

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/policy-data"
          : "https://apiv1.reserv4me.com/policy-data",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {},
      withCredentials: true,
    })
      .then((result) => {
        //console.log("Igot", result);
        this.setState((prevState) => {
          return { policyonlinedata: result.data };
        });
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });

    //let resp = ipcRenderer.sendSync('policy_gather_intro', req)
  };

  handle_close_Room_Image_Dialog = () => {
    this.setState({ image_Dialog: false });
  };

  return_To_Main = () => {
    this.props.history.push({
      pathname: `/mainmenu`,
    });
  };

  stage_Changer(direction) {
    this.setState((prevState) => ({
      stage: prevState.stage + (direction === "up" ? 1 : -1),
    }));
  }

  progress_Update(direction) {
    let verified = this.stepValidated(direction);
    if (verified === true) {
      this.setState((prevState) => ({
        progress: prevState.progress + (direction === "up" ? 10 : -10),
        stage: prevState.stage + (direction === "up" ? 1 : -1),
      }));
    } else {
      //console.log("you need to add something");
    }
  }
  TermsAgreedto_Change = () => {
    this.setState((prevState) => ({ isTermsAgreed: !prevState.isTermsAgreed }));
  };

  onLogoImgSelected = (e) => {
    //console.log("selected", e.target.files);
    const reader = new FileReader();

    let tempFiles = Array.from(e.target.files);
    if (tempFiles[0].size < 100000) {
      //console.log("selected", tempFiles)
      reader.onload = () => {
        if (reader.readyState === 2) {
          //console.log(reader.result)
          this.setState({
            temp_logo_URL: reader.result,
            file_too_Large: false,
            hotel_logo: tempFiles,
          });
        }
      };
      /*this.setState({ hotel_logo: tempFiles, file_too_Large: false }, () => {
        reader.onload = () => {
          if (reader.readyState === 2) {
            ////console.log(reader.result)
            this.setState({ temp_logo_URL: reader.result });
          }
        };
      });*/
      reader.readAsDataURL(e.target.files[0]);
    } else {
      ////console.log("Please select a file that is less than 100KB in size for your logo")
      this.setState({
        hotel_logo: InitialState.hotel_logo,
        file_too_Large: true,
      });
    }
  };

  Room_Images_Selected = (e) => {
    ////console.log("selected", e.target.files)
    //debugger;
    let tempFiles = Array.from(e.target.files);
    //console.log("tempFile", tempFiles);
    Promise.all(
      tempFiles.map((current, index) => {
        //console.log(current, index);
        var reader = new FileReader();
        var itemread = "";
        reader.onload = () => {
          if (reader.readyState === 2) {
            //console.log(reader.result);
            itemread = reader.result;
            if (current.size < 5e6) {
              this.setState(
                (prevState) => {
                  const selected = prevState.current_room_Selected;
                  const list = [...prevState.preview_selected_Images];
                  const imagesSelected = [...prevState.selectedRoomImages];
                  //console.log("test", list.length);
                  //console.log("before selection", imagesSelected);

                  if (list.length === 0) {
                    list[selected] = { images: [reader.result] };
                    imagesSelected[selected][0] = {
                      image: reader.result,
                      title: "",
                      description: "",
                    };
                  } else {
                    list[selected] = {
                      images: [...list[selected].images, reader.result],
                    };
                    //imagesSelected[selected]={image:current.path,title:"",description:""};
                    imagesSelected[selected] = {
                      images: [
                        ...imagesSelected[selected].images,
                        { image: reader.result, title: "", description: "" },
                      ],
                    };
                  }
                  //console.log("after selection", imagesSelected);
                  return {
                    preview_selected_Images: list,
                    selectedRoomImages: imagesSelected,
                  };
                },
                () => {
                  /*console.log(
                    "preview img",
                    this.state.preview_selected_Images,
                    this.state.selectedRoomImages
                  );*/
                }
              );
            } else {
              this.setState((prevState) => ({
                room_Image_Exeeds: true,
                image_Dialog: true,
              }));
            }
          }
        };
        reader.readAsDataURL(current);
        return itemread;
      })
    );
  };

  basicValidation_Text = (id, currentValue) => {
    switch (id) {
      case "hotel_fax":
      case "hotel_phone": {
        let previoustestRegEx = new RegExp(
          /^((\+1|1)?( |-)?)?(\(([2-9])?([0-9]{2})?)$/
        );
        /*console.log(
          "test12",
          previoustestRegEx.test(currentValue),
          currentValue
        );*/
        if (previoustestRegEx.test(currentValue)) {
          currentValue = currentValue.substring(0, currentValue.length - 1);
          //console.log("check", currentValue);
        }
        //console.log("check2".currentValue);
        ////console.log("test12",previoustestRegEx.test(currentValue))
        //if(currentValue)
        let temppast = currentValue;
        let tempcur = currentValue.replace(/[\/\-\+()]/, "");
        while (tempcur !== temppast) {
          temppast = temppast.replace(/[\/\-\+()]/, "");
          tempcur = tempcur.replace(/[\/\-\+()]/, "");
        }
        if (tempcur.substring(0, 1) === "1") {
          if (tempcur.substring(1, 2) === " ") {
            tempcur = tempcur.substring(2);
          } else {
            tempcur = tempcur.substring(1);
          }
        }

        //console.log("we ended", tempcur.substring(0, 1), tempcur, currentValue);
        let displaynumb = null;
        if (tempcur.length <= 3) {
          if (tempcur === "") {
            displaynumb = "";
          } else {
            displaynumb = `+1 (${tempcur})`;
          }

          //console.log("a", displaynumb);
        } else if (tempcur.length <= 6) {
          displaynumb = `+1 (${tempcur.substring(0, 3)})-${tempcur.substring(
            3,
            6
          )}`;
          //console.log("b", displaynumb);
        } else if (tempcur.length <= 10) {
          displaynumb = `+1 (${tempcur.substring(0, 3)})-${tempcur.substring(
            3,
            6
          )}-${tempcur.substring(6)}`;
          //console.log("c", displaynumb);
        }
        //console.log("d", displaynumb);
        //else if(tempcur.len)
        let validatorRegExp = new RegExp(
          /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/
        ); //only works for US numbers
        if (validatorRegExp.test(displaynumb)) {
          return [id, displaynumb, false];
        } else {
          return [id, displaynumb, true];
        }
      }
      case "hotel_email": {
        let validatorRegExp = new RegExp(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        );
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
      case "hotel_zip": {
        let validatorRegExp = new RegExp(/^\d{5}(-\d{4})?$/); ///^\d{5}(-\d{4})?$/
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
      case "hotel_inlink":
      case "hotel_twlink":
      case "hotel_fblink": {
        let validatorRegExp = new RegExp(
          /^(https)?(:)?(\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
        );
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
      case "hotel_town":
      case "hotel_State": {
        let validatorRegExp = new RegExp(/^[a-zA-Z\s]+$/);
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
      case "total_numb_rooms": {
        let validatorRegExp = new RegExp(/^[0-9]+$/);
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
      default: {
        let validatorRegExp = new RegExp(/^[a-zA-Z0-9\s]+$/);
        if (validatorRegExp.test(currentValue)) {
          return [id, currentValue, false];
        } else {
          return [id, currentValue, true];
        }
      }
    }
  };

  onBasicInfo_Change = (e) => {
    //console.log("change", e.target.id);
    let tempvar = e.target;
    ////console.log(tempvar)
    let validated = this.basicValidation_Text(tempvar.id, tempvar.value);
    //console.log(validated);
    this.setState((prevState) => {
      const list = prevState.basic_info;
      list[tempvar.id] = validated[1];
      const listvalidator = prevState.basic_info_validation;
      listvalidator[tempvar.id] = validated[2];
      if (tempvar.id === "total_numb_rooms") {
        //console.log(list);
        return {
          basic_info: list,
          temp_range_high: parseInt(tempvar.value),
          basic_info_validation: listvalidator,
        };
      }
      ////console.log(tempvar.value,list.hotel_name)
      else {
        //console.log(list);
        return { basic_info: list, basic_info_validation: listvalidator };
      }
    });
  };
  onPolicyChange = (e) => {
    let tempvar = e.target;

    ////console.log("policy_change",tempvar.options[tempvar.selectedIndex].text)
    //console.log(tempvar.value);
    if (tempvar.id === "minimum_room_cost" || tempvar.id === "total_taxes") {
      let validatorRegExp = new RegExp(/^[0-9]+\.?([0-9]{2}|[0-9])?$/);
      if (validatorRegExp.test(tempvar.value)) {
        this.setState((prevState) => {
          const list = prevState.policy_info;
          list[tempvar.id] = tempvar.value;
          const listvalidator = prevState.policy_info_validation;
          listvalidator[tempvar.id] = false;
          return { policy_info: list, policy_info_validation: listvalidator };
        });
      } else {
        this.setState((prevState) => {
          const list = prevState.policy_info;
          list[tempvar.id] = tempvar.value;
          const listvalidator = prevState.policy_info_validation;
          listvalidator[tempvar.id] = true;
          return { policy_info: list, policy_info_validation: listvalidator };
        });
      }
    } else {
      if (tempvar.id === "payment_schedule_date") {
        let validatorRegExp = new RegExp(/^[0-9]+$/);
        if (validatorRegExp.test(tempvar.value)) {
          this.setState((prevState) => {
            const list = prevState.policy_info;
            list[tempvar.id] = tempvar.value;
            const listvalidator = prevState.policy_info_validation;
            listvalidator[tempvar.id] = false;
            return { policy_info: list, policy_info_validation: listvalidator };
          });
        } else {
          this.setState((prevState) => {
            const list = prevState.policy_info;
            list[tempvar.id] = tempvar.value;
            const listvalidator = prevState.policy_info_validation;
            listvalidator[tempvar.id] = true;
            return { policy_info: list, policy_info_validation: listvalidator };
          });
        }
      } else {
        this.setState(
          (prevState) => {
            const list = prevState.policy_info;
            list[tempvar.id] = tempvar.value;

            if (tempvar.value !== parseInt(0)) {
              const listvalidator = prevState.policy_info_validation;
              listvalidator[tempvar.id] = false;
              return {
                policy_info: list,
                policy_info_validation: listvalidator,
              };
            } else {
              return { policy_info: list };
            }
            ////console.log(tempvar.value);
            //listvalidator[tempvar.id]=
          },
          () => {
            //console.log("polci", this.state.policy_info);
          }
        );
      }
    }
  };
  onAddDiscounts = () => {
    if (
      this.state.adding_discounts === true &&
      this.state.discount_initial_Change === true
    ) {
      //console.log(this.state.discount_templist);
      if (
        this.state.discount_templist.discount_name === "" &&
        this.state.discount_templist.discount_rate === "" &&
        this.state.discount_templist.applicable_if_stay_longer === ""
      ) {
        this.setState((prevState) => ({
          adding_discounts: !prevState.adding_discounts,
        }));
      } else {
        let existerrors = [];
        //console.log("saving");
        Object.entries(this.state.discount_templist).map((current, i) => {
          if (current[1] === "") {
            existerrors[existerrors.length] = current;
          }
        });
        Object.entries(this.state.discount_temp_valid).map((current, i) => {
          //console.log(current, current[1]);
          if (current[1] === false) {
            existerrors[existerrors.length] = current;
          }
        });
        if (existerrors.length === 0) {
          //console.log("noerror");
          this.setState(
            (prevState) => {
              const discountlist = prevState.discounts_list;
              discountlist[discountlist.length] = prevState.discount_templist;
              return { discounts_list: discountlist };
            },
            () => {
              this.resetDiscount();
            }
          );
        } else {
          //console.log(existerrors);
          this.setState(
            (prevState) => {
              let replacevalid = prevState.discount_temp_valid;
              //replacevalid[current[0]]=true;
              //console.log("before", replacevalid);

              existerrors.map((current, i) => {
                //console.log(current[0]);
                replacevalid[current[0]] = false;
              });
              //console.log(replacevalid);
              return {
                discount_temp_valid: replacevalid,
                empty_discount: true,
              };
            },
            () => {
              //console.log(this.state.discount_temp_valid);
            }
          );
        }
      }
    } else {
      //console.log("adding");
      this.setState((prevState) => ({
        adding_discounts: !prevState.adding_discounts,
      }));
    }
  };
  resetDiscount = () => {
    this.setState((prevState) => {
      return {
        discount_templist: {
          discount_name: "",
          discount_rate: "",
          available_online: true,
          applicable_if_stay_longer: "",
        },
        discount_temp_valid: {
          discount_name: false,
          discount_rate: false,
          available_online: true,
          applicable_if_stay_longer: false,
        },
        adding_discounts: false,
        empty_discount: false,
        discount_initial_Change: false,
      };
    });
  };
  onDiscountChange = (e) => {
    //console.log("check", e.target);
    let tempvar = e.target;
    ////console.log("policy_change",tempvar.options[tempvar.selectedIndex].text)
    ////console.log("test",tempvar.checked)

    let validatorRegExp = new RegExp(/^[0-9]+\.?([0-9]{2}|[0-9])?$/);
    let validatorRegExp_INT = new RegExp(/^[0-9]+$/);

    let testvalue = null;
    if (tempvar.id === "discount_rate") {
      testvalue = validatorRegExp.test(tempvar.value);
      //console.log(testvalue);
    } else if (tempvar.id === "applicable_if_stay_longer") {
      testvalue = validatorRegExp_INT.test(tempvar.value);
      // console.log(
      // "🚀 ~ file: Main_Sign_up.js ~ line 929 ~ Main_Sign_up ~ testvalue",
      // testvalue
      // );
    }

    this.setState((prevState) => {
      const list = prevState.discount_templist;
      const validation = prevState.discount_temp_valid;
      list[tempvar.id] =
        tempvar.type === "checkbox" ? tempvar.checked : tempvar.value;

      if (
        tempvar.id === "discount_rate" ||
        tempvar.id === "applicable_if_stay_longer"
      ) {
        validation[tempvar.id] = testvalue;
      } else {
        validation[tempvar.id] =
          tempvar.type === "checkbox"
            ? true
            : tempvar.value === ""
            ? false
            : true;
      }

      return {
        discount_templist: list,
        discount_temp_valid: validation,
        discount_initial_Change: true,
      };
    });
  };

  onAddRooms = () => {
    //console.log("adding room");
    //this.setState(prevState=>({adding_room_type:!prevState.adding_room_type}))

    if (
      this.state.adding_room_type === true &&
      this.state.room_type_initial_Change === true
    ) {
      let existerrors = [];
      //console.log("saving");
      Object.entries(this.state.room_type_temp_list).map((current, i) => {
        //console.log("test array", current);
        if (current[1] === "") {
          existerrors[existerrors.length] = current;
        }
      });
      Object.entries(this.state.room_type_temp_valid).map((current, i) => {
        //console.log("test array verify", current);
        if (current[1] === false) {
          existerrors[existerrors.length] = current;
        }
      });
      if (existerrors.length === 0) {
        //noerror room being addded
        ////console.log("noerror")
        this.setState(
          (prevState) => {
            const roomlist = prevState.room_type_list;
            ////console.log("check",roomlist.length)
            const roomimg = prevState.preview_selected_Images;
            const lengthofroomlist = roomlist.length;
            const imagesSelected = prevState.selectedRoomImages;
            imagesSelected[lengthofroomlist] = { images: [] };
            roomimg[lengthofroomlist] = { images: [] };
            roomlist[lengthofroomlist] = {
              ...prevState.room_type_temp_list,
              room_description: "",
            };

            return {
              room_type_list: roomlist,
              preview_selected_Images: roomimg,
              selectedRoomImages: imagesSelected,
            };
          },
          () => {
            /*console.log(
              "images",
              this.state.preview_selected_Images,
              this.state.current_room_Selected,
              this.state.room_type_list
            );*/
            this.resetRoomTypeAdd();
          }
        );
      } else {
        //console.log(existerrors);
        this.setState(
          (prevState) => {
            let replacevalid = prevState.room_type_temp_valid;
            //replacevalid[current[0]]=true;
            //console.log("before", replacevalid);

            existerrors.map((current, i) => {
              //console.log(current[0]);
              replacevalid[current[0]] = false;
            });
            //console.log(replacevalid);
            return {
              room_type_temp_valid: replacevalid,
              empty_room_type: true,
            };
          },
          () => {
            //console.log(this.state.room_type_temp_valid);
          }
        );
      }
    } else {
      ////console.log("adding")
      this.setState((prevState) => ({
        adding_room_type: !prevState.adding_room_type,
      }));
    }
  };
  onRoomTypeChange = (e) => {
    let tempvar = e.target;
    //console.log("onRoomTypeChange -> tempvar", tempvar);
    let validated = null;

    switch (tempvar.id) {
      case "room_type_name": {
        validated = true;
        break;
      }
      case "base_rate": {
        let validatorRegExp = new RegExp(/^[0-9]+\.?([0-9]{2}|[0-9])?$/);
        validated = validatorRegExp.test(tempvar.value);
        if (
          parseInt(tempvar.value) <
          parseInt(this.state.policy_info.minimum_room_cost)
        ) {
          validated = false;
        }
        break;
      }
      case "max_occupancy": {
        let validatorRegExp = new RegExp(/^[0-9]+$/);
        validated = validatorRegExp.test(tempvar.value);

        break;
      }
      case "base_occupancy": {
        let validatorRegExp = new RegExp(/^[0-9]+$/);
        validated = validatorRegExp.test(tempvar.value);

        break;
      }
      case "extraguestc": {
        let validatorRegExp = new RegExp(/^[0-9]+\.?([0-9]{2}|[0-9])?$/);
        validated = validatorRegExp.test(tempvar.value);

        break;
      }
    }
    //console.log("onRoomTypeChange -> tempvar", tempvar, validated);

    this.setState((prevState) => {
      const list = prevState.room_type_temp_list;
      const validation = prevState.room_type_temp_valid;
      list[tempvar.id] = tempvar.value;
      validation[tempvar.id] = validated;
      return {
        room_type_temp_list: list,
        room_type_temp_valid: validation,
        room_type_initial_Change: true,
      };
    });

    ////console.log("discount changing")
  };

  resetRoomTypeAdd = () => {
    this.setState((prevState) => {
      return {
        room_type_temp_list: {
          room_type_name: "",
          base_rate: "",
          max_occupancy: "",
          base_occupancy: "",
          extraguestc: "",
        },
        room_type_temp_valid: {
          room_type_name: false,
          base_rate: false,
          max_occupancy: false,
          base_occupancy: false,
          extraguestc: false,
        },
        adding_room_type: false,
        empty_room_type: false,
        room_type_initial_Change: false,
      };
    });
  };

  current_Room_selected = (e) => {
    let tempvar = e.currentTarget;
    ////console.log("roomselected",this.state.current_room_Selected,tempvar.getAttribute("tabIndex"))
    this.setState(
      (prevState) => ({
        current_room_Selected: parseInt(tempvar.getAttribute("tabIndex")),
      }),
      () => {
        ////console.log("roomselected",this.state.current_room_Selected,tempvar.getAttribute("tabIndex"))
      }
    );
    ////console.log("item",)
  };

  onImgDescriptionChange = (e) => {
    let tempvar = e.target;
    // //console.log("testval",tempvar)

    this.setState((prevState) => {
      const image_desc = prevState.selectedRoomImages;
      image_desc[prevState.current_room_Selected].images[
        parseInt(tempvar.id.split("-")[0])
      ][tempvar.id.split("-")[1]] = tempvar.value;
      return { selectedRoomImages: image_desc };
    });
  };

  onRoom_Description_Change = (e) => {
    let tempvar = e.target;
    //console.log("testval", tempvar);

    this.setState((prevState) => {
      const room_desc = prevState.room_type_list;
      room_desc[prevState.current_room_Selected][tempvar.id.split("-")[1]] =
        tempvar.value;
      return { room_type_list: room_desc };
    });
  };

  onSliderValueChange = (e, value) => {
    // if(this.state.room_range_list.length===0){
    // this.setState(prevState=>{
    //     return({
    //     temp_range_low:value[0],
    //     temp_range_high:value[1]})
    // })}
    // else{
    let outside_range = this.state.room_range_list.find(
      (element) =>
        (element.room_start <= value[0] &&
          element.room_start <= value[1] &&
          element.room_end >= value[1] &&
          element.room_end >= value[0]) ||
        (element.room_start >= value[0] &&
          element.room_start <= value[1] &&
          element.room_end >= value[1] &&
          element.room_end >= value[0]) ||
        (element.room_start <= value[0] &&
          element.room_start <= value[1] &&
          element.room_end <= value[1] &&
          element.room_end >= value[0]) ||
        (element.room_start >= value[0] &&
          element.room_start <= value[1] &&
          element.room_end <= value[1] &&
          element.room_end >= value[0])
    );
    // let outside_range=this.state.room_range_list.includes(element=>element.room_start>value[0])

    ////console.log("lets see",outside_range)
    if (outside_range === undefined) {
      this.setState((prevState) => {
        return {
          temp_range_low: value[0],
          temp_range_high: value[1],
          range_overlap: false,
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          temp_range_low: value[0],
          temp_range_high: value[1],
          range_overlap: true,
        };
      });
    }

    //}
  };
  onAddingRoom_range = () => {
    if (this.state.adding_room_range === true) {
      if (
        this.state.temp_range_sel_rm !== `${0}-Choose...` &&
        this.state.range_overlap === false
      ) {
        let outside_range = this.state.room_range_list.find(
          (element) =>
            (element.room_start <= this.state.temp_range_low &&
              element.room_start <= this.state.temp_range_high &&
              element.room_end >= this.state.temp_range_high &&
              element.room_end >= this.state.temp_range_low) ||
            (element.room_start >= this.state.temp_range_low &&
              element.room_start <= this.state.temp_range_high &&
              element.room_end >= this.state.temp_range_high &&
              element.room_end >= this.state.temp_range_low) ||
            (element.room_start <= this.state.temp_range_low &&
              element.room_start <= this.state.temp_range_high &&
              element.room_end <= this.state.temp_range_high &&
              element.room_end >= this.state.temp_range_low) ||
            (element.room_start >= this.state.temp_range_low &&
              element.room_start <= this.state.temp_range_high &&
              element.room_end <= this.state.temp_range_high &&
              element.room_end >= this.state.temp_range_low)
        );

        if (outside_range === undefined) {
          this.setState(
            (prevState) => {
              const range_list = prevState.room_range_list;
              const temp_name = prevState.temp_range_sel_rm;
              range_list[range_list.length] = {
                room_type_id_temp: parseInt(temp_name.split("-")[0]),
                room_type: temp_name.split("-")[1],
                room_start: prevState.temp_range_low,
                room_end: prevState.temp_range_high,
              };
              range_list.sort((a, b) => {
                return a.room_start - b.room_start;
              });
              return {
                adding_room_range: !prevState.adding_room_range,
                room_range_list: range_list,
              };
            },
            () => {
              this.setState(
                (prevState) => {
                  const rmnum = prevState.basic_info.total_numb_rooms;
                  return {
                    temp_range_low: 1,
                    temp_range_high: parseInt(rmnum),
                    temp_range_sel_rm: `${0}-Choose...`,
                    room_selection_empty: false,
                  };
                },
                () => {
                  let error_range = 0;
                  this.state.room_range_list.map((current, index) => {
                    return (error_range =
                      error_range + current.room_end - current.room_start + 1);
                  });
                  ////console.log(error_range)
                  if (
                    error_range ===
                    parseInt(this.state.basic_info.total_numb_rooms)
                  ) {
                    this.setState((prevState) => {
                      return { all_ranges_covered: true };
                    });
                  }
                }
              );
            }
          );
        } else {
          this.setState((prevState) => {
            return { range_overlap: true };
          });
        }
      } else {
        this.setState((prevState) => {
          return { room_selection_empty: true };
        });
      }
    } else {
      this.setState((prevState) => ({
        adding_room_range: !prevState.adding_room_range,
      }));
    }
  };
  onRange_Selection_Change = (e) => {
    let tempvar = e.target;
    this.setState((prevState) => ({ temp_range_sel_rm: tempvar.value }));
  };

  close_adding_range = () => {
    this.setState((prevState) => {
      return { adding_room_range: !prevState.adding_room_range };
    });
  };
  /*activate_loading = () => {
    this.setState((prevState) => {
      return {
        isloadingInventoryCreatedFN: false,
        isBtnFinishDisabled: true,
      };
    });
  };*/

  create_Inventory = () => {
    //console.log("creating");

    this.setState(
      (prevState) => {
        return {
          isloadingInventoryCreatedFN: false,
          isBtnFinishDisabled: true,
        };
      },
      () => {
        return axios({
          method: "post",
          url:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/app-initialization"
              : "https://apiv1.reserv4me.com/app-initialization",
          headers: {
            "Access-Control-Allow-Origin":
              process.env.NODE_ENV === "development"
                ? "http://localhost:3000"
                : "https://apiv1.reserv4me.com",
            "Content-Type": "application/json",
            Authorization: "internalvar.apikey",
          },
          data: {},
          withCredentials: true,
        })
          .then((result) => {
            // console.log(
            // "🚀 ~ file: Main_Sign_up.js ~ line 1254 ~ Main_Sign_up ~ result",
            // result
            // );
            if (result.data.success === true) {
              this.setState({
                internalapi_id: result.data.internalapi_id,
                externalapi_id: result.data.externalapi_id,
              });
              return axios({
                method: "post",
                url:
                  process.env.NODE_ENV === "development"
                    ? "http://localhost:3000/hotel-basic-info"
                    : "https://apiv1.reserv4me.com/hotel-basic-info",
                headers: {
                  "Access-Control-Allow-Origin":
                    process.env.NODE_ENV === "development"
                      ? "http://localhost:3000"
                      : "https://apiv1.reserv4me.com",
                  "Content-Type": "application/json",
                  Authorization: "internalvar.apikey",
                },
                data: {
                  basic_info: this.state.basic_info,
                  hotel_min_price: this.state.policy_info.minimum_room_cost,
                  externalapi_id: this.state.externalapi_id,
                  internalapi_id: this.state.internalapi_id,
                  stripe_user_id: this.state.stripe_acct_id,
                },
                withCredentials: true,
              })
                .then((result) => {
                  //if((result.data.handled_basic===true)){
                  //console.log("hotelid", result);

                  this.setState((prevState) => {
                    return {
                      hotel_web_id: result.data.hotel_web_id,
                      hotel_local_id: result.data.hotel_local_id,
                    };
                  });
                  return axios({
                    method: "post",
                    url:
                      process.env.NODE_ENV === "development"
                        ? "http://localhost:3000/hotel-policy-info"
                        : "https://apiv1.reserv4me.com/hotel-policy-info",
                    headers: {
                      "Access-Control-Allow-Origin":
                        process.env.NODE_ENV === "development"
                          ? "http://localhost:3000"
                          : "https://apiv1.reserv4me.com",
                      "Content-Type": "application/json",
                      Authorization: "internalvar.apikey",
                    },
                    data: {
                      policy_info: this.state.policy_info,
                      hotel_web_id: result.data.hotel_web_id,
                      hotel_name: this.state.basic_info.hotel_name,
                      hotel_local_id: this.state.hotel_local_id,
                    },
                    withCredentials: true,
                  })
                    .then((result) => {
                      //console.log("result id", result);

                      this.setState((prevState) => {
                        return {
                          hotel_tax_online_id:
                            result.data.internalvar.hotel_tax_online,
                          hotel_policy_id: result.data.internalvar.policyid,
                        };
                      });

                      return axios({
                        method: "post",
                        url:
                          process.env.NODE_ENV === "development"
                            ? "http://localhost:3000/discount-save"
                            : "https://apiv1.reserv4me.com/discount-save",
                        headers: {
                          "Access-Control-Allow-Origin":
                            process.env.NODE_ENV === "development"
                              ? "http://localhost:3000"
                              : "https://apiv1.reserv4me.com",
                          "Content-Type": "application/json",
                          Authorization: "internalvar.apikey",
                        },
                        data: {
                          discounts_list: this.state.discounts_list,
                          hotel_local_id: this.state.hotel_local_id,
                        },
                        withCredentials: true,
                      })
                        .then((result) => {
                          return axios({
                            method: "post",
                            url:
                              process.env.NODE_ENV === "development"
                                ? "http://localhost:3000/handle-room-type"
                                : "https://apiv1.reserv4me.com/handle-room-type",
                            headers: {
                              "Access-Control-Allow-Origin":
                                process.env.NODE_ENV === "development"
                                  ? "http://localhost:3000"
                                  : "https://apiv1.reserv4me.com",
                              "Content-Type": "application/json",
                              Authorization: "internalvar.apikey",
                            },
                            data: {
                              room_type_list: this.state.room_type_list,
                              extraguestc:
                                this.state.policy_info.additional_guest_cost,
                              hotel_web_id: this.state.hotel_web_id,
                              hotel_policy_id: this.state.hotel_policy_id,
                              breakfastid:
                                this.state.policy_info.breakfast_type,
                              wifi_id: this.state.policy_info.wifi_type,
                              hotel_local_id: this.state.hotel_local_id,
                            },
                            withCredentials: true,
                          })
                            .then((result) => {
                              this.setState((prevState) => {
                                return {
                                  local_r_types: result.data.room_type_list,
                                };
                              });
                              //console.log("after woom inserted", result);

                              return axios({
                                method: "post",
                                url:
                                  process.env.NODE_ENV === "development"
                                    ? "http://localhost:3000/range-handler"
                                    : "https://apiv1.reserv4me.com/range-handler",
                                headers: {
                                  "Access-Control-Allow-Origin":
                                    process.env.NODE_ENV === "development"
                                      ? "http://localhost:3000"
                                      : "https://apiv1.reserv4me.com",
                                  "Content-Type": "application/json",
                                  Authorization: "internalvar.apikey",
                                },
                                data: {
                                  room_range_list: this.state.room_range_list,
                                  local_r_types: this.state.local_r_types,
                                  total_num_room:
                                    this.state.basic_info.total_numb_rooms,
                                  hotel_local_id: this.state.hotel_local_id,
                                },
                                withCredentials: true,
                              })
                                .then((result) => {
                                  //console.log("calendar", result);

                                  return axios({
                                    method: "post",
                                    url:
                                      process.env.NODE_ENV === "development"
                                        ? "http://localhost:3000/receivedby-addition"
                                        : "https://apiv1.reserv4me.com/receivedby-addition",
                                    headers: {
                                      "Access-Control-Allow-Origin":
                                        process.env.NODE_ENV === "development"
                                          ? "http://localhost:3000"
                                          : "https://apiv1.reserv4me.com",
                                      "Content-Type": "application/json",
                                      Authorization: "internalvar.apikey",
                                    },
                                    data: {
                                      channel_type_list:
                                        this.state.channel_type_list,
                                      hotel_local_id: this.state.hotel_local_id,
                                    },
                                    withCredentials: true,
                                  });
                                })
                                .then((result) => {
                                  // console.log(
                                  // "🚀 ~ file: Main_Sign_up.js ~ line 1461 ~ Main_Sign_up ~ .then ~ result",
                                  // result
                                  // );

                                  return axios({
                                    method: "post",
                                    url:
                                      process.env.NODE_ENV === "development"
                                        ? "http://localhost:3000/imageuploadrooms"
                                        : "https://apiv1.reserv4me.com/imageuploadrooms",
                                    headers: {
                                      /*
                              
                                process.env.NODE_ENV === "development"
                                  ? "http://localhost:3000"
                                  : "https://apiv1.reserv4me.com",
                              */
                                      "Access-Control-Allow-Origin": "*",
                                      "Content-Type": "application/json",
                                      Authorization: "internalvar.apikey",
                                    },
                                    data: {
                                      hotel_logo: this.state.temp_logo_URL,
                                      room_images_room:
                                        this.state.selectedRoomImages,
                                      local_r_types: this.state.local_r_types,
                                      hotel_web_id: this.state.hotel_web_id,
                                      hotel_local_id: this.state.hotel_local_id,
                                    },
                                    withCredentials: true,
                                  });
                                })
                                .then((result) => {
                                  //console.log("in test", result.data);
                                  if (result.data.sucessful === true) {
                                    return axios({
                                      method: "post",
                                      url:
                                        process.env.NODE_ENV === "development"
                                          ? "http://localhost:3000/SignUp_Email"
                                          : "https://apiv1.reserv4me.com/SignUp_Email",
                                      headers: {
                                        "Access-Control-Allow-Origin":
                                          process.env.NODE_ENV === "development"
                                            ? "http://localhost:3000"
                                            : "https://apiv1.reserv4me.com",
                                        "Content-Type": "application/json",
                                        Authorization: "internalvar.apikey",
                                      },
                                      data: {
                                        hotel_name:
                                          this.state.basic_info.hotel_name,
                                        hotel_email:
                                          this.state.basic_info.hotel_email,
                                      },
                                      withCredentials: true,
                                    })
                                      .then((result) => {
                                        if (result.data.status === "ok") {
                                          this.setState({
                                            show_successful_modal: true,
                                            isloadingInventoryCreatedFN: true,
                                          });
                                        } else {
                                          /*console.log(
                                "something went wrong please close and wait for Reserv4me to be in contact with you"
                              );*/
                                        }
                                      })
                                      .catch((error) => {
                                        this.props.history.push({
                                          pathname: `/`,
                                        });
                                      });
                                  } else {
                                  }
                                })
                                .catch((error) => {
                                  this.props.history.push({
                                    pathname: `/`,
                                  });
                                });
                            })
                            .catch((error) => {
                              this.props.history.push({
                                pathname: `/`,
                              });
                            });
                        })
                        .catch((error) => {
                          this.props.history.push({
                            pathname: `/`,
                          });
                        });
                    })
                    .catch((error) => {
                      this.props.history.push({
                        pathname: `/`,
                      });
                    });

                  /*}else{
                                          throw "The basic addidtion failed"
                                      }*/
                })
                .catch((error) => {
                  this.props.history.push({
                    pathname: `/`,
                  });
                });
            } else {
              return null;
            }

            //console.log("app init", result)
          })
          .catch((error) => {
            this.props.history.push({
              pathname: `/`,
            });
          });
      }
    );
    //this.activate_loading();
    // axios({
    //   method: "post",
    //   url:
    //     process.env.NODE_ENV === "development"
    //       ? "http://localhost:3000/app-initialization"
    //       : "https://apiv1.reserv4me.com/app-initialization",
    //   headers: {
    //     "Access-Control-Allow-Origin":
    //       process.env.NODE_ENV === "development"
    //         ? "http://localhost:3000"
    //         : "https://apiv1.reserv4me.com",
    //     "Content-Type": "application/json",
    //     Authorization: "internalvar.apikey",
    //   },
    //   data: {},
    //   withCredentials: true,
    // }).then((result) => {
    // //   console.log(
    // //     "🚀 ~ file: Main_Sign_up.js ~ line 1254 ~ Main_Sign_up ~ result",
    // //     result
    // //   );
    //   if (result.data.success === true) {
    //     this.setState({
    //       internalapi_id: result.data.internalapi_id,
    //       externalapi_id: result.data.externalapi_id,
    //     });
    //     return axios({
    //       method: "post",
    //       url:
    //         process.env.NODE_ENV === "development"
    //           ? "http://localhost:3000/hotel-basic-info"
    //           : "https://apiv1.reserv4me.com/hotel-basic-info",
    //       headers: {
    //         "Access-Control-Allow-Origin":
    //           process.env.NODE_ENV === "development"
    //             ? "http://localhost:3000"
    //             : "https://apiv1.reserv4me.com",
    //         "Content-Type": "application/json",
    //         Authorization: "internalvar.apikey",
    //       },
    //       data: {
    //         basic_info: this.state.basic_info,
    //         hotel_min_price: this.state.policy_info.minimum_room_cost,
    //         externalapi_id: this.state.externalapi_id,
    //         internalapi_id: this.state.internalapi_id,
    //         stripe_user_id: this.state.stripe_acct_id,
    //       },
    //       withCredentials: true,
    //     }).then((result) => {
    //       //if((result.data.handled_basic===true)){
    //       console.log("hotelid", result);

    //       this.setState((prevState) => {
    //         return {
    //           hotel_web_id: result.data.hotel_web_id,
    //           hotel_local_id: result.data.hotel_local_id,
    //         };
    //       });
    //       return axios({
    //         method: "post",
    //         url:
    //           process.env.NODE_ENV === "development"
    //             ? "http://localhost:3000/hotel-policy-info"
    //             : "https://apiv1.reserv4me.com/hotel-policy-info",
    //         headers: {
    //           "Access-Control-Allow-Origin":
    //             process.env.NODE_ENV === "development"
    //               ? "http://localhost:3000"
    //               : "https://apiv1.reserv4me.com",
    //           "Content-Type": "application/json",
    //           Authorization: "internalvar.apikey",
    //         },
    //         data: {
    //           policy_info: this.state.policy_info,
    //           hotel_web_id: result.data.hotel_web_id,
    //           hotel_name: this.state.basic_info.hotel_name,
    //           hotel_local_id: this.state.hotel_local_id,
    //         },
    //         withCredentials: true,
    //       }).then((result) => {
    //         console.log("result id", result);

    //         this.setState((prevState) => {
    //           return {
    //             hotel_tax_online_id: result.data.internalvar.hotel_tax_online,
    //             hotel_policy_id: result.data.internalvar.policyid,
    //           };
    //         });

    //         return axios({
    //           method: "post",
    //           url:
    //             process.env.NODE_ENV === "development"
    //               ? "http://localhost:3000/discount-save"
    //               : "https://apiv1.reserv4me.com/discount-save",
    //           headers: {
    //             "Access-Control-Allow-Origin":
    //               process.env.NODE_ENV === "development"
    //                 ? "http://localhost:3000"
    //                 : "https://apiv1.reserv4me.com",
    //             "Content-Type": "application/json",
    //             Authorization: "internalvar.apikey",
    //           },
    //           data: {
    //             discounts_list: this.state.discounts_list,
    //             hotel_local_id: this.state.hotel_local_id,
    //           },
    //           withCredentials: true,
    //         }).then((result) => {
    //           return axios({
    //             method: "post",
    //             url:
    //               process.env.NODE_ENV === "development"
    //                 ? "http://localhost:3000/handle-room-type"
    //                 : "https://apiv1.reserv4me.com/handle-room-type",
    //             headers: {
    //               "Access-Control-Allow-Origin":
    //                 process.env.NODE_ENV === "development"
    //                   ? "http://localhost:3000"
    //                   : "https://apiv1.reserv4me.com",
    //               "Content-Type": "application/json",
    //               Authorization: "internalvar.apikey",
    //             },
    //             data: {
    //               room_type_list: this.state.room_type_list,
    //               extraguestc: this.state.policy_info.additional_guest_cost,
    //               hotel_web_id: this.state.hotel_web_id,
    //               hotel_policy_id: this.state.hotel_policy_id,
    //               breakfastid: this.state.policy_info.breakfast_type,
    //               wifi_id: this.state.policy_info.wifi_type,
    //               hotel_local_id: this.state.hotel_local_id,
    //             },
    //             withCredentials: true,
    //           }).then((result) => {
    //             this.setState((prevState) => {
    //               return { local_r_types: result.data.room_type_list };
    //             });
    //             console.log("after woom inserted", result);

    //             return axios({
    //               method: "post",
    //               url:
    //                 process.env.NODE_ENV === "development"
    //                   ? "http://localhost:3000/range-handler"
    //                   : "https://apiv1.reserv4me.com/range-handler",
    //               headers: {
    //                 "Access-Control-Allow-Origin":
    //                   process.env.NODE_ENV === "development"
    //                     ? "http://localhost:3000"
    //                     : "https://apiv1.reserv4me.com",
    //                 "Content-Type": "application/json",
    //                 Authorization: "internalvar.apikey",
    //               },
    //               data: {
    //                 room_range_list: this.state.room_range_list,
    //                 local_r_types: this.state.local_r_types,
    //                 total_num_room: this.state.basic_info.total_numb_rooms,
    //                 hotel_local_id: this.state.hotel_local_id,
    //               },
    //               withCredentials: true,
    //             })
    //               .then((result) => {
    //                 console.log("calendar", result);

    //                 return axios({
    //                   method: "post",
    //                   url:
    //                     process.env.NODE_ENV === "development"
    //                       ? "http://localhost:3000/receivedby-addition"
    //                       : "https://apiv1.reserv4me.com/receivedby-addition",
    //                   headers: {
    //                     "Access-Control-Allow-Origin":
    //                       process.env.NODE_ENV === "development"
    //                         ? "http://localhost:3000"
    //                         : "https://apiv1.reserv4me.com",
    //                     "Content-Type": "application/json",
    //                     Authorization: "internalvar.apikey",
    //                   },
    //                   data: {
    //                     channel_type_list: this.state.channel_type_list,
    //                     hotel_local_id: this.state.hotel_local_id,
    //                   },
    //                   withCredentials: true,
    //                 });
    //               })
    //               .then((result) => {
    // //                 console.log(
    // //                   "🚀 ~ file: Main_Sign_up.js ~ line 1461 ~ Main_Sign_up ~ .then ~ result",
    // //                   result
    // //                 );

    //                 return axios({
    //                   method: "post",
    //                   url:
    //                     process.env.NODE_ENV === "development"
    //                       ? "http://localhost:3000/imageuploadrooms"
    //                       : "https://apiv1.reserv4me.com/imageuploadrooms",
    //                   headers: {
    //                     /*

    //                       process.env.NODE_ENV === "development"
    //                         ? "http://localhost:3000"
    //                         : "https://apiv1.reserv4me.com",
    //                     */
    //                     "Access-Control-Allow-Origin": "*",
    //                     "Content-Type": "application/json",
    //                     Authorization: "internalvar.apikey",
    //                   },
    //                   data: {
    //                     hotel_logo: this.state.temp_logo_URL,
    //                     room_images_room: this.state.selectedRoomImages,
    //                     local_r_types: this.state.local_r_types,
    //                     hotel_web_id: this.state.hotel_web_id,
    //                     hotel_local_id: this.state.hotel_local_id,
    //                   },
    //                   withCredentials: true,
    //                 });
    //               })
    //               .then((result) => {
    //                 console.log("in test", result.data);
    //                 if (result.data.sucessful === true) {
    //                   return axios({
    //                     method: "post",
    //                     url:
    //                       process.env.NODE_ENV === "development"
    //                         ? "http://localhost:3000/SignUp_Email"
    //                         : "https://apiv1.reserv4me.com/SignUp_Email",
    //                     headers: {
    //                       "Access-Control-Allow-Origin":
    //                         process.env.NODE_ENV === "development"
    //                           ? "http://localhost:3000"
    //                           : "https://apiv1.reserv4me.com",
    //                       "Content-Type": "application/json",
    //                       Authorization: "internalvar.apikey",
    //                     },
    //                     data: {
    //                       hotel_name: this.state.basic_info.hotel_name,
    //                     },
    //                     withCredentials: true,
    //                   }).then((result) => {
    //                     if (result.data.status === "ok") {
    //                       this.setState({
    //                         show_successful_modal: true,
    //                         isloadingInventoryCreatedFN: true,
    //                       });
    //                     } else {
    //                       console.log(
    //                         "something went wrong please close and wait for Reserv4me to be in contact with you"
    //                       );
    //                     }
    //                   });
    //                 } else {
    //                   console.log(
    //                     "something went wrong please close and wait for Reserv4me to be in contact with you"
    //                   );
    //                 }
    //               });
    //           });
    //         });
    //       });

    //       /*}else{
    //                                 throw "The basic addidtion failed"
    //                             }*/
    //     });
    //   } else {
    //     return null;
    //   }

    //   //console.log("app init", result)
    // });

    /*resp=ipcRenderer.sendSync('hotel-basic-info',req);
        console.log("final",resp)*/
  };

  onLogout_pressed = () => {
    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/logout-local"
          : "https://apiv1.reserv4me.com/logout-local",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
      },
      data: {},
      withCredentials: true,
    })
      .then((result) => {
        window.sessionStorage.clear();
        //console.log(result.data);
        this.props.history.push({
          pathname: `/`,
        });
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
      });
  };

  onAddChannel = () => {
    console.log(
      "initial",
      this.state.channel_type_temp,
      this.state.channel_type_temp.channel_name !== "" &&
        (this.state.channel_type_temp.channel_immediateness !== "" ||
          this.state.channel_type_temp.channel_immediateness !== 0)
    );
    if (
      this.state.adding_received_by === true &&
      this.state.channel_initial_change === true
    ) {
      if (
        this.state.channel_type_temp.channel_name !== "" &&
        this.state.channel_type_temp.channel_immediateness !== "" &&
        this.state.channel_type_temp.channel_immediateness !== 0
      ) {
        //console.log("reseting channel");
        this.setState((prevState) => {
          const channel_list = prevState.channel_type_list;
          channel_list[channel_list.length] = {
            ...prevState.channel_type_temp,
          };

          return {
            channel_type_list: channel_list,
            adding_received_by: !prevState.adding_received_by,
            channel_type_temp: {
              channel_num: "",
              channel_name: "",
              channel_immediateness: 0,
            },
            empty_channel_type: false,
            channel_initial_change: false,
          };
        });
      }
    } else {
      this.setState((prevState) => ({
        adding_received_by: !prevState.adding_received_by,
      }));
    }
  };

  onChannelAddChange = (e) => {
    let tempvar = e.target;
    console.log(
      "🚀 ~ file: Main_Sign_up.js ~ line 1613 ~ Main_Sign_up ~ tempvar",
      tempvar.value
    );
    this.setState((prevState) => {
      const numval = prevState.channel_type_list.length + 1;
      const list = prevState.channel_type_temp;
      const validation = prevState.channel_type_temp_valid;
      list[tempvar.id] = tempvar.value;
      list["channel_num"] = numval;
      validation[tempvar.id] = tempvar.value === "" ? false : true;
      return {
        channel_type_temp: list,
        channel_type_temp_valid: validation,
        channel_initial_change: true,
        //channel_is_verified:false
      };
    });
  };

  stripe_acc_setter = (acc_id) => {
    //console.log("here adding account", acc_id);
    this.setState((prevState) => ({ stripe_acct_id: acc_id }));
  };

  stepValidated = (direction) => {
    if (direction === "up") {
      switch (this.state.stage) {
        case 0: {
          return true;
        }
        case 1: {
          return true;
        }
        case 2: {
          return true;
        }
        case 3: {
          let verification = true;
          //console.log(this.state.basic_info_validation);
          for (const prop in this.state.basic_info_validation) {
            ////console.log(prop, this.state.basic_info_validation[prop]);
            if (verification === false) {
            } else {
              if (prop === "total_numb_rooms") {
                //console.log("prop totoal num");
              } else {
                if (this.state.basic_info_validation[prop] === true) {
                  verification = false;
                  this.setState((prevState) => {
                    return { basic_is_verified: true };
                  });
                }
              }
            }
          }
          if (this.state.hotel_logo === "" || this.state.temp_logo_URL === "") {
            verification = false;
            this.setState((prevState) => {
              return { hotel_logo_verification: false };
            });
          }
          ////console.log(verification);

          return verification;
        }
        case 4: {
          let verification = true;
          for (const prop in this.state.policy_info_validation) {
            console.log(prop, this.state.policy_info_validation[prop]);
            if (verification === false) {
            } else {
              if (
                prop === "later_payment" ||
                prop === "payment_schedule_date"
              ) {
                //console.log("prop totoal num");
              } else {
                if (this.state.policy_info_validation[prop] === true) {
                  verification = false;
                  this.setState((prevState) => {
                    return { policycheckv1: true };
                  });
                }
              }
            }
          }
          return verification;
        }
        case 5: {
          let verification = true;
          if (this.state.policy_info.later_payment === "true") {
            for (const prop in this.state.policy_info_validation) {
              ////console.log(prop, this.state.basic_info_validation[prop]);
              if (verification === false) {
              } else {
                if (prop !== "payment_schedule_date") {
                } else {
                  if (this.state.policy_info_validation[prop] === true) {
                    verification = false;
                    this.setState((prevState) => {
                      return { policy_late_verified: true };
                    });
                  }
                }
              }
            }
          }
          if (this.state.adding_discounts === true) {
            verification = false;
            this.setState((prevState) => {
              return { stage_policies_disc_verified: true };
            });
          }
          return verification;
        }
        case 6: {
          let verification = true;
          if (this.state.channel_type_list.length === 0) {
            verification = false;
            this.setState((prevState) => {
              return { channel_is_verified: true };
            });
          }
          return verification;
        }
        case 7: {
          let verification = true;

          if (
            this.state.room_type_list.length === 0 ||
            this.state.basic_info_validation.total_numb_rooms === true ||
            this.state.adding_room_type === true
          ) {
            verification = false;
            this.setState((prevState) => {
              return { room_type_verified: true };
            });
          }

          return verification;
        }
        case 8: {
          let verification = true;
          for (let i = 0; i < this.state.room_type_list.length; i++) {
            if (verification === false) {
            } else {
              ////console.log(i, this.state.selectedRoomImages[i]);
              if (
                this.state.selectedRoomImages[i].images.length === 0 ||
                this.state.room_type_list[i].room_description === ""
              ) {
                verification = false;
                this.setState((prevState) => {
                  return { image_stage_verified: true };
                });
              } else {
                for (
                  let u = 0;
                  u < this.state.selectedRoomImages[i].images.length;
                  u++
                ) {
                  if (verification === false) {
                  } else {
                    if (
                      this.state.selectedRoomImages[i].images[u].title == "" ||
                      this.state.selectedRoomImages[i].images[u].description ==
                        ""
                    ) {
                      verification = false;
                      this.setState((prevState) => {
                        return { image_stage_verified: true };
                      });
                    }
                  }
                }
              }
            }
          }

          return verification;
        }
        case 9: {
          let verification = true;
          if (
            this.state.room_range_list.length === 0 ||
            this.state.all_ranges_covered === false
          ) {
            verification = false;
            this.setState((prevState) => {
              return { range_is_verified: true };
            });
          }
          return verification;
        }
        case 10: {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  set_str_btn_disabled = (status) => {
    this.setState((prevState) => {
      return { str_btn_deactivated: status };
    });
  };
  set_continue_disabled = (type) => {
    this.setState((prevState) => {
      return {
        continue_btn_deactivated: type === "enable" ? false : true,
      };
    });
  };

  close_Stripe_Modal = () => {
    this.setState((prevState) => {
      return { show_modal_stripe: !prevState.show_modal_stripe };
    });
  };

  close_succesful_Modal = () => {
    this.setState((prevState) => {
      return { show_successful_modal: !prevState.show_successful_modal };
    });
  };
  close_Missing_Modal = () => {
    this.setState((prevState) => {
      return { show_modal_info_miss: !prevState.show_modal_info_miss };
    });
  };

  onStripe_loading_Change = (status) => {
    this.setState((prevState) => {
      return { loading_stripe: status };
    });
  };

  stripe_verification_setter = (type) => {
    this.setState((prevState) => {
      return { stripe_verification_passed: type };
    });
  };

  MustDelete_tomovetoteststage = () => {
    this.setState((prevState) => ({
      stage: 7,
    }));
  };

  render() {
    return (
      <>
        {(() => {
          if (this.state.temp_logo_URL !== "") {
            return (
              <div className="shadow-xl max-w-120p translate-x-75vw translate-y-8">
                <img className="image_logo" src={this.state.temp_logo_URL} />
              </div>
            );
          }
        })()}
        {
          //<Button onClick={this.MustDelete_tomovetoteststage}>New Stage</Button>
        }
        <div className="p-8 rounded shadow-3xl mx-auto w-90 my-20 ">
          {/* <Row
            hidden={this.state.stage === 0 ? false : true}
            className="Return_Main-btn"
          ></Row> */}
          <div className="">
            {(() => {
              switch (this.state.stage) {
                case 0: {
                  return (
                    <>
                      <Welcome_Stage />
                    </>
                  );
                }
                case 1: {
                  return (
                    <>
                      <IsAdd2Online
                        isTermsAgreed={this.state.isTermsAgreed}
                        TermsAgreedto_Change={this.TermsAgreedto_Change}
                        set_continue_disabled={this.set_continue_disabled}
                      />
                    </>
                  );
                }
                case 2: {
                  return (
                    <>
                      <StripeConnection
                        stripe_acc_setter={this.stripe_acc_setter}
                        set_str_btn_disabled={this.set_str_btn_disabled}
                        set_continue_disabled={this.set_continue_disabled}
                        str_btn_deactivated={this.state.str_btn_deactivated}
                        str_acc={this.state.stripe_acct_id}
                        close_Stripe_Modal={this.close_Stripe_Modal}
                        loading_stripe={this.state.loading_stripe}
                        onStripe_loading_Change={this.onStripe_loading_Change}
                        stripe_acct_id={this.state.stripe_acct_id}
                        stripe_verification_passed={
                          this.state.stripe_verification_passed
                        }
                        stripe_verification_setter={
                          this.stripe_verification_setter
                        }
                        close_Missing_Modal={this.close_Missing_Modal}
                      />
                    </>
                  );
                }
                case 3: {
                  return (
                    <>
                      <Basic_Info
                        onLogoImgSelected={this.onLogoImgSelected}
                        selectedFiles={this.state.hotel_logo}
                        file_too_Large={this.state.file_too_Large}
                        basic_info={this.state.basic_info}
                        onBasicInfo_Change={this.onBasicInfo_Change}
                        basic_info_validation={this.state.basic_info_validation}
                        hotel_logo_verification={
                          this.state.hotel_logo_verification
                        }
                        basic_is_verified={this.state.basic_is_verified}
                      />
                    </>
                  );
                }
                case 4: {
                  return (
                    <>
                      <Additional_Info
                        policyonlinedata={this.state.policyonlinedata}
                        onPolicyChange={this.onPolicyChange}
                        policycheckv1={this.state.policycheckv1}
                        policy_info={this.state.policy_info}
                        policy_info_validation={
                          this.state.policy_info_validation
                        }
                      />
                    </>
                  );
                }
                case 5: {
                  return (
                    <>
                      <Additional_Info_2
                        onPolicyChange={this.onPolicyChange}
                        policy_info={this.state.policy_info}
                        onAddDiscounts={this.onAddDiscounts}
                        adding_discounts={this.state.adding_discounts}
                        discount_templist={this.state.discount_templist}
                        onDiscountChange={this.onDiscountChange}
                        discount_temp_valid={this.state.discount_temp_valid}
                        empty_discount={this.state.empty_discount}
                        discounts_list={this.state.discounts_list}
                        policy_info_validation={
                          this.state.policy_info_validation
                        }
                        policy_late_verified={this.state.policy_late_verified}
                        stage_policies_disc_verified={
                          this.state.stage_policies_disc_verified
                        }
                      />
                    </>
                  );
                }
                case 6: {
                  return (
                    <>
                      <ReceivedBy_info
                        adding_received_by={this.state.adding_received_by}
                        onAddChannel={this.onAddChannel}
                        onChannelAddChange={this.onChannelAddChange}
                        channel_type_list={this.state.channel_type_list}
                        channel_type_temp_valid={
                          this.state.channel_type_temp_valid
                        }
                        channel_type_temp={this.state.channel_type_temp}
                        empty_channel_type={this.state.empty_channel_type}
                        channel_initial_change={
                          this.state.channel_initial_change
                        }
                        channel_is_verified={this.state.channel_is_verified}
                      />
                    </>
                  );
                }
                case 7: {
                  return (
                    <>
                      <Rooms_and_Rates
                        basic_info={this.state.basic_info}
                        basic_info_validation={this.state.basic_info_validation}
                        onBasicInfo_Change={this.onBasicInfo_Change}
                        room_type_list={this.state.room_type_list}
                        room_type_temp_list={this.state.room_type_temp_list}
                        room_type_temp_valid={this.state.room_type_temp_valid}
                        adding_room_type={this.state.adding_room_type}
                        empty_room_type={this.state.empty_room_type}
                        room_type_initial_Change={
                          this.state.room_type_initial_Change
                        }
                        onAddRooms={this.onAddRooms}
                        onRoomTypeChange={this.onRoomTypeChange}
                        room_type_verified={this.state.room_type_verified}
                      />
                    </>
                  );
                }
                case 8: {
                  return (
                    <>
                      <Rooms_and_Rates_Additional
                        Room_Images_Selected={this.Room_Images_Selected}
                        preview_selected_Images={
                          this.state.preview_selected_Images
                        }
                        current_room_Selected={this.state.current_room_Selected}
                        room_type_list={this.state.room_type_list}
                        current_Room_selected={this.current_Room_selected}
                        selectedRoomImages={this.state.selectedRoomImages}
                        onImgDescriptionChange={this.onImgDescriptionChange}
                        onRoom_Description_Change={
                          this.onRoom_Description_Change
                        }
                        image_stage_verified={this.state.image_stage_verified}
                      />
                    </>
                  );
                }
                case 9: {
                  return (
                    <>
                      <Room_Ranges
                        room_type_list={this.state.room_type_list}
                        total_numb_rooms={
                          this.state.basic_info.total_numb_rooms
                        }
                        onSliderValueChange={this.onSliderValueChange}
                        temp_range_low={this.state.temp_range_low}
                        temp_range_high={this.state.temp_range_high}
                        room_range_list={this.state.room_range_list}
                        adding_room_range={this.state.adding_room_range}
                        onAddingRoom_range={this.onAddingRoom_range}
                        onRange_Selection_Change={this.onRange_Selection_Change}
                        room_selection_empty={this.state.room_selection_empty}
                        range_overlap={this.state.range_overlap}
                        temp_range_sel_rm={this.state.temp_range_sel_rm}
                        all_ranges_covered={this.state.all_ranges_covered}
                        close_adding_range={this.close_adding_range}
                        range_is_verified={this.state.range_is_verified}
                      />
                    </>
                  );
                }
                case 10: {
                  return (
                    <>
                      <Finalize_Signup
                        create_Inventory={this.create_Inventory}
                        temporary_test={this.temporary_test}
                        isBtnFinishDisabled={this.state.isBtnFinishDisabled}
                      />
                    </>
                  );
                }
              }
            })()}
          </div>
          <div className="flex flex-wrap justify-center gap-4 items-center pt-6">
            <button
              className="
              lg:order-1
              order-2
              px-6
              py-2.5
              bg-blue-600
              text-white
              font-medium
              text-xs
              leading-tight
              uppercase
              rounded
              shadow-md
              hover:bg-blue-700 hover:shadow-lg
              focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
              active:bg-blue-800 active:shadow-lg
              transition
              duration-150
              ease-in-out
              ml-1"
              onClick={() => {
                this.progress_Update("down");
                //this.stage_Changer("down");
              }}
              hidden={this.state.stage === 0 ? true : false}
            >
              Back
            </button>
            <div className="h-4 w-full  lg:w-1/2 order-1 lg:order-2">
              <div className=" bg-gray-200 rounded-full ">
                <div
                  className=" bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full"
                  style={{ width: this.state.progress + "%" }}
                >{`${this.state.progress}%`}</div>
              </div>
            </div>
            {/* <ProgressBar
                className="center_progress"
                now={this.state.progress}
                label={`${this.state.progress}%`}
              ></ProgressBar> */}
            <button
              className={`lg:order-3
              order-3
              px-6
              py-2.5
              font-medium
              text-xs
              leading-tight
              uppercase
              rounded
              shadow-md
              hover:bg-blue-700 hover:shadow-lg
              active:bg-blue-800 active:shadow-lg
              transition
              duration-150
              ease-in-out
              ml-1 ${
                this.state.continue_btn_deactivated === true
                  ? "bg-blue-300 text-white"
                  : this.state.stage === 1 && this.state.isTermsAgreed === false
                  ? "bg-blue-300 text-white"
                  : "bg-blue-600 text-white"
              }`}
              onClick={() => {
                this.progress_Update("up");
                //this.stage_Changer("up");
              }}
              disabled={
                this.state.continue_btn_deactivated === true
                  ? true
                  : this.state.stage === 1 && this.state.isTermsAgreed === false
                  ? true
                  : false
              }
              hidden={this.state.stage === 10 ? true : false}
            >
              {this.state.stage === 0 ? "Lets get started!" : "Continue"}
            </button>
          </div>
        </div>
        <Dialog_Page
          show={this.state.image_Dialog}
          handleClose={this.handle_close_Room_Image_Dialog}
        />
        <div className="userbox-screen">
          <p>{`Logged in as: ${window.sessionStorage.getItem("uname")}`}</p>
        </div>
        <Modal_Stripe_Failed
          show={this.state.show_modal_stripe}
          handleClose={this.close_Stripe_Modal}
        />
        <Modal_Stripe_Missing_Info
          show={this.state.show_modal_info_miss}
          handleClose={this.close_Missing_Modal}
        />
        <Inventory_successful_Modal
          show={this.state.show_successful_modal}
          handleClose={this.onLogout_pressed}
        />

        <div
          hidden={this.state.isloadingInventoryCreatedFN}
          className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  "
        >
          <div className="loading"></div>
          <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
            <p className="text-2xl text-white -translate-y-16">Loading...</p>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Main_Sign_up);
