import React from "react";

class Welcome_Stage extends React.Component {
  render() {
    return (
      <>
        <div className="mx-auto w-full md:w-8/12 space-y-2">
          <h1 className="mx-auto  font-bold text-lg text-center ">
            Welcome to Reserv4me inventory management
          </h1>
          <p className="mx-auto">
            This page will help you create your initial inventory where initial
            basic information is required such as base rates,tax rate,room types
            and more
          </p>
          <p className="w-90 mx-auto text-xl text-center pt-36">
            Please click next to continue
          </p>
        </div>
      </>
    );
  }
}

export default Welcome_Stage;
