import React from "react";

function FieldGroup({
  validationState,
  id,
  mdsize,
  label,
  help,
  type,
  isInvalid,
  ...props
}) {
  return (
    <div className="px-3">
      <label>{label}</label>

      <input
        id={id}
        type={type}
        {...props}
        className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
      />
      <p
        className="text-red-500 text-sm mt-2"
        hidden={isInvalid === false ? true : false}
      >
        {help}
      </p>
    </div>
  );
}

class Basic_Info extends React.Component {
  render() {
    const {
      basic_info,
      onBasicInfo_Change,
      basic_info_validation,
      hotel_logo_verification,
      basic_is_verified,
      new_hotel_logo,
      isBlocking,
    } = this.props;
    return (
      <>
        <div className="">
          <div className="space-y-2">
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
                <div>
                  <img
                    src={
                      new_hotel_logo === ""
                        ? basic_info.hotel_logo
                        : new_hotel_logo
                    }
                    width="200"
                  />
                  <div className="w-full">
                    {(() => {
                      if (this.props.file_too_Large === true) {
                        return (
                          <p className="Error_Paragraph">
                            Choose a file that is less than 100kB
                          </p>
                        );
                      } else if (this.props.selectedFiles === "") {
                        if (this.props.hotel_logo_verification === false) {
                          return (
                            <p className="Error_Paragraph">
                              {" "}
                              Please add a logo that represents your company
                            </p>
                          );
                        } else {
                          return <p></p>;
                        }
                      } else {
                        return (
                          <>
                            <p>{`${this.props.selectedFiles[0].name} | ${(
                              this.props.selectedFiles[0].size / 1e6
                            ).toFixed(2)} MB`}</p>
                          </>
                        );
                      }
                    })()}
                  </div>
                </div>
                <div className="lg:relative flex md:inline justify-end">
                  <input
                    type="file"
                    onChange={this.props.onLogoImgSelected}
                    ref={(fileInput) => (this.fileInput = fileInput)}
                    style={{ display: "none" }}
                    multiple={false}
                    accept="image/*"
                  ></input>
                  <button
                    className="font-normal  bg-blue-500 text-white p-2 rounded inline lg:absolute lg:bottom-0 "
                    variant="primary"
                    onClick={() => this.fileInput.click()}
                  >
                    Update From File
                  </button>
                </div>
              </div>
              <FieldGroup
                id="hotelemail"
                label="Hotel Primary Email"
                type="email"
                placeholder="Enter Hotel Email"
                value={basic_info.hotelemail}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotelemail === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotelemail
                }
                help="Add a valid email address"
              />
            </div>
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2 ">
              <FieldGroup
                id="hotel_phone"
                label="Hotel Phone"
                type="tel"
                placeholder="Enter Hotel Phone"
                value={basic_info.hotel_phone}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_phone === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_phone
                }
                help={"Add a valid phone number"}
              />
              <FieldGroup
                id="hotel_fax"
                label="Hotel Fax"
                type="tel"
                placeholder="Enter Hotel Fax"
                value={basic_info.hotel_fax}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_fax === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_fax
                }
                help={"Add a valid fax number"}
              />
            </div>
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="hotel_address"
                label="Hotel Address"
                type="text"
                placeholder="Enter Hotel Address"
                value={basic_info.hotel_address}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_address === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_address
                }
                help="Add a valid address"
              />
              <FieldGroup
                id="hotel_town"
                label="Hotel Town"
                type="text"
                placeholder="Enter Hotel Town"
                value={basic_info.hotel_town}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_town === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_town
                }
                help={"Add a valid town"}
              />
            </div>
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="hotel_state"
                label="Hotel State"
                type="text"
                placeholder="Enter Hotel State"
                value={basic_info.hotel_state}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_state === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_state
                }
                help="Add a valid State"
              />
              <FieldGroup
                id="hotel_zip_code"
                label="Hotel ZipCode"
                type="text"
                placeholder="Enter Hotel Zip Code"
                value={basic_info.hotel_zip_code}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_zip_code === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_zip_code
                }
                help={"Add a valid ZipCode"}
              />
            </div>
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="hotel_min_price"
                label="Hotel minimum price"
                type="text"
                placeholder="Enter Hotel minimum price"
                value={basic_info.hotel_min_price}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotel_min_price === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotel_min_price
                }
                help={"Add a minimum price per night"}
              />
              <FieldGroup
                id="hotelfblink"
                label="Hotel Facebook Page Link"
                type="text"
                placeholder="Enter Hotel Facebook Page Link"
                value={basic_info.hotelfblink}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotelfblink === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotelfblink
                }
                help="Add a valid address in the form https://www.domain.com"
              />
            </div>
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="hoteltwitter"
                label="Hotel Twitter Link"
                type="text"
                placeholder="Enter Hotel Twitter Link"
                value={basic_info.hoteltwitter}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hoteltwitter === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hoteltwitter
                }
                help={"Add a valid address in the form https://www.domain.com"}
              />
              <FieldGroup
                id="hotelinsta"
                label="Hotel Instagram Page Link"
                type="text"
                placeholder="Enter Hotel Instagram Page Link"
                value={basic_info.hotelinsta}
                onChange={(e) => {
                  onBasicInfo_Change(e);
                }}
                isInvalid={
                  basic_info.hotelinsta === "" && !basic_is_verified
                    ? false
                    : basic_info_validation.hotelinsta
                }
                help="Add a valid address in the form https://www.domain.com"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Basic_Info;
