import React from "react";

function FieldGroup({
  validationState,
  id,
  label,
  help,
  type,
  options,
  onChange,
  ...props
}) {
  return (
    <div className=" px-3 ">
      <label
        className="block text-black text-sm font-medium mb-1"
        htmlFor={label}
      >
        {label} <span className="text-red-600">*</span>
      </label>

      {type === "textarea" ? (
        <textarea
          id={id}
          {...props}
          onChange={onChange}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner "
        ></textarea>
      ) : type === "select" ? (
        <select
          id={id}
          onChange={onChange}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
        >
          {options()}
        </select>
      ) : (
        <input
          id={id}
          type={type}
          onChange={onChange}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
          {...props}
        />
      )}

      {type === "textarea" ? (
        <p hidden={validationState} className=" text-sm mt-2 flex justify-end">
          {help}
        </p>
      ) : (
        <p hidden={validationState} className="text-red-500 text-sm mt-2">
          {help}
        </p>
      )}
    </div>
  );
}

class Additional_Info extends React.Component {
  render() {
    const {
      policyonlinedata,
      onPolicyChange,
      policy_info,
      policy_info_validation,
      policycheckv1,
    } = this.props;

    return (
      <>
        <div>
          <h1 className="text-lg">{`Policies & Charges`}</h1>
          <div className="space-y-2">
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="total_taxes"
                type="text"
                label="Total % of Tax & fees"
                placeholder="Enter Total % of Tax & Fees"
                onChange={onPolicyChange}
                value={policy_info.total_taxes}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.total_taxes
                }
                help={`Enter a tax rate in the form XX.XX with numbers only
                preferably in this form Note: XXX.XX means 100+% added cost
                or twice the before taxes cost`}
              />
              <FieldGroup
                id="pet_policy"
                label="Pet Policy"
                type="select"
                value={policy_info.pet_policy}
                onChange={onPolicyChange}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.pet_policy
                }
                help="Select a pet policy from the drop down"
                options={() => {
                  return (
                    <>
                      <option key={`PetPolicy-null`} value={0}>
                        Choose...
                      </option>
                      {(() => {
                        if (policyonlinedata.length !== 0) {
                          return policyonlinedata.pet_policy.map(
                            (current, index) => {
                              return (
                                <option
                                  key={`PetPolicy-${current.petpolicyid}`}
                                  value={current.petpolicyid}
                                >
                                  {current.pet_policy}
                                </option>
                              );
                            }
                          );
                        }
                      })()}
                    </>
                  );
                }}
              />
            </div>
            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="parking_policy"
                type="select"
                label="Parking Policy"
                value={policy_info.parking_policy}
                onChange={onPolicyChange}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.parking_policy
                }
                help={`Select a parking policy from the drop down`}
                options={() => {
                  return (
                    <>
                      <option value={`0`}>Choose...</option>
                      {(() => {
                        if (policyonlinedata.length !== 0) {
                          return policyonlinedata.parking_policy.map(
                            (current, index) => {
                              return (
                                <option
                                  key={`ParkingPolicy-${current.parking_id}`}
                                  value={current.parking_id}
                                >
                                  {current.parking_policy}
                                </option>
                              );
                            }
                          );
                        }
                      })()}
                    </>
                  );
                }}
              />
              <FieldGroup
                id="breakfast_type"
                label="Breakfast type"
                type="select"
                value={policy_info.breakfast_type}
                onChange={onPolicyChange}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.breakfast_type
                }
                help="Select a pet policy from the drop down"
                options={() => {
                  return (
                    <>
                      <option value={`0`}>Choose...</option>
                      {(() => {
                        if (policyonlinedata.length !== 0) {
                          return policyonlinedata.breakfast_type.map(
                            (current, index) => {
                              return (
                                <option
                                  key={`Breakfast_type-${current.breakfasttypeid}`}
                                  value={current.breakfasttypeid}
                                >
                                  {current.breakfasttype}
                                </option>
                              );
                            }
                          );
                        }
                      })()}
                    </>
                  );
                }}
              />
            </div>

            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="wifi_type"
                type="select"
                label="Wi-Fi type"
                value={policy_info.wifi_type}
                onChange={onPolicyChange}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.wifi_type
                }
                help={`Select a Wifi type from the drop down`}
                options={() => {
                  return (
                    <>
                      <option value={`0`}>Choose...</option>
                      {(() => {
                        if (policyonlinedata.length !== 0) {
                          return policyonlinedata.wifi_type.map(
                            (current, index) => {
                              return (
                                <option
                                  key={`wifi_type-${current.wifi_type_id}`}
                                  value={current.wifi_type_id}
                                >
                                  {current.wifi_type}
                                </option>
                              );
                            }
                          );
                        }
                      })()}
                    </>
                  );
                }}
              />
              <FieldGroup
                id="free_cancellation_period"
                label="Free Cancellation Period prior to arrival"
                type="select"
                value={policy_info.free_cancellation_period}
                onChange={onPolicyChange}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.free_cancellation_period
                }
                help="Select a Free cancellation period prior to arrival"
                options={() => {
                  return (
                    <>
                      <option value={`0`}>Choose...</option>
                      {(() => {
                        if (policyonlinedata.length !== 0) {
                          return policyonlinedata.free_cancellation_period.map(
                            (current, index) => {
                              return (
                                <option
                                  key={`freecancellationtime-${current.freecancellationtimeid}`}
                                  value={current.freecancellationtimeid}
                                >{`${current.freetime} ${current.timeframe}`}</option>
                              );
                            }
                          );
                        }
                      })()}
                    </>
                  );
                }}
              />
            </div>

            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="down_payment_cost"
                type="select"
                label="Down Payment prior to arrival (percentage or night cost)"
                value={policy_info.down_payment_cost}
                onChange={onPolicyChange}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.down_payment_cost
                }
                help={`Select a Down Payment required before arrival from the drop
                down`}
                options={() => {
                  return (
                    <>
                      <option value={`0`}>Choose...</option>
                      {(() => {
                        if (policyonlinedata.length !== 0) {
                          return policyonlinedata.down_payment_cost.map(
                            (current, index) => {
                              return (
                                <option
                                  key={`downpayment-${current.downpaymentid}`}
                                  value={current.downpaymentid}
                                >{`${current.dpamount} ${current.typeofdp}`}</option>
                              );
                            }
                          );
                        }
                      })()}
                    </>
                  );
                }}
              />
              <FieldGroup
                id="cancellation_cost"
                label="Cancellation Cost If done outside Free Cancellation period"
                type="select"
                value={policy_info.cancellation_cost}
                onChange={onPolicyChange}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.cancellation_cost
                }
                help="Select a cancellation cost from the drop down"
                options={() => {
                  return (
                    <>
                      <option value={`0`}>Choose...</option>
                      {(() => {
                        if (policyonlinedata.length !== 0) {
                          return policyonlinedata.cancellation_cost.map(
                            (current, index) => {
                              return (
                                <option
                                  key={`cancellation_cost-${current.cancellationcostid}`}
                                  value={current.cancellationcostid}
                                >{`${current.cost} ${current.typeofcancel}`}</option>
                              );
                            }
                          );
                        }
                      })()}
                    </>
                  );
                }}
              />
            </div>

            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="checkin_time"
                type="select"
                label="Check-In Time"
                value={policy_info.checkin_time}
                onChange={onPolicyChange}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.checkin_time
                }
                help={`Select a Check in time from the drop down`}
                options={() => {
                  return (
                    <>
                      <option value={`0`}>Choose...</option>
                      {(() => {
                        if (policyonlinedata.length !== 0) {
                          return policyonlinedata.checkin_time.map(
                            (current, index) => {
                              return (
                                <option
                                  key={`checkin_time-${current.checkintimeid}`}
                                  value={current.checkintimeid}
                                >{`${current.timecheckin}`}</option>
                              );
                            }
                          );
                        }
                      })()}
                    </>
                  );
                }}
              />
              <FieldGroup
                id="checkout_time"
                label="Check-Out Time"
                type="select"
                value={policy_info.checkout_time}
                onChange={onPolicyChange}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.checkout_time
                }
                help="Select a Check out time from the drop down"
                options={() => {
                  return (
                    <>
                      <option value={`0`}>Choose...</option>
                      {(() => {
                        if (policyonlinedata.length !== 0) {
                          return policyonlinedata.checkout_time.map(
                            (current, index) => {
                              return (
                                <option
                                  key={`checkout_time-${current.checkouttimeid}`}
                                  value={current.checkouttimeid}
                                >{`${current.timecheckout}`}</option>
                              );
                            }
                          );
                        }
                      })()}
                    </>
                  );
                }}
              />
            </div>

            <div className="grid grid-flow-row lg:grid-flow-col lg:grid-cols-2">
              <FieldGroup
                id="minimum_room_cost"
                type="text"
                label="Enter Minimum Room Cost"
                onChange={onPolicyChange}
                value={policy_info.minimum_room_cost}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.minimum_room_cost
                }
                help={`Enter a minimum room cost, this will prevent you from
                setting a price too low`}
              />
              <FieldGroup
                id="additional_policies"
                type="textarea"
                label="Additional Policies (Enter here Custom Policies that you
                  want to show in all confirmations that you think are
                  relevant)"
                onChange={onPolicyChange}
                value={policy_info.additional_policies}
                validationState={
                  policycheckv1 === false
                    ? true
                    : policy_info_validation.additional_policies
                }
                help={`Are you sure no additional policies are necessary`}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Additional_Info;
