import React from "react";

import Notes_grid from "./notesAdded";

const Modal_notes = ({
  handleClose,
  show,
  value,
  onValueChanged,
  canMove,
  canMovechanged,
  onAddNoteButton,
  notesadded,
  isNotesInvalidated,
  pagedisplay,
}) => {
  return (
    <>
      <div
        className={`modal fade fixed top-0 left-0  w-full h-full outline-none overflow-x-hidden overflow-y-auto  bg-black/75 ${
          show === true ? "show block" : ""
        }`}
        id="exampleModal"
      >
        <div className="modal-dialog relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalLabel"
              >
                Notes Added:
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body relative p-4">
              <div>
                <div className="flex items-center justify-start w-full mb-12">
                  <label
                    for="toggleB"
                    className="flex items-center cursor-pointer"
                  >
                    {/* <!-- toggle --> */}
                    <div className="relative">
                      {/* <!-- input --> */}
                      <input
                        type="checkbox"
                        id="toggleB"
                        className="sr-only"
                        onChange={canMovechanged}
                      />
                      {/* <!-- line --> */}
                      <div
                        className={`block ${
                          canMove === true ? "bg-gray-400  " : " bg-blue-600 "
                        }  w-14 h-8 rounded-full`}
                      ></div>
                      {/* <!-- dot --> */}
                      <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                    </div>
                    {/* <!-- label --> */}
                    <div className="ml-3 text-gray-700 font-medium">
                      Check to prevent room being shifted
                    </div>
                  </label>
                </div>
                <div className="flex space-y-2 flex-wrap">
                  <label for="notes">Notes:</label>

                  <textarea
                    id="notes"
                    name="notes"
                    rows="4"
                    cols="50"
                    onChange={onValueChanged}
                    value={value}
                  ></textarea>
                  <div className="flex justify-end w-full">
                    <button
                      className="px-6
                              py-2.5
                              bg-blue-600
                              text-white
                              font-medium
                              text-xs
                              leading-tight
                              uppercase
                              rounded
                              shadow-md
                              hover:bg-blue-700 hover:shadow-lg
                              focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                              active:bg-blue-800 active:shadow-lg
                              transition
                              duration-150
                              ease-in-out
                              ml-1 "
                      onClick={onAddNoteButton}
                    >
                      Add Note
                    </button>
                  </div>
                  <p
                    className={`text-red-500 ${
                      isNotesInvalidated === false ? "hidden" : "show"
                    }`}
                  >
                    Field needed
                  </p>
                </div>

                <div className="flex ">
                  <div className="w-20">Date</div>
                  <div>Note</div>
                </div>
                <div space-x-2>
                  <Notes_grid
                    notesadded={notesadded}
                    pagedisplay={pagedisplay}
                  ></Notes_grid>
                </div>
              </div>
            </div>
            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button
                type="button"
                className="px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out
      ml-1"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal_notes;
