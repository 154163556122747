import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const DialogModify = ({ open, disagree, agree }) => {
  return (
    <div
      className={`modal fade fixed top-0 left-0  w-full h-full outline-none overflow-x-hidden overflow-y-auto  bg-black/75 ${
        open === true ? "show block" : ""
      }`}
      id="exampleModal"
    >
      <div className="modal-dialog relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5
              className="text-xl font-medium leading-normal text-gray-800"
              id="exampleModalLabel"
            >
              Modify current Reservation?
            </h5>
            <button
              type="button"
              className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              onClick={disagree}
            ></button>
          </div>
          <div className="modal-body relative p-4">
            You are about to make permanent changes to the cuurent Reservation,
            once completed the changes cannot be reverted
          </div>
          <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button
              type="button"
              className="px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out
      ml-1"
              onClick={disagree}
            >
              Disagree
            </button>
            <button
              type="button"
              className="px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out
      ml-1"
              onClick={agree}
            >
              Agree
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogModify;
{
  /* <div>
          <Dialog
            open={open}
            onClose={disagree}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Modify current Reservation?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You are about to make permanent changes to the cuurent Reservation,
                once completed the changes cannot be reverted
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={disagree} color="primary">
                Disagree
              </Button>
              <Button onClick={agree} color="primary" autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </div> */
}
