import React from "react";
import DicountAdded from "./DiscountsAdded";
//import AddPayment_Grid from './Add_Patment_grid'

const Modal_Add_Discount = ({
  handleClose,
  show,
  value,
  onValueChanged,
  canMove,
  canMovechanged,
  onAddPaymentButton,
  AmountValue,
  onAmountChange,
  addedPayment,
  SelectedPaymenttype,
  onPaymentTypeSelectedChange,
  discounttypeArray,
  discountAdded,
  discountValue,
  pagedisplay,
  onDiscountChange,
  onAddDiscountButton,
  onDeleteDiscount,
}) => {
  ////console.log("paymentobject",addedPayment)
  return (
    <>
      <div
        className={`modal fade fixed top-0 left-0  w-full h-full outline-none overflow-x-hidden overflow-y-auto  bg-black/75 ${
          show === true ? "show block" : ""
        }`}
        id="exampleModal"
      >
        <div className="modal-dialog relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalLabel"
              >
                Add Discount:
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body relative p-4">
              <div className="flex justify-between items-center space-x-2">
                <label className="w-1/4">Name / (%)</label>

                <select
                  value={discountValue}
                  onChange={onDiscountChange}
                  className="w-2/4 form-input text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
                >
                  {discounttypeArray.map((current, index) => {
                    return (
                      <option
                        key={current.discount_id}
                        value={`${current.discount_name}`}
                      >{`${current.discount_name} @ ${current.discount_rate}%`}</option>
                    );
                  })}
                </select>
                <button
                  onClick={onAddDiscountButton}
                  className="px-6
                      py-2.5
                      bg-blue-600
                      text-white
                      font-medium
                      text-xs
                      leading-tight
                      uppercase
                      rounded
                      shadow-md
                      hover:bg-blue-700 hover:shadow-lg
                      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                      active:bg-blue-800 active:shadow-lg
                      transition
                      duration-150
                      ease-in-out
                      ml-1 "
                >
                  Add Discount
                </button>
              </div>
              <div className="flex">
                <div className="w-1/4">Type</div>
                <div className="w-1/4">Percentage</div>
                <div className="w-1/4">Date</div>
              </div>
              <DicountAdded
                discountAdded={discountAdded}
                pagedisplay={pagedisplay}
                onDeleteDiscount={onDeleteDiscount}
              />
            </div>
            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button
                type="button"
                className="px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out
      ml-1"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal_Add_Discount;
