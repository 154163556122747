import React from "react";

class Room_Image_Preview extends React.Component {
  render() {
    const {
      imgsrc,
      description,
      title,
      index,
      onImgDescriptionChange,
      photoID,
      typeofpreview,
      editbutton_pressed,
    } = this.props;
    return (
      <>
        <div className="p-4 bg-zinc-100 shadow-lg rounded-md  mx-auto max-w-lg">
          <div className="flex flex-wrap relative">
            {(() => {
              if (typeofpreview === "edit") {
                return (
                  <>
                    <span className="absolute right-3 top-1 ">
                      <input
                        type="file"
                        id={`${index}-${photoID}-replace`}
                        onChange={editbutton_pressed}
                        ref={(fileInput) => (this.fileInput = fileInput)}
                        style={{ display: "none" }}
                        multiple={false}
                        accept="image/*"
                      ></input>
                      <button
                        onClick={() => this.fileInput.click()}
                        id={`${index}-${photoID}-replace`}
                        className="bg-white/[.60] px-6
      py-2.5
      
      text-stone-800
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      hover:text-white
       focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out
      ml-1 "
                      >
                        Replace
                      </button>
                      <button
                        onClick={editbutton_pressed}
                        id={`${index}-${photoID}-delete`}
                        className="bg-white/[.60] px-6
      py-2.5
      
      text-stone-800
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      hover:text-white
       focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out
      ml-1 "
                      >
                        Delete
                      </button>
                    </span>
                  </>
                );
              } else {
                return <></>;
              }
            })()}

            <img className=" mx-auto" src={imgsrc} />
          </div>
          <div className="flex flex-wrap space-x-2 pt-4 justify-center">
            <label>Image Title:</label>
            <input
              type="text"
              value={title}
              onChange={onImgDescriptionChange}
              id={`${index}-title`}
              className="form-input w-90 text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
            />
            <p
              className="w-90 text-red-500 text-center"
              hidden={title === "" ? true : false}
            >
              Enter a Title
            </p>
          </div>
          <div className="flex flex-wrap space-x-2 pt-4 justify-center">
            <label>Image Description:</label>
            <input
              type="text"
              value={description}
              onChange={onImgDescriptionChange}
              id={`${index}-description`}
              className="form-input w-90 text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
            />
            <p
              className="w-90 text-red-500 text-center"
              hidden={description === "" ? true : false}
            >
              Enter a Description
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Room_Image_Preview;
