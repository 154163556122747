import React from "react";
import Room_Image_Preview from "./Room_Image_Preview";

class Rooms_and_Rates_Additional extends React.Component {
  render() {
    const {
      room_type_list,
      current_room_Selected,
      current_Room_selected,
      preview_selected_Images,
      selectedRoomImages,
      onImgDescriptionChange,
      onRoom_Description_Change,
      image_stage_verified,
    } = this.props;
    //console.log("selector", image_stage_verified);
    return (
      <>
        <div className="space-y-2">
          <h1 className="text-lg">{`Rooms & Rates`}</h1>
          <p className="shadow-divs">
            In the following you will be asked to fill in the description of
            each room type along with any photos that you want to add for each
            room type
          </p>
          <div>
            {(() => {
              return room_type_list.map((current, index) => {
                ////console.log("textarea",current)
                return (
                  <>
                    <div
                      hidden={index === current_room_Selected ? false : true}
                    >
                      <label
                        className="block text-black text-sm font-medium mb-1 pt-4"
                        htmlFor={`roomt`}
                      >
                        {`Enter Room Type Description ${current.room_type_name}`}
                      </label>
                      <textarea
                        name="roomt"
                        value={current.room_description}
                        onChange={onRoom_Description_Change}
                        id={`${index}-room_description`}
                        className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner "
                      ></textarea>
                    </div>
                  </>
                );
              });
            })()}
            <p
              className="text-red-500"
              hidden={image_stage_verified === false ? true : false}
            >
              Please Enter a description for all the room types
            </p>
          </div>
          <div className="flex flex-col md:flex-row text-center md:justify-around py-4">
            <p>Maximum Image size allowable is 5Mbs and 990px x 660px</p>
            <p
              className="Error_Paragraph"
              hidden={image_stage_verified === false ? true : false}
            >
              Please Add at least 1 image to all room types
            </p>
            <input
              type="file"
              onChange={this.props.Room_Images_Selected}
              ref={(fileInput) => (this.fileInput = fileInput)}
              style={{ display: "none" }}
              multiple={true}
              accept="image/*"
            ></input>
            <button
              className="
              lg:order-1
              order-2
              px-6
              py-2.5
              bg-blue-600
              text-white
              font-medium
              text-xs
              leading-tight
              uppercase
              rounded
              shadow-md
              hover:bg-blue-700 hover:shadow-lg
              focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
              active:bg-blue-800 active:shadow-lg
              transition
              duration-150
              ease-in-out
              ml-1"
              onClick={() => this.fileInput.click()}
            >
              Add Photos
            </button>
          </div>
          <div className="flex flex-wrap w-full h-96 overflow-y-scroll scrollbar-blue-500 gap-4">
            {(() => {
              if (preview_selected_Images.length === 0) {
                return <></>;
              } else {
                ////console.log("sellll",this.props.preview_selected_Images)
                return preview_selected_Images[
                  this.props.current_room_Selected
                ].images.map((current, index) => {
                  return (
                    /*<Col className="image_room--container"><img src={current} className="image_logo"/></Col>*/
                    <div className="">
                      <Room_Image_Preview
                        imgsrc={current}
                        description={
                          selectedRoomImages[current_room_Selected].images[
                            index
                          ].description
                        }
                        title={
                          selectedRoomImages[current_room_Selected].images[
                            index
                          ].title
                        }
                        index={index}
                        onImgDescriptionChange={onImgDescriptionChange}
                      />
                    </div>
                  );
                });
              }
            })()}
          </div>
        </div>

        <div class="flex justify-center">
          <nav>
            <ul class="flex list-style-none">
              {room_type_list.map((current, index) => {
                return (
                  <>
                    <li
                      class={`page-item  rounded py-1  ${
                        current_room_Selected === index
                          ? "bg-blue-500 "
                          : "hover:bg-gray-200"
                      }`}
                    >
                      <a
                        tabIndex={index}
                        class={`no-underline hover:no-underline relative block py-1.5 px-3 border-0 bg-transparent 
                        outline-none transition-all duration-300 rounded ${
                          current_room_Selected === index
                            ? "text-white "
                            : "text-gray-800 "
                        } 
                        hover:text-gray-800  focus:shadow-none`}
                        onClick={current_Room_selected}
                      >
                        {index + 1}
                      </a>
                    </li>
                  </>
                );
              })}
            </ul>
          </nav>
        </div>
        {/* <Pagination className="pagination_main">
          {room_type_list.map((current, index) => {
            return (
              <>
                <Pagination.Item
                  tabIndex={index}
                  active={current_room_Selected === index ? true : false}
                  onClick={current_Room_selected}
                >
                  {index + 1}
                </Pagination.Item>
              </>
            );
          })}
        </Pagination> */}
      </>
    );
  }
}

export default Rooms_and_Rates_Additional;
