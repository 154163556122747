import React from "react";
import { withRouter } from "react-router-dom";
import Calendar from "react-calendar";
import Room_type_select from "./Room_type_selector";
import Rate_Display from "./Rate_Display";
import moment from "moment";
import Modal_update from "./Modal_update_rates";
import axios from "axios";
import SideBar from "../Header Menu/SideBar";
import Header from "../Header Menu/Header";

//const { ipcRenderer } = window.require("electron");

const InitialState = {
  calendardate: new Date(),
  initialdate: new Date(),
  Date_initial: moment(),
  maxdaystodisp: 35,
  min_price_hotel: "",
  date_start: "",
  date_end: "",
  room_types: "",
  room_typesel: "",
  room_rates_disp: [],
  room_type_local: "",
  modal_update_isvisible: false,
  sidebarOpen: false,
};

let immutableStore = [];

let index_of_changes = [];

class Daily_rate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendardate: new Date(),
      initialdate: new Date(),
      Date_initial: moment(),
      maxdaystodisp: 35,
      min_price_hotel: "",
      date_start: "",
      date_end: "",
      room_types: "",
      room_typesel: "",
      room_rates_disp: [],
      room_type_local: "",
      modal_update_isvisible: false,
      sidebarOpen: false,
    };
  }

  onMainMenuClick = () => {
    this.props.history.push({
      pathname: `/mainmenu`,
    });
  };

  onCalendarDateChange = (date) => {
    this.setState((prevState) => ({ calendardate: date }));
  };

  componentDidMount() {
    /*let req = JSON.stringify({

        })

        let resp = ipcRenderer.sendSync('get-roomtypes', req);*/

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/get-roomtypes"
          : "https://apiv1.reserv4me.com/get-roomtypes",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {},
      withCredentials: true,
    })
      .then((resp) => {
        //console.log(resp.data, resp.data.roomtypeinfo);

        this.setState(
          (prevState) => ({
            room_types: resp.data.roomtypeinfo,
            room_typesel: resp.data.roomtypeinfo[0].onlineroomtypeid,
            min_price_hotel: resp.data.minprice,
            room_type_local: resp.data.roomtypeinfo[0].type_id,
          }),
          () => {
            this.setRangeDates();
          }
        );
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });
  }

  setRangeDates = () => {
    ////console.log("test dates",moment(this.state.Date_initial).startOf('month').day(),moment(this.state.Date_initial).daysInMonth() )
    //console.log("type", this.state.room_typesel);
    let nextmonthdays =
      this.state.maxdaystodisp -
      moment(this.state.Date_initial).startOf("month").day() -
      moment(this.state.Date_initial).daysInMonth();
    let remainingdays =
      this.state.maxdaystodisp -
      moment(this.state.Date_initial).daysInMonth() -
      nextmonthdays;
    let startdate = 0;
    let enddate = 0;

    /*if(nextmonthdays>0){
            enddate=moment(this.state.Date_initial).endOf('month').add(nextmonthdays,'days')
        }*/
    startdate = moment(this.state.Date_initial)
      .startOf("month")
      .subtract(remainingdays, "days");
    enddate = moment(this.state.Date_initial)
      .endOf("month")
      .add(nextmonthdays, "days");

    /*let req = JSON.stringify({
            CIdate: startdate.format("MM/DD/YYYY"),
            COdate: enddate.format("MM/DD/YYYY"),
            roomtypeid: this.state.room_typesel
        })

        let resp = ipcRenderer.sendSync('get-dailyrates', req);*/

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/get-dailyrates"
          : "https://apiv1.reserv4me.com/get-dailyrates",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        CIdate: startdate.format("MM/DD/YYYY"),
        COdate: enddate.format("MM/DD/YYYY"),
        roomtypeid: this.state.room_typesel,
      },
      withCredentials: true,
    })
      .then((resp) => {
        immutableStore = resp.data;
        //console.log(resp.data);

        this.setState((prevState) => ({ room_rates_disp: resp.data }));
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });

    ////console.log(this.state.Date_initial,startdate.format('MM/DD/YYYY'),enddate.format('MM/DD/YYYY'))
  };

  onMonthchange = (e) => {
    // //console.log(e.target.id)
    let assignment = e.target.id;

    switch (assignment) {
      case "more": {
        this.setState(
          (prevState) => ({ Date_initial: prevState.Date_initial.add(1, "M") }),
          () => {
            this.setRangeDates();
          }
        );
        break;
      }
      case "less": {
        this.setState(
          (prevState) => ({
            Date_initial: prevState.Date_initial.add(-1, "M"),
          }),
          () => {
            this.setRangeDates();
          }
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  onRoomTypeChange = (e) => {
    let tempselectedval = e.target.value;
    ////console.log(e.target.value)
    this.setState(
      (prevState) => ({ room_typesel: tempselectedval, room_rates_disp: [] }),
      () => {
        this.setRangeDates();
      }
    );
  };

  onPriceChange = (e) => {
    let tempvariable = e.target;
    let tempvalue = tempvariable.value;
    let separatedvar = tempvariable.id.split("");
    switch (separatedvar[2]) {
      case "0": {
        this.setState(
          (prevState) => ({ room_rates_disp: [] }),
          () => {
            this.checkRateChanges(tempvalue, separatedvar);
          }
        );
        //this.checkRateChanges(tempvalue,separatedvar)//need work

        break;
      }
      case "1": {
        this.setState(
          (prevState) => ({ room_rates_disp: [] }),
          () => {
            this.checkMinStay(tempvalue, separatedvar);
          }
        );

        //console.log("change to minstay");
        break;
      }
      case "2": {
        this.setState(
          (prevState) => ({ room_rates_disp: [] }),
          () => {
            this.checkUnitsAvail(tempvalue, separatedvar);
          }
        );
        //console.log("change to units avail");
        break;
      }
      default: {
        //console.log("we seem to have an error there are more boxes available in the calendar [row,col,rowinside]");
      }
    }
  };

  testfocusout = (e) => {
    //console.log("we unfoxuesd", e);
  };

  checkRateChanges = (tempvalue, pointerchanges) => {
    //console.log(tempvalue.split("$"), pointerchanges);
    //this.set
    let tempsplit = tempvalue.split("$");

    switch (
      tempsplit.length //this checks if the $ was removed by client and then assigns correct value to be checked
    ) {
      case 1: {
        let pass = this.priceChangeChecker(tempsplit[0], pointerchanges);

        //console.log("yeap this is", pass);
        break;
      }
      case 2: {
        let pass = this.priceChangeChecker(tempsplit[1], pointerchanges);

        //console.log("yeap this is 2", pass);

        break;
      }
      default: {
        //console.log("yeap this is 3");

        break;
      }
    }
  };

  priceChangeChecker = (tempcheck, pointerchanges) => {
    /*let temparray=[]
        temparray=immutableStore.map((cur,ind)=>{
            //console.log([...cur],ind)
            return(
                temparray[ind]=cur.map((c,i)=>{
                    return(
                        c
                    )
                })
            )
        })*/
    const temparray = immutableStore;

    //let temparray=this.state.room_rates_disp;
    ////console.log("immutable",temparray)

    let regex =
      /^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/;
    let testsuccess = regex.test(tempcheck);

    if (testsuccess === true) {
      //console.log("yay");
      tempcheck = parseFloat(tempcheck).toFixed(2);
      if (tempcheck < this.state.min_price_hotel) {
        this.setState(
          (prevState) => ({ room_rates_disp: temparray }),
          () => {
            let element = document.getElementById(
              `${pointerchanges[0]}${pointerchanges[1]}${pointerchanges[2]}`
            );
            element.classList.add("is-invalid");
          }
        );
        //console.log("please note that the rate is too low");
      } else {
        temparray[pointerchanges[0]][
          pointerchanges[1]
        ].basecostp = `$${tempcheck}`;
        temparray[pointerchanges[0]][pointerchanges[1]].hasupdates = true;
        //console.log("after", temparray);
        this.setState((prevState) => ({ room_rates_disp: temparray }));
      }
    } else {
      //console.log("nay");
      this.setState(
        (prevState) => ({ room_rates_disp: temparray }),
        () => {
          let element = document.getElementById(
            `${pointerchanges[0]}${pointerchanges[1]}${pointerchanges[2]}`
          );
          element.classList.add("is-invalid");
        }
      );
    }

    return testsuccess;
  };

  checkMinStay = (tempcheck, pointerchanges) => {
    let regex = /^[0-9]+$/;

    let temparray = immutableStore;

    let testsuccess = regex.test(tempcheck);

    if (testsuccess === true) {
      temparray[pointerchanges[0]][
        pointerchanges[1]
      ].minimumstay = `${tempcheck}`;
      temparray[pointerchanges[0]][pointerchanges[1]].hasupdates = true;

      this.setState((prevState) => ({ room_rates_disp: temparray }));
    } else {
      this.setState(
        (prevState) => ({ room_rates_disp: temparray }),
        () => {
          let element = document.getElementById(
            `${pointerchanges[0]}${pointerchanges[1]}${pointerchanges[2]}`
          );
          element.classList.add("is-invalid");
        }
      );
    }
  };

  checkUnitsAvail = (tempcheck, pointerchanges) => {
    let regex = /^[0-9]+$/;

    let temparray = immutableStore;

    let testsuccess = regex.test(tempcheck);

    if (testsuccess === true) {
      temparray[pointerchanges[0]][
        pointerchanges[1]
      ].numofroomavail = `${tempcheck}`;
      temparray[pointerchanges[0]][pointerchanges[1]].hasupdates = true;

      this.setState((prevState) => ({ room_rates_disp: temparray }));
    } else {
      this.setState(
        (prevState) => ({ room_rates_disp: temparray }),
        () => {
          let element = document.getElementById(
            `${pointerchanges[0]}${pointerchanges[1]}${pointerchanges[2]}`
          );
          element.classList.add("is-invalid");
        }
      );
    }
  };
  RateUpdater = () => {
    /*//console.log("vhevkingh rate",
        this.state.room_types.findIndex(element=>{
            ////console.log("el",element.onlineroomtypeid===this.state.room_typesel)
            return(element.onlineroomtypeid===this.state.room_typesel)
        }))*/
    immutableStore.map((current, index) => {
      return current.map((cur, ind) => {
        //console.log(cur.hasupdates === undefined);
        if (cur.hasupdates !== undefined) {
          cur.dateofday = moment(cur.dateofday).format("MM/DD/YYYY");
          return (index_of_changes[index_of_changes.length] = cur);
        } else {
          return null;
        }
      });
    });

    //console.log("prices being updated", index_of_changes);

    /*let req = JSON.stringify({
            updatedratearr: index_of_changes,
            roomtypeid: this.state.room_typesel,
            /*room_type_local: this.state.room_types.findIndex(element => {
                //console.log("el", element.onlineroomtypeid === this.state.room_typesel)
                return (element.onlineroomtypeid === this.state.room_typesel)
            })*/

    /*})

        let resp = ipcRenderer.sendSync('update-alldailyrates', req);*/

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/update-alldailyrates"
          : "https://apiv1.reserv4me.com/update-alldailyrates",
      headers: {
        "Access-Control-Allow-Origin":
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://apiv1.reserv4me.com",
        "Content-Type": "application/json",
        Authorization: "internalvar.apikey",
      },
      data: {
        updatedratearr: index_of_changes,
        roomtypeid: this.state.room_typesel,
      },
      withCredentials: true,
    })
      .then((resp) => {
        //console.log("we are good", resp.data);
        if (resp.data.success === true) {
          this.setState(
            (prevState) => {
              return InitialState;
            },
            () => {
              immutableStore = [];
              index_of_changes = [];
              this.handleModalUpdateClose();
              this.componentDidMount();
              ////console.log("date",this.state.Date_initial)
              this.setState((prevState) => ({ Date_initial: moment() }));
            }
          );
        }
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/`,
        });
        //console.log("massive error", error);
      });

    ////console.log("prices being updated", index_of_changes)
  };

  handleModalUpdateClose = () => {
    this.setState((prevState) => ({
      modal_update_isvisible: !prevState.modal_update_isvisible,
    }));
  };
  setSidebarOpen = () => {
    this.setState((prevState) => ({ sidebarOpen: !prevState.sidebarOpen }));
  };

  render() {
    //console.log(this.state.Date_initial.format("MM/DD/YYYY"));
    return (
      <>
        <div className="flex flex-col min-h-screen overflow-hidden">
          <SideBar
            sidebarOpen={this.state.sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
          />
          <Header
            sidebarOpen={this.state.sidebarOpen}
            setSidebarOpen={this.setSidebarOpen}
            className="z-90"
            userName={window.sessionStorage.getItem("uname")}
          />
          <main>
            <div className="flex flex-wrap md:flex-nowrap">
              <div className="space-y-2 p-4">
                <button
                  className="font-semibold  bg-blue-500 text-white  p-2 rounded "
                  onClick={this.RateUpdater}
                >
                  Update Prices
                </button>
                <Calendar
                  value={this.state.calendardate}
                  onChange={this.onCalendarDateChange}
                />
              </div>
              <div className=" flex-grow p-4  w-9/12">
                <div className="flex gap-4 w-full">
                  <button
                    className="font-semibold  bg-blue-500 text-white  p-2 rounded "
                    onClick={this.onMonthchange}
                    id="less"
                  >{`<<`}</button>
                  <p>{`${this.state.Date_initial.format("MMM YYYY")}`}</p>
                  <button
                    className="font-semibold  bg-blue-500 text-white  p-2 rounded "
                    onClick={this.onMonthchange}
                    id="more"
                  >{`>>`}</button>
                  <Room_type_select
                    room_types={this.state.room_types}
                    onChangeroom={this.onRoomTypeChange}
                  />
                </div>

                <div className="flex flex-wrap ">
                  <div className=" hidden lg:grid grid-flow-col grid-cols-7 w-full px-4 ">
                    <span>SUN</span>
                    <span>MON</span>
                    <span>TUE</span>
                    <span>WED</span>
                    <span>THU</span>
                    <span>FRI</span>
                    <span>SAT</span>
                  </div>
                  <div className=" py-4 mx-auto">
                    {this.state.room_rates_disp.map((current, index) => {
                      return (
                        <div
                          className="grid grid-flow-row lg:grid-flow-col grid-cols-1
                          md:grid-cols-3 lg:grid-cols-7 gap-4 my-4 "
                          key={index}
                        >
                          {current.map((cur, ind) => {
                            ////console.log("here",cur)
                            return (
                              <div
                                key={`${ind}_${moment(cur.dateofday).format(
                                  "DD MMM"
                                )}_${index}`}
                                className="shadow-3xl p-2 rounded"
                              >
                                <Rate_Display
                                  rates={cur}
                                  idbox={ind}
                                  idRow={index}
                                  onChangePrice={this.onPriceChange}
                                  onCheckfocus={this.testfocusout}
                                  DNE={cur.DNE}
                                />
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Modal_update
            handleClose={this.handleModalUpdateClose}
            show={this.state.modal_update_isvisible}
          />
        </div>
      </>
    );
  }
}
export default withRouter(Daily_rate);
