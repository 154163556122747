import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const DialogShell = ({ open, agree, DialogShelltxt, DialongShellHeader }) => {
  ////console.log(open,agree,Dialogtxt,DialongShellHeader)

  return (
    <div
      className={`modal fade fixed top-0 left-0  w-full h-full outline-none overflow-x-hidden overflow-y-auto  bg-black/75 ${
        open === true ? "show block" : ""
      }`}
      id="exampleModal"
      onClick={agree}
    >
      <div className="modal-dialog relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5
              className="text-xl font-medium leading-normal text-gray-800"
              id="exampleModalLabel"
            >
              {DialongShellHeader}
            </h5>
            <button
              type="button"
              className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body relative p-4">{DialogShelltxt}</div>
          <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button
              type="button"
              className="px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out
      ml-1"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogShell;
{
  /* <div>
      <Dialog
        open={open}
        onClose={agree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{DialongShellHeader}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {DialogShelltxt}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={agree} color="primary" autoFocus>
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </div> */
}
