import React from "react";
import { Modal, Button } from "react-bootstrap";

const Modal_Request_Inv_Fail = ({ handleClose, show }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request was not Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Something went wrong on our end please try again later or contact our
          support team:{" "}
          <a
            className="anchorStripe"
            href="mailto:support@reserv4me.com"
            target="_blank"
          >
            support@reserv4me.com
          </a>{" "}
          or{" "}
          <a
            className="anchorStripe"
            href="https://twitter.com/R4MeSupport"
            target="_blank"
          >
            twitter support
          </a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Modal_Request_Inv_Fail;
