import React from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = sessionStorage.getItem("hassignedup");
  const hasUname = sessionStorage.getItem("uname");
  //console.log("this", isAuthenticated, hasUname);

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated && hasUname !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default ProtectedRoute;
