import React from "react";
import { Modal, Button } from "react-bootstrap";

const Modal_Request_Inv = ({ handleClose, show }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request was Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will receive an email confirmation please allow 24 hrs. for your
          request to be reviewed
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Modal_Request_Inv;
