import React from "react";

class Daily_rate_select extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    ////console.log("porps",this.props.room_types.length)
    const { room_types, onChangeroom } = this.props;
    ////console.log("porps",room_types)

    if (room_types.length === 0) {
      return <></>;
    } else {
      return (
        <>
          <select
            onChange={onChangeroom}
            className="w-2/4 form-input text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
          >
            {this.props.room_types.map((current, index) => {
              return (
                <option
                  key={current.onlineroomtypeid}
                  value={current.onlineroomtypeid}
                >
                  {current.typeofr}
                </option>
              );
            })}
          </select>
        </>
      );
    }
  }
}

export default Daily_rate_select;
