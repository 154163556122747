import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

function FieldGroup({
  validationState,
  id,
  label,
  help,
  type,
  options,
  onChange,
  value,
  ...props
}) {
  return (
    <div className=" px-3 flex space-x-4 space-y-4">
      <label
        className="flex  text-black text-sm font-medium mb-1 w-72"
        htmlFor={label}
      >
        {label} <span className="text-red-600 pl-2">*</span>
      </label>

      {type === "textarea" ? (
        <textarea
          id={id}
          {...props}
          onChange={onChange}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner "
        ></textarea>
      ) : type === "select" ? (
        <select
          id={id}
          onChange={onChange}
          value={value}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
        >
          {options()}
        </select>
      ) : (
        <input
          id={id}
          type={type}
          onChange={onChange}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
          {...props}
        />
      )}

      {type === "textarea" ? (
        <p hidden={validationState} className=" text-sm mt-2 flex justify-end">
          {help}
        </p>
      ) : (
        <p hidden={validationState} className="text-red-500 text-sm mt-2">
          {help}
        </p>
      )}
    </div>
  );
}

class Email_Settings extends React.Component {
  render() {
    const {
      email_settings,
      onEmailMethodChange,
      hidde_savebtn,
      saveEmailSettings,
    } = this.props;
    return (
      <>
        <div>
          <div>
            <h1 className="text-center shadow-divs">Email Settings</h1>
          </div>
          <div className="pt-4">
            <p>
              Channels for which email confirmations are sent automatically:
            </p>
            <div className="">
              {(() => {
                if (email_settings.length !== 0) {
                }
                return email_settings.map((current, index) => {
                  return (
                    <>
                      <FieldGroup
                        label={current.received_type}
                        type="select"
                        value={`${index}-${current.immediate_email}`}
                        onChange={onEmailMethodChange}
                        options={() => {
                          return (
                            <>
                              <option key={0} value={`${index}-null`}>
                                Choose...
                              </option>
                              <option key={`1${index}`} value={`${index}-true`}>
                                Automatic
                              </option>
                              <option
                                key={`2${index}`}
                                value={`${index}-false`}
                              >
                                Manual
                              </option>
                            </>
                          );
                        }}
                      />
                    </>
                  );
                });
              })()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Email_Settings;

{
  /* <Row className="border_separation mainsettings_row">
            <Col xs={10}>
              <h3>Email Settings</h3>
            </Col>
            <Col xs={2} hidden={hidde_savebtn}>
              <Button onClick={saveEmailSettings}>Save</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <p>
                Channels for which email confirmations are sent automatically:
              </p>
            </Col>
            <Col xs={4}></Col>
          </Row>
          <Row>
            <Col xs={6}>
              <p>Channel</p>
            </Col>
            <Col xs={6}>
              <p>Email Delivery Mode</p>
            </Col>
          </Row>
          <Row>
            {(() => {
              if (email_settings.length !== 0) {
              }
              return email_settings.map((current, index) => {
                return (
                  <>
                    <Col xs={6}>
                      <p>{current.received_type}</p>
                    </Col>
                    <Col xs={6}>
                      <Form.Group>
                        <Form.Control
                          as="select"
                          value={`${index}-${current.immediate_email}`}
                          onChange={onEmailMethodChange}
                        >
                          <option key={0} value={`${index}-null`}>
                            Choose...
                          </option>
                          <option key={`1${index}`} value={`${index}-true`}>
                            Automatic
                          </option>
                          <option key={`2${index}`} value={`${index}-false`}>
                            Manual
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </>
                );
              });
            })()}
          </Row> */
}
