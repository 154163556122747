import React from "react";
import { withRouter } from "react-router-dom";

class Button_array extends React.Component {
  render() {
    return (
      <>
        <div className="grid grid-rows-2 space-y-2 justify-items-end lg:justify-items-start">
          <div>
            <button
              className="font-normal  bg-blue-500 text-white p-2 rounded  "
              onClick={this.props.onClick}
              disabled={this.props.disableModals}
            >
              Payment(s)
            </button>
          </div>
          <div>
            <button
              className="font-normal bg-blue-500 text-white p-2 rounded  "
              onClick={this.props.onDiscountClick}
              disabled={this.props.disableModals}
            >
              Discount(s)
            </button>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Button_array);
