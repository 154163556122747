import React from "react";

class Finalize_Signup extends React.Component {
  render() {
    const { create_Inventory, temporary_test, isBtnFinishDisabled } =
      this.props;
    return (
      <>
        <div className="space-y-2">
          <h1 className="text-xl font-bold">
            Congratulations you are one click away
          </h1>
          <div className="max-w-3xl mx-auto px-4">
            <span>
              By clicking the Finish Integration Reserv4me.com (owned by Engsys
              Analyics LLC) will create an inventory for 2 years with base
              prices and policies that were set here by you, the inventory for
              following years will be created automatically, Each property will
              need to change the rates of each day of the year using the Daily
              Rates tab found in the main menu, in the mean time your content
              will be checked and uploaded to our platform, this process will
              take about 24 hrs. Once concluded, we will send you an email with
              instructions on how to proceed with the integration. Our support
              team is avaiable to help you please email us at anytime to
              <a className="text-blue-600" href="mailto:support@reserv4me.com">
                {" "}
                support@reserv4me.com
              </a>
            </span>
          </div>
          <div className="flex justify-center">
            <button
              className="
              
              order-2
              px-6
              py-2.5
              bg-blue-600
              text-white
              font-medium
              text-xs
              leading-tight
              uppercase
              rounded
              shadow-md
              hover:bg-blue-700 hover:shadow-lg
              focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
              active:bg-blue-800 active:shadow-lg
              transition
              duration-150
              ease-in-out
              ml-1 "
              onClick={create_Inventory}
              disabled={isBtnFinishDisabled}
            >
              Finish Integration
            </button>
          </div>
        </div>

        {/*<Form className="Form_Box-separation">
          <Row className="FormBox_Sizing">
            <Col>
              <Button onClick={create_Inventory}>Create</Button>
              <Button onClick={temporary_test}>Test</Button>
            </Col>
          </Row>
    </Form>*/}
      </>
    );
  }
}

export default Finalize_Signup;
